import React, { useState, Component } from "react";
import Cross from "../../images/cross.svg";
import { validateImage, compressImage } from "../../helpers/images";
import { Form } from 'react-bootstrap';
import { apiCall } from './../../helpers/api'

export class ImageUploadPreviewComponent extends Component {
 
    constructor(props) {
        super(props)
        this.state = {
            files: this.props.images,
            errors: [],
            draggedFile: null
        }
        this.previewFiles = this.previewFiles.bind(this)
        console.log(this.props.images)
    }
 
    async previewFiles(e) {
        var errors = [];
        var files = e.target.files;
        var fileArray = this.state.files;
        if (files.length + fileArray.length){
            files = Object.entries(files).slice(0,5-fileArray.length).map(file => file[1]);
        }
        for (var file of files) {
            var [validationResult, validationReason] = validateImage(file)
            if (validationResult){
                file = await compressImage(file,0.95)
                var tempFile = {"url":URL.createObjectURL(file),"data":file,"metadata":null,"type":"image","new":true}
                var response = await apiCall(`getUploadSignature?folder=campaignImages&asset_type=${tempFile.type}`,{ "method" : "GET" });
                if (response["success"]) {
                    tempFile.metadata = response["success"]["metadata"]
                    fileArray.push(tempFile);
                }
                else {
                    errors.push(`${file.name} could not be uploaded: ${response["failure"]["error"]}`)
                }
            }
            else {
                errors.push(`${file.name} could not be uploaded: ${validationReason}`)
            }
        }
        this.setState({ files: fileArray, errors: errors })
        this.props.setImages(fileArray)
        this.props.setImagesChanged(true)
    }

    removeFile(targetFile){
        var fileArray = this.state.files.filter(function(file) {return file.url != targetFile.url });
        this.setState({ files: fileArray })
        this.props.setImages(fileArray)
        this.props.setImagesChanged(true)
    }

    setPrimary(targetFile){
        var fileArray = this.state.files.filter(function(file) {return file.url != targetFile.url });
        fileArray.unshift(targetFile);
        this.setState({ files: fileArray })
        this.props.setImages(fileArray)
        this.props.setImagesChanged(true)
    }

    handleDrag(targetFile){
        this.setState({draggedFile: targetFile})
    }
    
    handleDrop(targetFile){
        var newArray = [];
        this.state.files.forEach(file => {
            if (file.url == this.state.draggedFile.url) {
                newArray.push(targetFile)
            }
            else if (file.url == targetFile.url){
                newArray.push(this.state.draggedFile)
            }
            else {
                newArray.push(file)
            }
        })
        this.setState({ files: newArray, draggedFile: null})
    }
 
    render() {
        return (
            <>
            <div className="form-group multi-preview mt-3">
                {this.state.files.length > 0 && (
                    <span key={this.state.files[0].url} className="imageWrapper mb-3" draggable={true} onDragOver={(ev) => ev.preventDefault()} onDragStart={e => this.handleDrag(this.state.files[0])} onDrop={e => this.handleDrop(this.state.files[0])}> 
                        <img height="200px" width="200px" className="me-3 object-fit-cover rounded border" src={this.state.files[0].url} alt="..." />
                        <img className="overlayTopRight" title="Remove this image from the campaign" onClick={e => this.removeFile(this.state.files[0])} src={Cross}/>
                        <span className="overlayBottomRight">Primary</span>
                    </span>
                )}
                {this.state.files.length > 1 && (this.state.files.slice(1).map(file => (
                    <span key={file.url} className="imageWrapper mb-3" draggable={true} onDragOver={(ev) => ev.preventDefault()} onDragStart={e => this.handleDrag(file)} onDrop={e => this.handleDrop(file)}>
                        <img id={file.url}  height="200px" width="200px" className="me-3 object-fit-cover rounded border" src={file.url} alt="..." />
                        <img className="overlayTopRight" title="Remove this image from the campaign" onClick={e => this.removeFile(file)} src={Cross}/>
                        <button className="btn btn-dark btn-sm overlayBottomLeft" title="Set this image as the primary image" onClick={e => this.setPrimary(file)}>Set Primary</button>
                    </span>
                )))}
                {this.state.errors && (<>{this.state.errors.map(error => (<p>{error}</p>))}</>)}
            </div>
            <Form.Group className="mb-3 multiUpload">
                <div className="multiUploadBox">Drag & Drop or <a className="text-primary">Upload</a></div>
                <Form.Control disabled={this.state.files.length >= 5} type="file" onChange={this.previewFiles} multiple accept=".png, .jpg, .jpeg, .webp"/>
                {this.state.files.length >= 5 && (
                    <div className="text-danger mt-3">If you'd like to change any photos, please delete one first</div>
                )}
            </Form.Group>
            </>
        )
    }
}

export async function uploadNewImages(images){
    var failedImages = [];
    var campaignImages = [];
    for (const image of images){
        if (image.new) {
            const formData = new FormData();
            formData.append("file",image.data)
            formData.append("public_id",image.metadata.public_id)
            formData.append("api_key",image.metadata.api_key)
            formData.append("timestamp",image.metadata.timestamp)
            formData.append("signature",image.metadata.signature)
            if (image.metadata.folder != ""){
                formData.append("folder",image.metadata.folder)
            }
            campaignImages.push(await fetch(`${image.metadata.target}/${image.metadata.resource_type}/upload`, {
                method: "POST",
                body: formData
            }).then(async response => {
                try {
                    let responseJSON = await response.json();
                    if (response.ok) {
                        return {"id":responseJSON.public_id,"source":image.metadata.source,"name":image.data.name}
                        }
                    else {
                        failedImages.push(image)
                    }
                } catch (error) {
                    failedImages.push(image)
                }
            }))
        }
        else {
            campaignImages.push(image.original)
        }
    }
    return campaignImages;
  }
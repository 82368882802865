import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, Card, Carousel, Badge, Tab, Tabs, ListGroup, Fade, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { apiCall } from './../../helpers/api'
import { getDifference, withinAgeRange } from './../../helpers/dates'
import { useSearchParams, Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import AlertModal from "../../components/alerts/alert";
import { getDetails, getProfilePicture, validateAddress, validatePhoneNumber } from '../../helpers/user';
import { getSocialBadge } from "../../helpers/socials";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import { formatText } from "../../helpers/format";

import './Campaigns.css';
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import Spinner from './../../images/tail-spin.svg';
import Clock from "./../../images/clock.svg";
import Avatar from "./../../images/avatar.png";
import NoAccess from "../../images/no-access.png";
import Instagram from "./../../images/instagram.svg";
import Facebook from "./../../images/facebook.svg";
import Info from "./../../images/info.svg";
import Plus from "./../../images/plus.svg";
import checkGreen from "./../../images/check-green.svg";
import checkGrey from "./../../images/check.svg";
import crossGrey from "./../../images/cross.svg";
import instagramOutline from "./../../images/instagram-gray.svg";
import facebookGrey from "./../../images/facebook-gray.svg";
import event from "./../../images/event.svg";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

class CampaignView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: null,
      campaign: null,
    };
    this.getCampaign(props.id, props)
    this.getUserDetails()
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  async getCampaign(id, props) {
    var response = await apiCall(`campaign?id=${id}`,{ "method" : "GET" });
    if (response["success"]) {
      this.setState({
        campaign : response["success"]["campaign"]
      });
      this.getStartAndEnd(response["success"]["campaign"])
      this.getRandomCampaigns()
      this.getProfileDetails(props.currentProfile.profile.id, props, response["success"]["campaign"]["_id"])
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async getUserDetails() {
    var response = await apiCall(`influencer/myDetails`,{ "method" : "GET" });
    if (response["success"]) {
      this.setState({
        userDetails : response["success"]["details"]
      });
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async getProfileDetails(id, props, campaignID) {
    var response = await apiCall(`profile?campaignID=${campaignID}`,{ "method" : "GET" });
    if (response["success"]) {
      props.setCooldownElapsed(response["success"]["cooldownElapsed"])
      if(response["success"]["cooldownElapsed"] === true) {
        var questions = response["success"]["questions"]
        questions.forEach(question => {
          if (question.questionType == "Options"){
            question.answer = []
          }
          else {
            question.answer = ""
          }
        });
        this.props.setQuestions(questions)
      }
    }
  }

  getStartAndEnd(campaign){
    this.setState(
      {
        campaignStart : getDifference(campaign["startDate"]),
        campaignEnd : getDifference(campaign["endDate"])
      }
    ) 
  }

  async getRandomCampaigns(){
    var response = await apiCall(`campaigns?random=true&limit=5&active=true&live=true&exclude=${JSON.stringify([this.props.id])}`,{ "method" : "GET" });
    if (response["success"]) {  
      this.setState({
        isLoaded: true, 
        otherCampaigns : response["success"]["campaigns"]
      });
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  CampaignItem(campaign){
    return (
        <Card className="shadow w-100">
          <Link to={"/Campaign?id="+campaign.id} reloadDocument className="position-relative d-block p-3">
            <Card.Img variant="top" className="cardImage rounded" src={getImageUrl(campaign.class.props.providers,campaign.images[0],["w_500","c_scale"])} />
          </Link>
          <Card.Body className="pt-0">
            <div className="text-center px-3 mb-2">
                <img src={getImageUrl(campaign.class.props.providers,campaign.brandIcon)} width="75%" height="50px" className="object-fit-contain" />
                {/* <div className="cardTitle text-truncate">
                  <Link to={"/Campaign?id="+campaign.id} reloadDocument>{campaign.brandName}</Link>
                </div>             */}
            </div>
            <h6 className="text-center px-4">
              <Link className="wallInfo" to={"/Campaign?id="+campaign.id} reloadDocument>
                {campaign.offering}
              </Link>
            </h6>
          </Card.Body>
        </Card>
    )
  }

  CancelModal(props){
    var isSubmitting = false;
    var error = null;
    const handleSubmit = async e => {
      e.preventDefault();
      isSubmitting = true
      var response = await apiCall(`campaign/bid?id=${props.id}`,{ "method" : "DELETE" });
      if (response["success"]) {
        props.class.getCampaign(props.id)
        props.handleClose();
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Bid Cancelled","content":"Bid Successfully Cancelled","className":"bg-success text-white"})
      }
      else {
        error = response["failure"]["error"]
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Error Cancelling Bid","content":error,"className":"bg-danger text-white"})
      };
      isSubmitting = false
    }

    return (
      <Modal show={props.show} centered onHide={props.handleClose}>
        <Modal.Header className="bg-danger text-white" closeButton>
          <Modal.Title className="h5">Cancellation Confirmation</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body className="pb-0">
            <p>Are you sure you want to cancel this bid? You won't be able to undo this action once you've clicked yes.</p>            
            {error && <div className="error text-danger small ms-2">{error}</div>}
          </Modal.Body>
          <Modal.Footer className="p-3 pt-0 border-0 d-flex align-items-center justify-content-between">
            <Button variant="success" className="m-0" onClick={props.handleClose}>No, don't cancel my bid</Button>
            <Button variant="danger" className="m-0" disabled={isSubmitting} type="submit" onClick={handleSubmit}>
              {isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Cancelling...</> : "Yes, cancel my bid"}
            </Button>      
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }

  BidModal(props){
    var error = null;
    const handleSubmit = async e => {
      e.preventDefault();
      if (!props.class.props.terms) {
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Confirm Terms","content":"Please confirm you agree to the brands terms","className":"bg-danger text-white"})
      }
      else if (props.message.count < 50) {
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Bid Message Too Short","content":"Please write at least 50 characters for your bid","className":"bg-danger text-white"})
      }
      else if (props.campaign.campaignType == "commercial" && (props.class.props.budget < 0 || props.class.props.budget > props.campaign.budget.maximum)){
          props.class.props.setAlert({"display":"toast","visible":true,"title":"Invalid Fee","content":`${props.class.props.budget} Please provide a fee between ${props.campaign.budget.currency == "GBP" ? "£" : "$"}0 and ${props.campaign.budget.currency == "GBP" ? "£" : "$"}${props.campaign.budget.maximum}`,"className":"bg-danger text-white"})
      }
      else {
        props.class.props.setIsSubmitting(true)
        var response = await apiCall(`campaign/bid?id=${props.id}`,{ "method" : "PUT", "data" : {"bidderID":props.currentProfile.profile.id,"message":props.message.value,"fee":props.class.props.budget} });
        if (response["success"]) {
          props.class.getCampaign(props.id);
          props.handleClose();
          props.class.props.setAlert({"display":"toast","visible":true,"title":"Bid Submitted","content":"Bid Successfully Submitted","className":"bg-success text-white"})
        }
        else {
          error = response["failure"]["error"]
          props.class.props.setAlert({"display":"toast","visible":true,"title":"Error Submitting Bid","content":error,"className":"bg-danger text-white"})
        };
        props.class.props.setIsSubmitting(false)
      }
    }

    const handleCheck = e => {
      if (e.target.id == "termsAgreement") {
        props.class.props.setTerms(e.target.checked);
      }
      else if (e.target.id == "usageRights") {
        props.class.props.setUsageRights(e.target.checked);
      }
      else if (e.target.id == "correctAddress") {
        props.class.props.setAddressConfirmed(e.target.checked);
      }
    }

    return (
      <Modal show={props.show} centered onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">Bid on This Campaign</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className="mb-3 position-relative" >
                <Form.Label>Bid Message (Min. 50 Characters)</Form.Label>
                <Form.Control 
                  onChange={e => props.setMessage({"value":e.target.value,"count":e.target.value.length})}
                  as="textarea"
                  id="message"
                  required
                  maxLength={500}
                  placeholder="Write bid message here..."
                  value={props.message.value}
                  rows={5}
                  className="pb-4"
                />
                <span className="position-absolute smaller end-0 bottom-0 text-muted me-2 mb-1">{props.message.count}/500</span> 
              </Form.Group>

              {props.campaign.campaignType == "commercial" && (
                <Form.Group className="mb-3 position-relative" >
                  <p className="text-muted small">As this campaign has a budget, please also provide your fee. This must not exceed the maximum budget for the campaign, which is {props.campaign.budget.currency == "GBP" ? "£" : "$"}{props.campaign.budget.maximum}.</p>
                  <Form.Label>Your Fee</Form.Label>
                    <InputGroup className="mb-4">
                        <Form.Control type="text" id="currency" className="w-25" value={props.campaign.budget.currency == "GBP" ? "£" : "$"} readOnly />
                        <Form.Control type="number" max={props.campaign.budget.maximum} min={0} id="budget" className="w-75" value={props.class.props.budget} onChange={e => props.class.props.setBudget(parseInt(e.target.value))} />
                    </InputGroup>  
                </Form.Group>
              )}
              <div className="d-flex align-items-center small mb-2">
                <img src={props.currentProfile.profile.profileImage.hasOwnProperty("id") ? checkGreen : crossGrey} className="me-2" width="20px" /><img src={Avatar} width="18px" height="16px" className="me-2" /> Profile Picture 
              </div>
              {props.campaign.requirements.deliveryAddress && (
              <div className="d-flex align-items-center small mb-2">
                <img src={validateAddress(props.userDetails.address) ? checkGreen : crossGrey} className="me-2" width="20px" /><img src={Avatar} width="18px" height="16px" className="me-2" /> Delivery Details
              </div>
              )}
              {props.campaign.requirements.phoneNumber && (
              <div className="d-flex align-items-center small mb-2">
                <img src={validatePhoneNumber(props.userDetails.phoneNumber) ? checkGreen : crossGrey} className="me-2" width="20px" /><img src={Avatar} width="18px" height="16px" className="me-2" /> Phone Number 
              </div>
              )}
              {props.campaign.requirements.usageRights && (
                <Form.Group className="mt-3">
                  <div className="form-check">
                    <input type="checkbox" id="usageRights" className="form-check-input" onChange={event => handleCheck(event)} value={props.class.props.terms} />
                    <label title="" htmlFor="usageRights" className="form-check-label">I agree to give <b>{props.campaign.brandName}</b> rights to use any content I create for this campaign</label>
                  </div>
                </Form.Group>
              )}
              {(props.campaign.requirements.deliveryAddress && validateAddress(props.userDetails.address)) && (
                <>
                <Form.Group className="mt-3">
                <div className="form-check">
                  <input type="checkbox" id="correctAddress" className="form-check-input" onChange={event => handleCheck(event)} value={props.class.props.terms} />
                  <label title="" htmlFor="correctAddress" className="form-check-label">I confirm <a className="fw-500" onClick={(e) => {props.class.props.setShowAddress(true); e.preventDefault()}}>My Address</a> is correct. <a className="fw-500" target="_blank" href={"/Settings?setting=contactDetails"}>Not Correct?</a></label>
                </div>
                </Form.Group>
                {props.class.props.showAddress && (
                  <div className="position-relative mt-3 brandTerms">
                    <textarea readOnly defaultValue={`${props.userDetails.address.address1}\n${props.userDetails.address.address2}\n${props.userDetails.address.city}\n${props.userDetails.address.postcode}`} wrap="hard" rows="4" className="rounded border p-3 pb-5 pe-5 w-100" />
                    <Button variant="secondary" size="sm" className="position-absolute end-0 bottom-0 me-3 mb-4" onClick={() => props.class.props.setShowAddress(false)}>Hide</Button>
                  </div>  
                )}
                </>
              )}
              <Form.Group className="mt-3">
                <div className="form-check">
                  <input type="checkbox" id="termsAgreement" className="form-check-input" onChange={event => handleCheck(event)} value={props.class.props.terms} />
                  <label title="" htmlFor="termsAgreement" className="form-check-label">I agree to <a className="fw-500" onClick={(e) => {props.class.props.setShowTCs(true); e.preventDefault()}}>{props.campaign.brandName}'s terms</a></label>
                </div>
              </Form.Group>
              {props.class.props.showTCs && (
                <div className="position-relative mt-3 brandTerms">
                  <textarea readOnly defaultValue={props.campaign.terms} wrap="hard" rows="6" className="rounded border p-3 pb-5 pe-5 w-100" />
                  <Button variant="secondary" size="sm" className="position-absolute end-0 bottom-0 me-3 mb-4" onClick={() => props.class.props.setShowTCs(false)}>Hide</Button>
                </div>  
              )}

              {((props.campaign.requirements.deliveryAddress && !validateAddress(props.userDetails.address)) || (props.campaign.requirements.phoneNumber && !validatePhoneNumber(props.userDetails.phoneNumber)) || !props.currentProfile.profile.profileImage.hasOwnProperty("id")) && (
                <div class="alert alert-warning" role="alert">
                  You are missing a requirement to bid on this campaign. Please visit <Link to={"/Settings?setting=contactDetails"}>your settings</Link> to add the required details before bidding.
                </div>
              )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex align-items-center justify-content-between m-0">
              {error && <div className="error text-danger small">{error}</div>}
              <div>
                <Button variant="outline-secondary" size="sm" className="me-2" onClick={props.handleClose}>Cancel</Button>
                <Button variant="primary" size="sm" disabled={!props.class.props.terms || (props.campaign.requirements.usageRights && !props.class.props.usageRights) || (props.campaign.requirements.deliveryAddress && !props.class.props.addressConfirmed) || props.message.value.length < 50 || (props.campaign.requirements.deliveryAddress && !validateAddress(props.userDetails.address)) || (props.campaign.requirements.phoneNumber && !validatePhoneNumber(props.userDetails.phoneNumber)) || !props.currentProfile.profile.profileImage.hasOwnProperty("id")} type="submit" onClick={handleSubmit}>
                  {props.class.props.isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...</> : "Submit Bid"}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }

  AlsoViewed(props){
    return (
      <Container className="bg-secondary pb-0" fluid>
        <Container className="py-4">
          <div className="d-flex justify-content-between align-items-end mb-3 px-3 px-lg-0">
            <div className="sectionTitle text-truncate d-block d-md-none">People also viewed</div>
            <div className="sectionTitle text-truncate fw-700 fs-3 d-none d-md-block">People also viewed</div>
            <Link to={"/Home"} className="seeAllLink text-nowrap me-3 me-lg-0">See all</Link>
          </div>
          <div className="multiCarousel px-3 pb-md-3 px-lg-0">
            {props.otherCampaigns.map((campaign) => <props.class.CampaignItem key={campaign._id} name={campaign.name} images={campaign.images} brandIcon={campaign.brandIcon} id={campaign._id} brandName={campaign.brandName} offering={campaign.offering} class={props.class} />)}
          </div>
        </Container>
      </Container>
    )
  }

  HeaderCarousel(props){
    return (
      <Container className="position-relative headerCarousel pb-0 pb-md-3">      
        <div className="d-none d-md-block">
          <div className="mb-2 mt-3">
            <Link onClick={() => window.history.back()} className="text-primary small">&#60; Back</Link>
          </div>
          <h3 className="mb-4 text-white tragedyRegularFont">{props.title}</h3>
          <Row className="imagesBoxView">
            <div className="col-lg-6 col-md-8 col-sm-12 col-12 h-100">
            <img src={getImageUrl(props.class.props.providers,props.images[0],["w_500","c_scale"])} className="w-100 h-100 border rounded-xl shadow object-fit-cover cursor-pointer" onClick={() => {props.class.props.setShowCarousel(true); props.class.props.setClickedImage(0)}} aria-controls="imageViewer" aria-expanded={props.class.props.showCarousel} />
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col-12 h-100">
              <Row className="h-50">
                {props.images.slice(1).map((campaignImage,index) => (
                  <div key={campaignImage.id} className="col-6 h-100 col-padding-bottom">
                    <img src={getImageUrl(props.class.props.providers,campaignImage,["w_500","c_scale"])} className="w-100 h-100 border rounded object-fit-cover cursor-pointer" onClick={() => {props.class.props.setShowCarousel(true); props.class.props.setClickedImage(index+1)}} aria-controls="imageViewer" aria-expanded={props.class.props.showCarousel} />
                  </div>
                ))}
              </Row>
            </div>
          </Row>
        </div>
        <Fade in={props.class.props.showCarousel}>
          <div id="imageViewer">
            <div className="carouselControl">
              <button type="button" className="btn btn-sm btn-success">Download</button>
              <button type="button" onClick={() => props.class.props.setShowCarousel(false)} className="btn btn-sm btn-light closeImageViewer">Close</button>
            </div>
            <Carousel activeIndex={props.class.props.clickedImage} onSelect={e => {props.class.props.setClickedImage(e)}} slide={false}>
              <Carousel.Item>
                <img id="0" className="d-block" src={getImageUrl(props.class.props.providers,props.images[0],["w_500","c_scale"])} alt="First slide" />
              </Carousel.Item>
              {props.images.slice(1).map((campaignImage,index) => (
                <Carousel.Item key={index}>
                  <img id={index+1} src={getImageUrl(props.class.props.providers,campaignImage,["w_500","c_scale"])} className="d-block" alt={campaignImage.id} />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Fade>
        <div className="d-block d-md-none">
          <div className="blackGradient"></div>      
          <div className="my-4 py-2 px-3 overlayBox">
            <Link className="backArrow me-4" onClick={() => window.history.back()}>
              <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#ffffff"/>
              </svg>
            </Link>
          </div>
          {props.class.state.campaignStart.future ? <Badge bg="light" text="dark" className="left"><img src={Clock} className="align-bottom" /> Starts in <strong>{props.class.state.campaignStart.difference} {props.class.state.campaignStart.unit}</strong></Badge> : <Badge bg="light" text="dark" className="left"><img src={Clock} className="align-bottom" /> Ends in <strong>{props.class.state.campaignEnd.difference} {props.class.state.campaignEnd.unit}</strong></Badge>}      
          <Carousel>
            {props.images.map((campaignImage,index) => (
              <Carousel.Item key={campaignImage.id}>
                <img
                  className="d-block w-100"
                  src={getImageUrl(props.class.props.providers,campaignImage,["w_500","c_scale"])} onError={(event) => event.target.src = Spinner}
                  alt="First slide"
                />
                <Badge bg="light" text="dark" className="right">{index+1}/{props.images.length}</Badge>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Container>
    )
  }

  CampaignPage(props){
    var error = null;
    const handleSubmit = async e => {
      e.preventDefault();
      props.class.props.setIsSubmitting(true)
      var response = await apiCall(`profile`,{ "method" : "PUT", "data" : {"influencerID":props.currentProfile.profile.id,"questionID":props.class.props.questions[0].questionID,"answer":props.class.props.questions[0].answer} });
      if (response["success"]) {
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Answer Submitted","content":"Answer Successfully Submitted","className":"bg-success text-white"})
        props.class.props.questions.splice(0,1)
      }
      else {
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Error submitting answer","content":error,"className":"bg-danger text-white"})
      };
      props.class.props.setIsSubmitting(false)
    }

    const handleCancel = async e => {
      e.preventDefault();
      props.class.props.setIsCancelling(true)
      var response = await apiCall(`profile/resetCooldownTimer`,{ "method" : "PUT", "data" : {"influencerID":props.currentProfile.profile.id} });
      if (response["success"]) {
        props.class.props.questions.splice(0,1)
      }
      else {
        props.class.props.setAlert({"display":"toast","visible":true,"title":"Error removing popup","content":error,"className":"bg-danger text-white"})
      };
      props.class.props.setIsCancelling(false)
    }

    function updateQuestionAnswer(answer){
      var tempQuestions = structuredClone(props.class.props.questions)
      tempQuestions[0].answer = answer
      props.class.props.setQuestions(tempQuestions)
    }

    function ToggleButtonGroupAnswer() {    
      return (
        <>
          <ToggleButtonGroup type={props.class.props.questions[0].optionsType === "Single" ? "radio" : "checkbox"} name="options" id="answer" value={props.class.props.questions[0].answer} onChange={(choice) => updateQuestionAnswer(choice)}>
            {props.class.props.questions[0].options.map((option, index) =>
              <ToggleButton id={`tbg-btn-${index}`} className="me-2 mb-2" key={option} value={option}>{option}</ToggleButton>
            )}
          </ToggleButtonGroup>
        </>
      );
    }

    return (
      <Container className="p-0" fluid>
        {props.currentProfile.type === "influencers" && props.class.props.cooldownElapsed === true && props.class.props.questions.length >= 1 && (
            <Modal show centered>
              <Modal.Header>
                <Modal.Title>Would you like to answer some questions relevant to this campaign?</Modal.Title>
              </Modal.Header>
      
              <Modal.Body className="pb-0">
                <Row>
                  <Col>
                      <Form.Group className="mb-3 position-relative" >
                        <p className="text-muted small">{props.class.props.questions[0].question}</p>
                          {props.class.props.questions[0].questionType === "Text" &&
                            <InputGroup className="mb-4">
                              <Form.Control type="text" id="answer" className="" value={props.class.props.questions[0].answer} onChange={e => updateQuestionAnswer(e.target.value)} />
                            </InputGroup>
                          }
                          {props.class.props.questions[0].questionType === "Yes/No" &&
                            <>
                              <div className="border rounded p-2 mb-3">
                                  <Form.Check className="ms-1 pt-1" type="radio" checked={props.class.props.questions[0].answer === true} onChange={() => updateQuestionAnswer(true)} id="answerYes" name="answer" label="Yes" /> 
                              </div>   
                              <div className="border rounded p-2 mb-3">
                                  <Form.Check className="ms-1 pt-1" type="radio" checked={props.class.props.questions[0].answer === false} onChange={() => updateQuestionAnswer(false)} id="answerNo" name="answer" label="No" />    
                              </div>
                            </>
                          }
                          {props.class.props.questions[0].questionType === "Options" &&
                            <div className="form-group createCampaign">
                              <ToggleButtonGroupAnswer />
                            </div>
                          }
                          {props.class.props.questions[0].questionType === "Number" &&
                            <InputGroup className="mb-4">
                              <input className="mx-2 pt-1 w-75" type="range" id="answer" min={props.class.props.questions[0].numberRange.minimum} max={props.class.props.questions[0].numberRange.maximum} step={1} value={props.class.props.questions[0].answer ? props.class.props.questions[0].answer : props.class.props.questions[0].numberRange.minimum} onChange={(e) => updateQuestionAnswer(e.target.value)} />
                              {props.class.props.questions[0].answer ? props.class.props.questions[0].answer : props.class.props.questions[0].numberRange.minimum}
                            </InputGroup>
                          }
                      </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
      
              <Modal.Footer>
                <Button variant="secondary" disabled={props.class.props.isSubmitting || props.class.props.isCancelling} onClick={handleCancel}>
                {props.class.props.isCancelling ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Cancelling...</> : "Not now"}
                </Button>
                <Button variant="primary" disabled={(props.class.props.isSubmitting || props.class.props.isCancelling || props.class.props.questions[0].answer.length == 0)} type="submit" onClick={handleSubmit}>
                {props.class.props.isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...</> : "Submit"}
                </Button>
              </Modal.Footer>
            </Modal>
        )}
        <Container className="px-0 mb-5 mb-md-4 mb-lg-0 pb-4 pb-md-2 pb-lg-0 tabViewChange ps-md-3" fluid>
          <props.class.HeaderCarousel class={props.class} images={props.campaign.images} title={props.campaign.name}/>
          <Container fluid className="bg-light rounded-top-xl">
            <Container className="mt-md-4 pt-4">
              <div className="logoBox mb-4">
                <img src={getImageUrl(props.class.props.providers,props.campaign.brandIcon)} width="46px" /> {props.campaign.brandName}
              </div>
              <div className="mb-4">
                <h5 className="d-block d-md-none">{props.campaign.name}</h5>
                {props.campaign.categories.map(category => (
                  <Badge bg="event" className="me-2" key={category}>{category}</Badge>
                ))}
                {props.campaign.trackingTags.map(tag => (
                  <Badge bg="event" className="me-2" key={tag}><strong>{tag}</strong></Badge>
                ))}
              </div>
            </Container>
            <Container>
              <Row>
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12 mb-3 mb-md-0">
                  {props.campaign.event && (
                    <div className="border rounded bg-gray mb-4 d-inline-block">
                      <div className="d-flex align-items-center">
                        <div className="px-3">
                          <img src={event} width="20px" />
                        </div>
                        <div className="bg-white small p-2 rounded-end w-100">
                          {Object.values(props.campaign.eventDetails).every(item => item == false) && (
                            <div>This is an event</div>
                          )}
                          {props.campaign.eventDetails.eventStartDate && (
                            <div>This is an event scheduled for:<br />
                              <strong className="text-black">{(new Date(props.campaign.eventDetails.eventStartDate*1000)).toLocaleDateString('en-GB', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</strong> at <strong className="text-black">{(new Date(props.campaign.eventDetails.eventStartDate*1000)).toLocaleString('en-US', { hour:"2-digit", minute:"2-digit" })}</strong>{props.campaign.eventDetails.location && (<> in <strong className="text-black">{props.campaign.eventDetails.location}</strong></>)}{props.campaign.eventDetails.duration && (<> for <strong className="text-black">{props.campaign.eventDetails.duration} day{props.campaign.eventDetails.duration > 1 ? "s" : null}</strong></>)}
                            </div>
                          )}
                          {props.campaign.eventDetails.location && !props.campaign.eventDetails.eventStartDate && (
                            <div>This is an event in:<br />
                              <strong className="text-black">{props.campaign.eventDetails.location}</strong>
                            </div>
                          )}
                          </div>
                      </div>
                    </div>
                  )}
                  <Tabs defaultActiveKey="offering" id="uncontrolled-tab-example" fill >

                    <Tab eventKey="offering" title="Offering">
                      <div className="p-3 px-md-0">
                        <h6>What we're offering</h6>
                        <p className="small text-muted">{formatText(props.campaign.offering)}</p>
                      </div>
                    </Tab>

                    <Tab eventKey="looking" title="Looking for">
                      <div className="p-3 px-md-0">

                        <h6>What we're looking for</h6>
                        <p className="small text-muted pb-3 mb-3">{formatText(props.campaign.lookingFor)}</p>
                        
                        <div className="d-block d-md-none aboutYouBox">
                          <h6 className="mb-3">About You</h6>
                          <ListGroup horizontal className="mb-2">
                            <ListGroup.Item className="w-100 d-flex align-items-center px-2">
                              {withinAgeRange(props.user.dob,props.campaign.criteria.ages).match ? <img src={checkGreen} className="me-2" width="20px" /> : <img src={checkGrey} className="me-2" width="20px" />} <img src={Avatar} width="18px" height="16px" className="me-2 avatarIcon" /> {withinAgeRange(props.user.dob,props.campaign.criteria.ages).range}
                            </ListGroup.Item>
                          </ListGroup>

                          {props.campaign.criteria.hasOwnProperty("socials") && props.campaign.criteria.socials.map(social => (
                            <ListGroup horizontal className="mb-2" key={social}>
                            <ListGroup.Item className="w-100 d-flex align-items-center px-2">
                              {props.user && props.user.socials.includes(social.toLowerCase()) ? <img src={checkGreen} className="me-2" width="20px" /> : <img src={checkGrey} className="me-2" width="20px" />} {getSocialBadge(social)} {social}
                            </ListGroup.Item>
                            </ListGroup>
                          ))}
                        </div>
                      </div>
                    </Tab>

                    {props.campaign.terms ? 
                    <Tab eventKey="terms" title="Terms">
                      <div className="p-3 px-md-0 mb-md-4">
                        <h6>What are the terms</h6>
                        <p className="small text-muted">Here are the specific terms for this campaign: </p>
                        <p className="small text-muted">{formatText(props.campaign.terms)}</p>
                        <p className="small text-muted">You agree to abide by these terms for the specific contract and won't breach them. </p>
                      </div>
                    </Tab>
                    : null}

                  </Tabs>
                </div>
                <div className="col-xl-3 col-lg-4 mb-md-4">
                  <div className="card border-0 bg-secondary d-none d-md-block">
                    <div className="py-3 d-flex align-items-center card-header">
                        {!props.class.state.campaignEnd.future ? 
                          <Badge bg="warning" text="dark" className="reportBadge">
                            <img src={Clock} className="align-bottom" /> Finished <strong>{props.class.state.campaignEnd.difference} {props.class.state.campaignEnd.unit}</strong> ago
                          </Badge> 
                          : (props.class.state.campaignStart.future ? 
                          <Badge bg="warning" text="dark" className="reportBadge">
                            <img src={Clock} className="align-bottom" /> Starts in <strong>{props.class.state.campaignStart.difference} {props.class.state.campaignStart.unit}</strong>
                          </Badge> 
                          : 
                          <Badge bg="warning" text="dark" className="reportBadge">
                            <img src={Clock} className="align-bottom" /> Ends in <strong>{props.class.state.campaignEnd.difference} {props.class.state.campaignEnd.unit}</strong>
                          </Badge>
                        )} 
                    </div>
                    <div className="card-body">
                      <div className="card-title h6 mb-3">About You</div>
                      <div className="card-text text-muted small aboutYouBox">
                          <div className="d-flex align-items-center mb-2">
                          {withinAgeRange(props.user.dob,props.campaign.criteria.ages).match ? <img src={checkGreen} className="me-2" width="20px" /> : <img src={checkGrey} className="me-2" width="20px" />} <img src={Avatar} width="18px" height="16px" className="me-2 avatarIcon" /> {withinAgeRange(props.user.dob,props.campaign.criteria.ages).range}
                          </div>
                        {props.campaign.criteria.socials.map(social => (
                        <div className="d-flex align-items-center mb-2" key={social}>
                          {props.user && props.user.socials.includes(social.toLowerCase()) ? <img src={checkGreen} className="me-2" width="20px" /> : <img src={checkGrey} className="me-2" width="20px" />} {getSocialBadge(social)} {social}
                          {/* <img src={checkGreen} className="me-2" width="20px" /> {getSocialBadge(social)} {social} */}
                        </div>
                        ))}
                        {/* <div className="d-flex align-items-center mb-2">
                          <img src={checkGrey} className="me-2" width="20px" /><img src={facebookGrey} width="18px" className="me-2" /> Facebook (<a className="text-primary">Add</a>)
                        </div> */}
                      </div>
                      {props.campaign.hasOwnProperty("bidState") && (
                        <>
                          {props.campaign.bidState == "Cancelled" || props.campaign.bidState == "Rejected" ? <button type="button" onClick={props.handleBidShow} className="w-100 mt-3 btn btn-primary btn-sm">Bid</button> : null}
                          {["Pending","Accepted","In Progress"].includes(props.campaign.bidState) ? <button type="button" onClick={props.handleCancelShow} className="w-100 mt-3 btn btn-danger btn-sm">Cancel Bid</button> : null}
                        </>
                      ) || props.class.state.campaignEnd.future && <button type="button" onClick={props.handleBidShow} className="w-100 mt-3 btn btn-primary btn-sm">Bid</button>
                      }
                    </div>
                  </div>
                  
                  <div className="border mt-md-4 rounded p-3 mb-3 mb-md-4">
                    <div className="mb-3 mt-1">
                      <svg width="50" height="44" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.7609 22.6451C18.7609 20.7215 20.3607 19.1613 22.3331 19.1613H26.8028C28.7749 19.1613 30.375 20.7233 30.375 22.6451C30.375 24.569 28.7752 26.129 26.8028 26.129H22.3331C20.3611 26.129 18.7609 24.5671 18.7609 22.6451ZM20.5477 22.6451C20.5477 23.6057 21.3485 24.3871 22.3332 24.3871H26.8029C27.7883 24.3871 28.5883 23.6072 28.5883 22.6451C28.5883 21.6848 27.7876 20.9032 26.8029 20.9032H22.3332C21.3477 20.9032 20.5477 21.6833 20.5477 22.6451ZM13.8475 25.2581C14.3409 25.2581 14.741 25.648 14.741 26.129C14.741 26.6102 14.3409 27 13.8475 27C6.19973 27 0 20.9559 0 13.5C0 6.04414 6.19973 0 13.8475 0C21.4953 0 27.695 6.04414 27.695 13.5C27.695 13.981 27.2951 14.371 26.8017 14.371C26.3084 14.371 25.9083 13.981 25.9083 13.5C25.9083 7.00602 20.5088 1.74198 13.8476 1.74198C7.18696 1.74198 1.78692 7.00602 1.78692 13.5C1.78692 19.994 7.18696 25.258 13.8476 25.258L13.8475 25.2581ZM22.3345 23.5162C21.8411 23.5162 21.4413 23.1261 21.4413 22.6451C21.4413 22.1641 21.8412 21.7743 22.3345 21.7743H24.5679C25.0613 21.7743 25.4614 22.1642 25.4614 22.6451C25.4614 23.1262 25.0613 23.5162 24.5679 23.5162H22.3345ZM18.761 13.0648C19.2544 13.0648 19.6545 13.4546 19.6545 13.9356C19.6545 14.4166 19.2544 14.8067 18.761 14.8067H13.4005C12.9071 14.8067 12.5071 14.4166 12.5071 13.9356V6.09694C12.5071 5.61592 12.9072 5.2259 13.4005 5.2259C13.8939 5.2259 14.2938 5.61594 14.2938 6.09694V13.0649L18.761 13.0648Z" fill="#2EC7DE"/>
                      </svg>
                    </div>
                    <div>
                      <div className="fw-500 mb-2">Tips for success</div>
                      <div className="text-muted">
                        <ul className="mb-0">
                          {props.campaign.tips.map(tip => tip && (<li><div className="text-muted">{tip}</div></li>))}
                        </ul>
                      </div>
                    </div>
                  </div>  
                </div>
              </Row>
            </Container>
          </Container>
          {props.otherCampaigns.length > 0 && (
            <props.class.AlsoViewed otherCampaigns={props.otherCampaigns} class={props.class} />
          )}
        </Container>
      </Container>
    )
  }

  render() {
    const { error, isLoaded, campaign, otherCampaigns, userDetails } = this.state;
    const handleBidClose = () => this.props.setShowBid(false);
    const handleBidShow = () => this.props.setShowBid(true);
    const handleCancelClose = () => this.props.setShowCancel(false);
    const handleCancelShow = () => this.props.setShowCancel(true);

    if (error) {
      return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {error}</div></div>;
    } else if (!campaign || !userDetails | !isLoaded) {
      return;
    } else {
      return (
        <Container className="p-0 pb-4 pb-md-0" fluid>
          <Container className="p-0" fluid>
            <this.CampaignPage class={this} campaign={campaign} otherCampaigns={otherCampaigns} handleBidShow={handleBidShow} handleCancelShow={handleCancelShow} user={userDetails} currentProfile={this.props.currentProfile}/>
          </Container>
          <this.BidModal handleClose={handleBidClose} show={this.props.showBid} message={this.props.message} setMessage={this.props.setMessage} id={campaign._id} class={this} userDetails={userDetails} user={this.props.user} campaign={campaign} currentProfile={this.props.currentProfile} />
          <this.CancelModal handleClose={handleCancelClose} show={this.props.showCancel} message={this.props.message} setMessage={this.props.setMessage} id={campaign._id} class={this} user={this.props.user} currentProfile={this.props.currentProfile}/>
          {campaign.hasOwnProperty("bidState") && (
            <>
              {campaign.bidState == "Cancelled" || campaign.bidState == "Rejected" ? <button type="button" onClick={handleBidShow} className="btn btn-primary btn-lg d-md-none mobileBidBtn">Bid</button> : null}
              {["Pending","Accepted","In Progress"].includes(campaign.bidState) ? <button type="button" onClick={handleCancelShow} className="btn btn-danger btn-lg d-lg-none mobileBidBtn">Cancel Bid</button> : null}
            </>
          ) || this.state.campaignEnd.future && <button type="button" onClick={handleBidShow} className="btn btn-primary btn-lg d-md-none mobileBidBtn">Bid</button>
          }
        </Container>
      );
    }
  }
}

export default function CampaignDetail() {
  const { defaultID, views, data, currentProfile } = useAuthContext();
  let [searchParams] = useSearchParams();
  const [showBid, setShowBid] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});
  const [ message, setMessage ] = useState({"value":"","count":0});
  const [ budget, setBudget ] = useState(0)
  const [ terms, setTerms ] = useState(false);
  const [ usageRights, setUsageRights ] = useState(false);
  const [ addressConfirmed, setAddressConfirmed ] = useState(false);
  const { providers } = useImageContext();
  const [showCarousel, setShowCarousel] = useState(false);
  const [clickedImage, setClickedImage] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [showTCs, setShowTCs] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const campaignID = searchParams.get('id');
  const [cooldownElapsed, setCooldownElapsed] = useState(false);
  const [questions, setQuestions] = useState([]);

  return (
    <div className="bg-page">
      <Topbar />
      <div className="pageName">Campaign Overview</div>
      <Container className="fullscreenWithTopBar campaignDetail bg-primary px-0" fluid="fluid">
          <AlertModal modal={showAlert} onClose={hideAlert} />
          <CampaignView id={campaignID} showBid={showBid} setShowBid={setShowBid} showCancel={showCancel} setShowCancel={setShowCancel} setAlert={setAlert} message={message} user={defaultID} setMessage={setMessage} terms={terms} setTerms={setTerms} currentProfile={currentProfile} cooldownElapsed={cooldownElapsed} setCooldownElapsed={setCooldownElapsed} questions={questions} setQuestions={setQuestions} memberships={data.memberships} providers={providers} showCarousel={showCarousel} setShowCarousel={setShowCarousel} clickedImage={clickedImage} setClickedImage={setClickedImage} showTCs={showTCs} setShowTCs={setShowTCs} budget={budget} setBudget={setBudget} setIsSubmitting={setIsSubmitting} isSubmitting={isSubmitting} isCancelling={isCancelling} setIsCancelling={setIsCancelling} usageRights={usageRights} setUsageRights={setUsageRights} showAddress={showAddress} setShowAddress={setShowAddress} addressConfirmed={addressConfirmed} setAddressConfirmed={setAddressConfirmed} />
      </Container>
      <Footer />
    </div>
  );
}
import React, { Component, useRef, useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {Container, Col, Row, Tab, Tabs, Nav, Button, Badge, Form, Card, Alert, Modal, Image, Popover, InputGroup, OverlayTrigger, Tooltip, Dropdown, DropdownButton, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { apiCall, PageScroller } from './../../helpers/api'
import { getImageUrl, validateImage, compressImage } from "../../helpers/images";
import { useImageContext } from "../../hooks/useImageContext";
import { getDifference, relativeTimeFormat } from "../../helpers/dates";
import { getStatusPill } from "../../helpers/status";
import { getFileUrl, getVideoURL, validateFile } from "../../helpers/files";
import { getAverageEngagement, getTotalFollowers, getSocialBadge, getContributionList } from "../../helpers/socials";
import { getSmallNumber, getPercentage } from "../../helpers/stats";
import { formatText } from "../../helpers/format";
import { setFilter } from "../../helpers/filters";
import AlertModal from "../../components/alerts/alert";
import { useAuthContext } from "../../hooks/useAuthContext";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import InboxLoader from "../../components/spinner/InboxLoader";
import Fade from 'react-bootstrap/Fade';
import Carousel from 'react-bootstrap/Carousel';
import Masonry from 'react-masonry-css'
import searchBlue from './../../images/search-blue.svg'
import info from './../../images/info.svg';import NoAccess from "../../images/no-access.png";
import filter from './../../images/filter.svg'
import starGrey from './../../images/star-grey.svg'
import starYellow from './../../images/star-yellow.svg'
import warning from './../../images/warning-alert.svg';
import blueAlert from './../../images/blue-alert.svg';
import blueProfileCard from './../../images/blue-profilecard.svg';
import blueTick from './../../images/blue-tick.svg';
import social from './../../images/social.svg';
import grayDoubleChecks from './../../images/gray-double-check.svg';
import whiteDoubleChecks from './../../images/white-double-check.svg';
import backArrow from './../../images/back-arrow-blue.svg';
import rightArrow from './../../images/blue-right-arrow.svg';
import sendDark from './../../images/send-dark.svg';
import paperclip from './../../images/paperclip.svg';
import Cross from "../../images/cross.svg";
import Document from "../../images/document.svg";
import Video from "../../images/video.svg";
import unread from "../../images/unread.svg";
import group from "../../images/groups.svg"
import groupClicked from "../../images/blue-groups.svg"
import read from "../../images/read.svg";
import checkGreen from "./../../images/check-green.svg";
import checkGrey from "./../../images/check.svg";
import Avatar from "./../../images/avatar.png";
import messageFilter from "../../images/messageFilter.svg";
import './InfluencerCampaigns.css'

class SendMessageForm extends Component {
  constructor() {
    super()
    this.state = {
        message: '',
        attachments: [],
        errors: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async sendMessage(message) {
    var data = {"sender":this.props.sender,"message":message,"messageType":"text"}
    if (this.props.unblockChat){
      data["unblockChat"] = true
    }
    var response = await apiCall(`conversation?id=${this.props.conversationID}`,{ "method" : "PUT", "data" : data });
    if (response["success"]) {
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async uploadAttachments(attachments){
    var failedAttachments = [];
    for (const attachment of attachments){
      const formData = new FormData();
      formData.append("file",attachment.data)
      formData.append("public_id",attachment.metadata.public_id)
      formData.append("api_key",attachment.metadata.api_key)
      formData.append("timestamp",attachment.metadata.timestamp)
      formData.append("signature",attachment.metadata.signature)
      if (attachment.metadata.folder != ""){
        formData.append("folder",attachment.metadata.folder)
      }
      fetch(`${attachment.metadata.target}/${attachment.metadata.resource_type}/upload`, {
        method: "POST",
        body: formData
      }).then(async response => {
        try {
          let responseJSON = await response.json();
          if (response.ok) {
            var response = await apiCall(`conversation?id=${this.props.conversationID}`,{ "method" : "PUT", "data" : {"sender":this.props.sender,"message":{"id":responseJSON.public_id,"source":attachment.metadata.source,"name":attachment.data.name},"messageType":attachment.type} });
            if (response["success"]) {
            }
            else {
              this.setState({
                error: response["failure"]["error"]
              });
            }
          } 
          else {
            failedAttachments.push(attachment)
          }
        } catch (error) {
          failedAttachments.push(attachment)
        }
      })
    }
  }

  handleChange(e) {
      this.setState({
          message: e.target.value
      })
  }

  async attachFiles(newFiles) {
    var errors = [];
    var files = newFiles.target.files;
    var fileArray = this.state.attachments;
    if (files.length + fileArray.length){
        files = Object.entries(files).slice(0,5-fileArray.length).map(file => file[1]);
    }
    for (var file of files) {
      if (["doc","pdf","docx","rtf","odt"].includes(file.name.split(".").pop())){
        var [validationResult, validationReason] = validateFile(file)
        var tempFile = {"url":URL.createObjectURL(file),"data":file,"metadata":null,"type":"file"}
      }
      else {
        var [validationResult, validationReason] = validateImage(file)
        file = await compressImage(file)
        var tempFile = {"url":URL.createObjectURL(file),"data":file,"metadata":null,"type":"image"}
      }
      if (validationResult){
          var response = await apiCall(`getUploadSignature?folder=attachments&asset_type=${tempFile.type}`,{ "method" : "GET" });
          if (response["success"]) {
            tempFile.metadata = response["success"]["metadata"]
            fileArray.push(tempFile);
          }
      }
      else {
          errors.push(`${file.name} could not be uploaded: ${validationReason}`)
      }
    }
    this.setState({ attachments: fileArray, errors: errors })
}

  removeFile(targetFile){
      var fileArray = this.state.attachments.filter(function(file) {return file.url != targetFile.url });
      this.setState({ attachments: fileArray })
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.state.attachments){
      this.uploadAttachments(this.state.attachments)
      this.setState({
        attachments: []
    })
    }
    if (this.state.message.length > 0){
      this.sendMessage(this.state.message)
      this.setState({
          message: ''
      })
    }
    if (!this.props.isBulkMessage){
      this.props.showConversation(this.props.conversationID,null,false)
    }
  }

  // Required for hitting enter into the textarea
  handleKeyPress(e,handleSubmit) {
    if(e.keyCode == 13 && e.shiftKey == false) {
      handleSubmit(e)
    }
    else {
      e.target.style.height = "0px"
      e.target.style.height = `${Math.min(e.target.scrollHeight, 120)}px`
    }
  }

  render() {
      return (
        <form onSubmit={this.handleSubmit}>

        <div className="attachedImages">
          {this.state.attachments.map(attachment => (
            <span key={attachment.url} className="imageWrapper mb-3" >
              {attachment.type == "image" && (
                <>
                  <img id={attachment.url} height="60" width="60" className="object-fit-cover rounded border" src={attachment.url} alt={attachment.data.name} title={attachment.data.name} />
                  <img className="overlayTopRight" title="Remove attachment" onClick={(e) => this.removeFile(attachment)} src={Cross}/>
                </>
              )}
              {attachment.type == "file" && (
                <>
                  <img id={attachment.data.name} height="60" width="60" className="object-fit-cover" src={Document} alt={attachment.data.name} title={attachment.data.name} />
                  <img className="overlayTopRight" title="Remove attachment" onClick={(e) => this.removeFile(attachment)} src={Cross}/>
                </>
              )}
            </span>
            ))}
        </div>

        <InputGroup className="align-items-center">
          <div className="grow-wrap">
            <textarea className="form-control border-0" rows="1" placeholder={"Send message to " + this.props.recipient.name} autoFocus onChange={this.handleChange} onKeyDown={(e) => this.handleKeyPress(e,this.handleSubmit)} name="text" id="text" value={this.state.message}></textarea>
          </div>
          {this.state.message.length == 0 && this.state.attachments.length == 0 ? <button onClick={this.handleSubmit} disabled className="btn border-0 bg-white"><img src={sendDark} /></button> : <button onClick={this.handleSubmit} className="btn border-0 bg-white"><img src={sendDark} /></button>}
          <label htmlFor="attachment-upload">
            <a className="btn border-0 bg-white">
              <img src={paperclip} />
            </a>
          </label>
          {this.state.errors && <div className={"text-muted small"}><strong>{this.state.errors}</strong></div>}   
          <Form.Control type="file" id="attachment-upload" multiple accept=".png, .jpg, .jpeg, .webp, .heic, .pdf, .doc, .docx, .rtf" onChange={(e) => this.attachFiles(e)} variant="default" className="fileUploadBtn d-none" />
        </InputGroup>
      </form>
      )
  }
}

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isConversationLoaded: false,
      readCooldown: {},
      filter: { search : "" }
    };
    this.showConversation = this.showConversation.bind(this);
    this.updateDueDate = this.updateDueDate.bind(this);
    this.getConversations(this.props.campaignID)
  }

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (this.props.class.props.conversationID){
        if (this.state.readCooldown > 0) {
          this.setState({
            readCooldown: this.state.readCooldown - 1
          })
        }
        else if (this.props.class.props.conversations.conversation.unreadMessages > 0){
          this.markConversationAsRead(this.props.class.props.conversationID)
        }
        this.getConversation(this.props.class.props.conversationID,null,false,this.props.class.props.conversations.conversation.lastMessageTime)
      }
      var lastMessageTime = Math.max.apply(Math, this.props.class.props.conversations.conversations.map(conversation => conversation.lastMessageTime))
      this.getConversations(this.props.campaignID,false,lastMessageTime)
    }, 10000);
  }

  componentWillUnmount(){
    clearInterval(this.intervalID);
  }

  componentDidUpdate(){
    if (this.state.isConversationLoaded && this.props.class.props.conversationID != this.props.class.props.conversations.conversation._id) {
      this.getConversation(this.props.class.props.conversationID)
    }
  }

  async markConversationAsRead(id){
    var response = await apiCall(`conversation/read?id=${id}`,{ "method" : "PUT", "data" : {"reader":this.props.class.props.conversations.conversation.participants.filter(function(participant) {return participant.hasOwnProperty("me") && participant.me})[0].id} });
    if (response["success"]) {
      this.getConversations(this.props.campaignID,false)
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async markAllConversationsAsRead(){
    var response = await apiCall(`conversations/read/all`,{ "method" : "PUT", "data" : {"campaignID": this.props.campaignID}});
    if (response["success"]) {
      this.getConversations(this.props.campaignID,false)
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.props.class.props.setViewMarkAllAsReadModal(false)
  }

  async markConversationAsUnread(id){
    var response = await apiCall(`conversation/unread?id=${id}`,{ "method" : "PUT", "data" : {"reader":this.props.class.props.conversations.conversation.participants.filter(function(participant) {return participant.hasOwnProperty("me") && participant.me})[0].id} });
    if (response["success"]) {
      this.getConversations(this.props.campaignID,false)
      this.setState({
        readCooldown: 3
      })
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async muteConversation(id){
    var response = await apiCall(`conversation/mute?id=${id}`,{ "method" : "PUT", "data" : {"muter":this.props.class.props.conversations.conversation.participants.filter(function(participant) {return participant.hasOwnProperty("me") && participant.me})[0].id} });
    if (response["success"]) {
      this.getConversations(this.props.campaignID,false)
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  async unmuteConversation(id){
    var response = await apiCall(`conversation/unmute?id=${id}`,{ "method" : "PUT", "data" : {"muter":this.props.class.props.conversations.conversation.participants.filter(function(participant) {return participant.hasOwnProperty("me") && participant.me})[0].id} });
    if (response["success"]) {
      this.getConversations(this.props.campaignID,false)
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
  }

  updateDueDate(bid,dueDate=null){
    if (dueDate){
      this.props.class.props.setDueDate(new Date(dueDate*1000).toISOString().slice(0,16));
    }
    else{
      this.props.class.props.setDueDate(new Date().toISOString().slice(0,16));
    }
    this.props.class.props.setReason("")
    this.props.class.props.setDueDateModal({"visible":true,"bids":[bid],"multiple":false,"isChat":true,"selectedBids":[bid.details.id]});
  }

  showConversation(id,setChatBox=null,showLoading=true){
    if (setChatBox){
      setChatBox(true);
    }
   
    this.props.class.props.setAutoScroll(true)
    this.props.class.props.setConversationID(id,null,showLoading);
    this.getConversation(id,null,showLoading);
    if (this.state.readCooldown > 0) {
      this.setState({
        readCooldown: this.state.readCooldown - 1
      })
    } else if (this.props.class.props.conversations.conversation.unreadMessages > 0) {
      this.markConversationAsRead(this.props.class.props.conversationID)
    }
  }

  updateConversations(newConversations){
    var existingConversations = this.props.class.props.conversations;
    newConversations.forEach(conversation => {
      var matchingIndex = existingConversations.conversations.findIndex(existingConversation => existingConversation._id == conversation._id)
      if (matchingIndex >= 0) {
        existingConversations.conversations[matchingIndex] = conversation
      }
      else {
        existingConversations.conversations.push(conversation)
      }
    })
    existingConversations.conversations.sort((a,b) => b.lastMessageTime - a.lastMessageTime);
    this.props.class.props.setConversations(existingConversations)
  }

  updateConversation(newMessages,addAfter=false){
    var existingConversations = this.props.class.props.conversations;
    newMessages.forEach(message => {
      var matchingIndex = existingConversations.messages.findIndex(existingMessage => existingMessage._id == message._id)
      if (matchingIndex >= 0) {
        existingConversations.messages[matchingIndex] = message
      }
      else {
        if (addAfter){
          existingConversations.messages.push(message)
        }
        else {
          existingConversations.messages.unshift(message)
        }
      }
    })
    existingConversations.messages.sort((a,b) => b.messageTime - a.messageTime);
    this.props.class.props.setConversations(existingConversations)
  }

  async getConversations(campaignID,showLoading=true,after=null) {
    var conversations = this.props.class.props.conversations; 
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('campaignID', campaignID);
    urlParams.set('limit', 1000);
    if (after && Number.isInteger(after)) {
      urlParams.set('after',after);
    }
    if (this.props.class.props.conversationID) {
      urlParams.set('conversationID', this.props.class.props.conversationID);
    }
    var response = await apiCall(`conversations?`+urlParams.toString(),{ "method" : "GET" });
    if (response["success"]) {
      if (after) { 
        this.updateConversations(response["success"]["conversations"]) 
      } else {
        conversations["conversations"] = response["success"]["conversations"];
        var unreadCounter = 0;
        conversations["conversations"].forEach(conversation => {
          conversation.participants.forEach(participant => {
            if (participant.me && participant.hasUnread) {unreadCounter += 1}
          })
        })
        this.props.class.props.setUnreadMessages(unreadCounter)
        if (this.props.class.props.conversationID){
          this.getConversation(this.props.class.props.conversationID,conversations,showLoading)
        } 
        else if (response["success"]["conversations"].length > 0){
          this.getConversation(response["success"]["conversations"][0]._id,conversations,showLoading)
          this.props.class.props.setConversationID(response["success"]["conversations"][0]._id)
        }
      }
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.props.class.props.setConversations(conversations);
    this.setState({
      isLoaded : true
    });
  }

  async getConversation(id,conversations=null,showLoading=true,after=null) {
    if (conversations == null){
      conversations = this.props.class.props.conversations;
    }
    if (showLoading){
      this.setState({
        isConversationLoaded : false
      });
    }
    if (after) {
      var response = await apiCall(`conversation?id=${id}&after=${after}`,{ "method" : "GET" });
    }
    else {
      var response = await apiCall(`conversation?id=${id}`,{ "method" : "GET" });
    }
    if (response["success"]) {
      if (after) { 
        this.updateConversation(response["success"]["messages"])
      } else {
        conversations["conversation"] = response["success"]["conversation"];
        conversations["messages"] = response["success"]["messages"];
      }
    }
    else {
      this.setState({
        error: response["failure"]["error"]
    });
    }
    this.props.class.props.setConversations({ "conversations" : conversations["conversations"], "conversation" : conversations["conversation"], "messages" : conversations["messages"] });
    if (showLoading){
      this.setState({
        isConversationLoaded : true
      });
    }
  }

  ConversationListItem(conversation){
    var recipient = {"name":"unknown","profileImage":"None"}
    conversation.participants.forEach(function (participant) {
      if (!participant.hasOwnProperty("me")){
        recipient = participant.details // We'll need to handle maybe group conversations in the future? Not sure if really needed...
      }
    })
    return (
      <div>
        <div className={conversation.id == conversation.selectedConversation ? "p-3 inboxCard selected" : "p-3 inboxCard" } onClick={()=>conversation.class.showConversation(conversation.id,conversation.setChatBox)}>
          <div className="pe-3">
            <img src={getImageUrl(conversation.class.props.providers,recipient.profileImage)} width="46px" height="46px" className="object-fit-cover rounded border bg-white" alt={recipient.name} />
          </div>
          <div className="d-flex justify-content-between">
            <div className="brandDetails">
              <div className="d-flex align-items-center pe-3 pe-lg-4 me-4 me-lg-5 mb-1">
                <h6 className="fw-700 mb-0 me-2 text-truncate">{recipient.name}</h6>
                {conversation.bidDetails && (getStatusPill(conversation.bidDetails.state))}
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-truncate text-muted small">{typeof(conversation.lastMessage.contents) === "string" ? conversation.lastMessage.contents : <i>An attachment was sent</i>} </div>
                {conversation.unreadMessages > 0 && <Badge bg="primary" className="smaller ms-2 fw-normal">{conversation.unreadMessages}</Badge>}
              </div>
            </div>
            <div className="text-end text-nowrap position-absolute end-0 me-3">
              <div className="text-muted smaller">{relativeTimeFormat(conversation.lastMessage.messageTime*1000,true)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  ConversationDetailItem(props){
    var recipient = {"name":"unknown","profileImage":"None"}
    var me = {"id":"","name":"unknown","profileImage":"None"}
    var active = false;
    var muted = false;
    var unblockChat = false;
    var participantType = null
      props.participants.forEach(function (participant) {
        if (!participant.hasOwnProperty("me")){
          recipient = participant.details // We'll need to handle maybe group conversations in the future? Not sure if really needed...
          recipient.type = participant.type
          if (props.bidDetails.state == "Pending" && participant.type == "influencer" && participant.active == false) {
            unblockChat = true;
          }
        }
        else {
          me = participant.details
          active = participant.active
          muted = participant.muted
          participantType = participant.type
        }
      })

    const AlwaysScrollToBottom = () => {
      const elementRef = useRef();
      useEffect(() => { 
        if (props.class.props.class.props.autoScroll)
        {
          elementRef.current.scrollIntoView()
        }
      });
      return <div ref={elementRef} />;
    }

    const chatAreaScroll = e  => {
      const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 10 & e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
      if (bottom) {
        props.class.props.class.props.setAutoScroll(true)
      } else {
        props.class.props.class.props.setAutoScroll(false)
      }
    }

    var address = [];
    if (recipient.type == "influencer" && recipient.address != {}){
        ["address1","address2","city","region","postcode"].forEach(line => {
        if (recipient.address[line] && recipient.address[line].length > 0){
          address.push(recipient.address[line])
        }
      })
    }

    return (
      <div className={`chatBox shadow-sm rounded-end h-100 ${!props.chatBox ? "hide": ""}`}>
        <div className="px-3 chatHeader">
          <div className="py-2 mb-2 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center text-truncate">
              <a className="mobileView pe-3" onClick={()=>props.setChatBox(false)}><img src={backArrow} /></a>
              <div className="pe-3">
                <img src={getImageUrl(props.class.props.providers,recipient.profileImage)} width="46px" height="46px" className="object-fit-cover rounded border bg-white" alt={recipient.name} />
              </div>
              <div className="text-truncate pe-4">
                <h6 className="mb-0 mw-100 text-truncate">{recipient.name}</h6>
                {props.bidDetails && (
                  <Link className="text-blue small" to={"/InfluencerCampaign/Preview?id="+props.bidDetails.campaignID} ><img className="mb-1 me-1" src={rightArrow} /> {props.bidDetails.title}</Link>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center">                            
              <DropdownButton drop="start" variant="light" className="moreVertical ms-3" title="" >
                {props.unreadMessages == 0 ? <Dropdown.Item className="small" onClick={() => props.class.markConversationAsUnread(props.id)}>Mark as Unread</Dropdown.Item> : <Dropdown.Item className="small" onClick={() => props.class.markConversationAsRead(props.id)}>Mark as Read</Dropdown.Item>}
                {muted ? <Dropdown.Item className="small" onClick={() => props.class.unmuteConversation(props.id)}>Unmute Notifications</Dropdown.Item> : <Dropdown.Item className="small" onClick={() => props.class.muteConversation(props.id)}>Mute Notifications</Dropdown.Item>}
                {/* <Dropdown.Divider />
                <Dropdown.Item className="small text-danger">Report & Block</Dropdown.Item> */}
              </DropdownButton>
            </div>
          </div>
        </div>

        <div className="chatBody">
          <div className="chatArea" onScroll={chatAreaScroll}>
            <div className="text-center text-muted py-3 small">{`This is the start of your conversation with ${recipient.name}`}</div>
            
              {[...props.messages].reverse().map(message => (
                <span key={message._id}>
                  {message.messageType == "update" ? (
                    <div className="text-center text-muted py-3 small">
                      {message.contents}
                      <span className="smaller ps-2">({relativeTimeFormat(message.messageTime*1000)})</span>
                    </div>
                  ) :
                  (
                  <>
                  {/* <div className="mb-4 text-center border-bottom h-15px">
                    <span className="fw-normal border text-muted smaller badge text-dark bg-gray">{isToday(new Date(message.messageTime*1000)) ? "Today" : (new Date(message.messageTime*1000)).toLocaleDateString('en-GB', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
                  </div> */}
                  <div className={message.sender != me._id ? "bubbleLeft" : "bubbleRight"}>
                    <div className={message.sender != me._id ? "bubbleLeftBoxes" : "bubbleRightBoxes"}>
                      <div className={message.sender != me._id ? "bubbleLeftText" : "bubbleRightText"}>
                        {message.messageType == "image" && <div className="imagesBoxView h-auto me-1 cursor-pointer"><img src={getImageUrl(props.class.props.providers,message.contents,["w_250","c_scale"])} className="object-fit-cover rounded-xl mt-2 me-2 mb-2 w-100" height="100px" alt={`Image from ${recipient.name}`} onClick={() => {props.class.props.class.props.setShowCarousel({visible:true,image:message.contents})}} aria-controls="imageViewer" /></div>}
                        {message.messageType == "file" && <div><a href={getFileUrl(props.class.props.providers,message.contents)} download={message.contents.name}><img src={Document} height="60px" className="object-fit-contain rounded" alt={`Document from ${recipient.name}`} /></a>{message.contents.name}</div>}
                        {message.messageType == "video" && <div><a href={getVideoURL(props.class.props.providers,message.contents)}><img src={Video} height="60px" className="object-fit-contain rounded" alt={`Document from ${recipient.name}`} /></a></div>}
                        {message.messageType == "text" && formatText(message.contents)}
                        <div className={message.sender != me._id ? "smaller text-muted text-nowrap pt-1" : "smaller text-nowrap pt-1" }>
                        {relativeTimeFormat(message.messageTime*1000)} <img className="ms-1" src={message.sender != me._id ? grayDoubleChecks : whiteDoubleChecks }/>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                  )}
                  <AlwaysScrollToBottom />
                </span>
                )
              )} 
          </div>
        </div>

        {props.class.props.class.props.showCarousel.visible && (
          <Fade in={props.class.props.class.props.showCarousel.visible}>
            <div id="imageViewer">
              <div className="carouselControl">
                <a href={getImageUrl(props.class.props.providers, props.class.props.class.props.showCarousel.image,null,true)} download target="_blank" className="btn btn-sm btn-success">Download</a>
                <button type="button" onClick={() => props.class.props.class.props.setShowCarousel({visible:false,image:null})} className="btn btn-sm btn-light closeImageViewer">Close</button>
              </div>
              <Carousel>
                <Carousel.Item>
                  <img id="0" className="d-block rounded-xl" src={getImageUrl(props.class.props.providers, props.class.props.class.props.showCarousel.image,["w_500","c_scale"])} alt={props.class.props.class.props.showCarousel.image.name} />
                </Carousel.Item>
              </Carousel>
            </div>
          </Fade> 
        )}  

        <div className="chatFooter border-top">
          <div className="position-relative d-flex justify-content-center">
          </div>
        {<SendMessageForm recipient={recipient} conversationID={props.id} sender={me._id} showConversation={props.showConversation} unblockChat={unblockChat}/>}
        </div>
      </div>
    );}

  ContextMenu(props){
    return (
      <div className="rightClickFilter" onClick={() => props.handleClose()}>
        <ul>
          <li onClick={() => props.performBulkMessage("pending",props.class)}>{(getStatusPill("Pending"))}</li>
          <li onClick={() => props.performBulkMessage("accepted",props.class)}>{(getStatusPill("Accepted"))}</li>
          <li onClick={() => props.performBulkMessage("in progress",props.class)}>{(getStatusPill("In Progress"))}</li>
          <li onClick={() => props.performBulkMessage("marked as complete",props.class)}>{(getStatusPill("Marked as Complete"))}</li>
          <li onClick={() => props.performBulkMessage("completed",props.class)}>{(getStatusPill("Completed"))}</li>
        </ul>
      </div>
    )
  }

  handleContextMenu(e){
    e.preventDefault();
    const { xCord, yCord } = e
    this.props.class.props.setContextMenu({visible : true, x : xCord, y : yCord})
  }

  handleFilterUpdate(value,type) {
    var filters = structuredClone(this.props.class.props.inboxFilters);
    if (type == "search") {
      filters.search = value.target.value.toLowerCase();
    }
    else if (type === "bidType") {
      if (value === "All") {
        filters.unread = false
        filters.archived = false
        delete filters["bidType"]
      }
      else if (value === "Unread") {
        filters.unread = true
        filters.archived = false
      }
      else if (value === "Archived") {
        filters.archived = true
        filters.unread = false
      }
      else {
        filters.bidType = value
        filters.unread = false
        filters.archived = false

      }
    }
    this.props.class.props.setInboxFilters(filters)
    setFilter(filters,"conversation")
    this.getConversations(this.props.campaignID)
  }

  render() {
    const { error, isLoaded, isConversationLoaded, filter } = this.state;
    if (error) {
      return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {error}</div></div>;
    } else if (!isLoaded) {
      return <FullPageSpinner />;
    } else {
    return (
      <Row className="mx-0 px-md-3 justify-content-center messagesPage">
        {/* <this.BulkMessageModal brandID={this.props.class.props.currentProfile.profile.id} class={this.props.class} /> */}
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 senderList shadow-sm rounded-start px-0 ps-md-3">
          <div className="inboxSearch pt-3 pt-md-0">
            <InputGroup className="py-1 position-relative">
              {/* <InputGroup.Text className="bg-white">
                <img src={Search} />
              </InputGroup.Text> */}
              <Form.Control placeholder="Search" className="border-end-0" defaultValue={filter.search} onChange={e => { this.setState({ filter: { search: e.target.value.toLowerCase() }}); this.props.class.props.setFilterUnreadMessages(false); }} />
              <Button variant="default" size="sm" className="border border-start-0 border-end-0" onClick={() => {this.props.class.props.setViewMarkAllAsReadModal(true)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-open" viewBox="0 0 16 16">
                  <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882zM15 7.383l-4.778 2.867L15 13.117zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765z"/>
                  <title>Select to mark all conversations as read</title>
                </svg>
              </Button>
              {this.props.class.props.viewMarkAllAsReadModal &&
                <Modal centered show>
                  <Modal.Header className="bg-primary text-white">
                    <Modal.Title className="h6">Mark all conversations as read</Modal.Title>
                  </Modal.Header>
                  <Form>
                    <Modal.Body className="pt-0">
                      <div className="pt-3">
                        <p className="fw-bold mb-2">Are you sure you want to mark all conversations as read?</p>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="p-3 pt-0 border-0">
                      <Button variant="outline-secondary" size="sm" className="m-0 me-2" onClick={() => {this.props.class.props.setViewMarkAllAsReadModal(false)}}>
                        Cancel
                      </Button>
                      <Button variant="success" size="sm" className="m-0" type="submit" onClick={(e) => {e.preventDefault();this.markAllConversationsAsRead()}}>
                        Mark as read
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Modal>
              }
              <Dropdown onSelect={e => { this.handleFilterUpdate(e,"bidType") }}>
                  <Dropdown.Toggle variant="white" className="messageFilterBtn border border-start-0">
                    <img src={messageFilter} height="18px" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow">
                    <Dropdown.Item eventKey={"All"} active={!this.props.class.props.inboxFilters.bidType}>All</Dropdown.Item>
                    <Dropdown.Item eventKey={"Accepted"} active={this.props.class.props.inboxFilters.bidType === "Accepted"}>Accepted</Dropdown.Item>
                    <Dropdown.Item eventKey={"Pending"} active={this.props.class.props.inboxFilters.bidType === "Pending"}>Pending</Dropdown.Item>
                    <Dropdown.Item eventKey={"In Progress"} active={this.props.class.props.inboxFilters.bidType === "In Progress"}>In Progress</Dropdown.Item>
                    <Dropdown.Item eventKey={"Marked as Complete"} active={this.props.class.props.inboxFilters.bidType === "Marked as Complete"}>Marked as Complete</Dropdown.Item>
                    <Dropdown.Item eventKey={"Completed"} active={this.props.class.props.inboxFilters.bidType === "Completed"}>Completed</Dropdown.Item>
                    <Dropdown.Item eventKey={"Rejected"} active={this.props.class.props.inboxFilters.bidType === "Rejected"}>Rejected</Dropdown.Item>
                    <Dropdown.Item eventKey={"Cancelled"} active={this.props.class.props.inboxFilters.bidType === "Cancelled"}>Cancelled</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey={"Unread"} active={this.props.class.props.inboxFilters.unread}>Unread</Dropdown.Item>
                    <Dropdown.Item eventKey={"Archived"} active={this.props.class.props.inboxFilters.archived}>Archived</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </InputGroup>
          </div>
        {this.props.class.props.conversations.conversations.filter(function(conversation) { var valid=false; conversation.participants.forEach(function (participant) {
          if (!participant.hasOwnProperty("me")){
            if (participant.details.name.toLowerCase().includes(filter.search)) {
              valid = true;
            }
          } }) ; if (valid) { return conversation }  }).map(conversation => <this.ConversationListItem key={conversation._id} id={conversation._id} participants={conversation.participants} lastMessage={conversation.lastMessage} setChatBox={this.props.setChatBox} chatBox={this.props.chatBox} bidDetails={conversation.bidDetails} selectedConversation={this.props.class.props.conversationID} unreadMessages={conversation.unreadMessages} class={this} />)
        }
        </div>
        {this.props.class.props.conversations.conversations.length > 0 ? (
          <>
          {isConversationLoaded ? (
          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 px-0 conversationDetail">
            <this.ConversationDetailItem key={this.props.class.props.conversations.conversation._id} messages={this.props.class.props.conversations.messages} id={this.props.class.props.conversations.conversation._id} participants={this.props.class.props.conversations.conversation.participants} setChatBox={this.props.setChatBox} chatBox={this.props.chatBox} bidDetails={this.props.class.props.conversations.conversation.bidDetails} showConversation={this.showConversation} unreadMessages={this.props.class.props.conversations.conversation.unreadMessages} updateDueDate={this.updateDueDate} setTabID={this.props.setTabID} class={this} />
          </div>
          ) :
          (
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 px-0 conversationDetail">
              <InboxLoader key={"empty"}/>
            </div>
          )
          }
          </>
        ) : 
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 px-0">
        </div>}
      </Row>
    )}
  }
}

class BidderCard extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        const bidDate = getDifference(this.props.bidder.time)
        return (
        <div>
            <div className={`mb-3`}  key={`${this.props.bidder.id}-small`}>
                <Card className='shadow-sm'>
                    <Card.Header className="py-3 d-flex align-items-center">
                        <img src={getImageUrl(this.props.class.props.providers,this.props.bidder.profileImage,["w_80","h_80","c_scale"])} alt="avatar" width="42px" className="rounded cursor-pointer" />
                        <div className="ms-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="mb-0 cursor-pointer fw-bold card-title" onClick={()=>(this.props.class.toggleCardView(this.props.bidder.id))} >{this.props.bidder.name}</div>
                          <div className="cardToggles">
                            <div className="cursor-pointer" onClick={()=>(this.props.class.toggleCardView(this.props.bidder.id))} ><img src={info} /></div>
                          </div>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                    <div className="card-text">
                        <div className="mb-3 d-flex align-items-start">
                        </div>
                        <div className="d-flex flex-column text-truncate">
                        <div className="mb-3 text-nowrap">                                    
                            <div className="mb-1 tagsTitle">Industry:</div>
                            <span className="tags">{this.props.bidder.industry}</span>
                        </div>
                        </div>
                        <div className="mt-3">
                        <div className="mb-1 smaller fw-500">Bid Message</div>
                        <p className="mb-0 smaller twoLineTruncate">{this.props.bidder.message}</p>
                        </div>
                    </div>
                    </Card.Body>
                    <Card.Footer className="d-flex align-items-center justify-content-between">
                    <div className="text-muted small">{bidDate.difference} {bidDate.unit} ago</div>
                    {this.props.class.state.bidsTab === "pending" && (
                        <div className="d-flex align-items-center">
                          <button className="btn btn-success btn-sm me-3" onClick={() => this.props.class.setState({approval: {"visible":true, "bidder":this.props.bidder}})}>Accept</button>
                          <button className="btn btn-outline-danger btn-sm me-3" onClick={() => this.props.class.setState({reject: {"visible":true, "bidder":this.props.bidder}})}>Reject</button>
                          {/* <Dropdown>
                              <button type="button" onClick={() => { this.props.class.setConversationModal({"visible":true,"conversationID":this.props.bidder.conversationID,"user":this.props.bidder}); this.props.class.setAutoScroll(true); } } className="btn btn-sm btn-outline-primary">
                                  Message
                              </button>
                          </Dropdown> */}
                        </div>
                    )}
                    </Card.Footer>
                </Card>
            </div>
        </div>
        )
    }
}

class CollaboratorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancellationMessage: "",
      completionMessage: "",
      submitting: false,
      cancelModal: false,
      completeModal: false,
    }
  }

  CancelModal(props){
    var error = null;
    const handleSubmit = async e => {
      e.preventDefault();
      props.class.setState({submitting: true})

      var response = await apiCall(`influencerCampaign/bid/cancel?id=${props.class.props.campaign.id}`,{ "method" : "PUT", "data" : {"bid":props.class.props.collaborator.id,"message":props.class.state.cancellationMessage} });
      if (response["success"]) {
        props.class.props.class.getBids()
        props.class.props.class.setState({alert: {"display":"toast","visible":true,"title":"Collaboration Cancelled","content":"The collaboration was cancelled successfully","className":"bg-success text-white"}})
        props.class.setState({cancelModal: false})
      }
      else {
        error = response["failure"]["error"]
        props.class.props.class.setState({alert: {"display":"toast","visible":true,"title":"Error Cancelling Collaboration","content":error,"className":"bg-danger text-white"}})
      };
      props.class.setState({submitting: false})
    }
    if (props.class.state.cancelModal){
        return (
          <Modal centered show onHide={() => props.class.setState({cancelModal: false})}>
            <Modal.Header className="bg-danger text-white" closeButton>
              <Modal.Title className="h6">Are you sure you want to cancel your collaboration with {props.class.props.collaborator.name}?</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body className="pt-0">
                <Form.Group className="form-group pt-3">
                  <Form.Label>Enter a message to send to {props.class.props.collaborator.name}:</Form.Label>
                  <Form.Control 
                    onChange={e => props.class.setState({cancellationMessage: e.target.value})}
                    value={props.class.state.cancellationMessage}
                    as="textarea"
                    id="message"
                    placeholder="Write your message here..."
                    rows={5}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="p-3 pt-0 border-0">
                <Button variant="outline-secondary" className="m-0 me-2" size="sm" onClick={() => props.class.setState({cancelModal: false})}>
                  Cancel
                </Button>
                <Button variant="danger" size="sm" className="m-0" disabled={props.class.state.submitting}  type="submit" onClick={handleSubmit}>
                  {props.class.state.submitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Cancelling...</> : "Cancel Collaboration"}
                </Button>
                {error && <div className="error text-danger small ms-3">{error}</div>}
              </Modal.Footer>
            </Form>
          </Modal>
        )
    }
    else{
      return (<></>)
    }
  }

  CompletionModal(props){
    var error = null;
    const handleClose = e => {
      props.class.setState({completeModal: false,completionMessage:""})
    }
    const markComplete = async e => {
      e.preventDefault();
      props.class.setState({submitting: true})

      var response = await apiCall(`influencerCampaign/bid/complete?id=${props.class.props.campaign.id}`,{ "method" : "PUT", "data" : {"bidID":props.class.props.collaborator.id,"message":props.class.state.completionMessage} });
      if (response["success"]) {
        props.class.props.class.getBids()
        props.class.props.class.setState({alert: {"display":"toast","visible":true,"title":"Collaboration Completion Confirmed","content":"The collaboration was confirmed as completed!","className":"bg-success text-white"}})
        handleClose()
      }
      else {
        error = response["failure"]["error"]
        props.class.props.class.setState({alert: {"display":"toast","visible":true,"title":"Error Confirming Collaboration Completion","content":error,"className":"bg-danger text-white"}})
      };
      props.class.setState({submitting: false})
    }
    if (props.class.state.completeModal){
      return (
        <Modal centered show onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="h6">Is your collaboration with {props.class.props.collaborator.name} complete?</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body className="pt-0">
              <div>
                <p>If you believe your collaboration with <strong>{props.class.props.collaborator.name}</strong> is complete, please confirm below.</p>
                <p>You have an option to send one final message to the brand as well - either to thank them or let them know what you still expect from them.</p>
              </div>
              <Form.Group className="form-group pt-3">
                <Form.Label>Enter a message to send to {props.class.props.collaborator.name}:</Form.Label>
                <Form.Control 
                  onChange={e => props.class.setState({completionMessage: e.target.value})}
                  value={props.class.state.completionMessage}
                  as="textarea"
                  id="message"
                  placeholder="Write your message here..."
                  rows={5}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="p-3 pt-0 border-0">
              <Button variant="outline-secondary" className="m-0 me-2" size="sm" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="success" size="sm" className="m-0" disabled={props.class.props.isSubmitting}  type="submit" onClick={markComplete}>
                {props.class.props.isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Confirming...</> : "Confirm Completion"}
              </Button>
              {error && <div className="error text-danger small ms-3">{error}</div>}
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    else{
      return (<></>)
    }
  }


  render() {
    return (
      <div>
        <this.CancelModal class={this} />
        <this.CompletionModal class={this} />
        <Card className={`shadow-sm mb-3 ${this.props.class.state.openBids.hasOwnProperty(this.props.collaborator.id) ? "hide" : ""}`}>
          <Card.Body>
            <div className="d-flex align-items-start flex-column">
              <div className="d-flex align-items-center">
                <img src={getImageUrl(this.props.class.props.providers,this.props.collaborator.profileImage,["w_80","h_80","c_scale"])} alt="avatar" width="24px" className="rounded cursor-pointer me-2" onClick={()=>(this.props.class.toggleCardView(this.props.collaborator.id))} />
                <div className="mb-0 cursor-pointer fw-bold card-title" onClick={()=>(this.props.class.toggleCardView(this.props.collaborator.id))}>{this.props.collaborator.name}</div>
                <div className="cursor-pointer position-absolute top-0 mt-3 end-0 me-3" onClick={()=>(this.props.class.toggleCardView(this.props.collaborator.id))}><img src={info} /></div>
              </div>                
            </div>
            <div className="mt-3 d-flex align-items-start small flex-wrap">
              {this.props.collaborator.state != "Completed" ? `You have an ongoing collaboration with ${this.props.collaborator.name}.` : `You have completed your engagement with ${this.props.collaborator.name}.`} 
              <br />
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                {this.props.collaborator.state != "Completed" && (<><button type="button" onClick={() => this.setState({cancelModal: true})} className="btn btn-sm btn-outline-danger ms-2">Cancel Bid</button></>)}
                {this.props.collaborator.state != "Completed" && (<><button type="button" onClick={() => this.setState({completeModal: true})} className="btn btn-sm btn-success ms-2">Mark Complete</button></>)}
              </div>
            </div>
          </Card.Body>
        </Card>  
        <Card className={`mb-md-4 shadow-sm mb-3 ${!this.props.class.state.openBids.hasOwnProperty(this.props.collaborator.id) ? "hide": ""}`}>
          <Card.Header>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="my-2">{this.props.collaborator.name}'s Profile</h6>
              <a className="cursor-pointer text-dark" onClick={()=>(this.props.class.toggleCardView(this.props.collaborator.id))}>✖</a>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="d-flex align-items-start mb-3">
              <Image src={getImageUrl(this.props.class.props.providers,this.props.collaborator.profileImage,["w_80","h_80","c_scale"])} alt="avatar" className="me-3 border rounded" width="90px" />
              <div>
                <h5 className="mb-0">{this.props.collaborator.name}</h5>
              </div>
            </div>
            <div className="border-top pt-3 mb-3 aboutYouBox">
              <h6 className="mb-3">About</h6>
              <div>
                <div className="mb-3 text-nowrap">                                    
                  <div className="mb-1 tagsTitle">Industry:</div>
                  <span className="tags">{this.props.collaborator.industry}</span>
                </div>
              </div>
            </div>
            <div className="border-top pt-3 mb-3">
              <h6 className="mb-2">Bid Message</h6>
              <p className="mb-0 small">{this.props.collaborator.message}</p>
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

class ManageCampaignView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            alert: {"display":"toast","visible":false,"title":"","content":""},
            loaders: {"bids":false,"collaborators":false,"campaign":true},
            pendingBids: [],
            collaborators: [],
            otherBids: [],
            tabID: "bids",
            bidsTab: "pending",
            collaboratorsTab: "accepted",
            filters: {"search":""},
            openBids: [],
            approval: {"visible":false,"bidder":null},
            reject: {"visible":false,"bidder":null},
            submitting: false,
            reason: "",
            message: "",
        }
        this.breakpointColumnsObj = {
            default: 3,
            1100: 2,
            700: 1
          }
    }

    componentDidMount(){
        this.getCampaign()
        this.getBids()
        this.setTabID(this.props.tabID)
    }

    async getCampaign() {
        var tempLoader = structuredClone(this.state.loaders)
        tempLoader.campaign = true
        this.setState({loaders:tempLoader})
        var response = await apiCall(`influencerCampaign?id=${this.props.campaignID}`,{ "method" : "GET" });
        if (response["success"]) {
            var responseData = response["success"]
            this.setState({
                campaign : {
                    "name":responseData["campaign"]["name"],
                    "images":responseData["campaign"]["images"],
                    "id": responseData["campaign"]["_id"]
                }
            });
        }
        else {
          this.setState({
              error: response["failure"]["error"]
        });
        }
        tempLoader.campaign = false
        setTimeout(this.setState({loaders:tempLoader}),0)
    }

    async getBids() {
        var tempLoader = structuredClone(this.state.loaders)
        tempLoader.campaign = true
        this.setState({loaders:tempLoader})
        var response = await apiCall(`influencerCampaign/bids?id=${this.props.campaignID}`,{ "method" : "GET" });
        if (response["success"]) {
            var responseData = response["success"]
            this.setState({
                pendingBids : responseData["pendingBids"],
                collaborators : responseData["collaborators"],
                otherBids : responseData["otherBids"],
            });
        }
        else {
          this.setState({
              error: response["failure"]["error"]
        });
        }
        tempLoader.campaign = false
        setTimeout(this.setState({loaders:tempLoader}),0)
    }

    async handleFilterUpdate() {

    }

    handleModals(modal,show=true){
        var tempState = structuredClone(this.state.modals); 
        tempState[modal] = show; 
        this.setState({modals:tempState})
    }

    toggleCardView(bidderID){
      var openCards = structuredClone(this.state.openBids);
      if (openCards.includes(bidderID)){
        openCards = openCards.filter(function(card) {return card != bidderID})
      }
      else {
        openCards.push(bidderID)
      }
      this.setState({openBids: openCards});
    }

    setTabID(tabID){
      this.setState({tabID: tabID})
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('view', tabID);
      let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
      window.history.replaceState({path:newUrl},'',newUrl);
    }

    ApprovalModal(props){
      var error = null;
      const handleSubmit = async e => {
        e.preventDefault();
        props.class.setState({submitting: true})
  
        var response = await apiCall(`influencerCampaign/bid/accept?id=${props.class.state.campaign.id}`,{ "method" : "PUT", "data" : {"bid":bid.id,"message":props.class.state.message} });
        if (response["success"]) {
          props.class.getBids()
          props.class.setState({alert: {"display":"toast","visible":true,"title":"Bids Accepted","content":"Bid accepted successfully","className":"bg-success text-white"}})
          props.handleClose()
        }
        else {
          error = response["failure"]["error"]
          props.class.setState({alert: {"display":"toast","visible":true,"title":"Error Accepting Bid","content":error,"className":"bg-danger text-white"}})
        };
        props.class.setState({submitting: false})
      }
      const bid = props.bidder;
        return (
          <Modal centered show onHide={props.handleClose}>
            <Modal.Header className="bg-success text-white" closeButton>
              <Modal.Title className="h6">Are you sure you want to accept {bid.name}?</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body className="pt-0">
                <div className="pt-3">
                  <Form.Group className="form-group">
                    <Form.Label>Enter a message to send to {bid.name}:</Form.Label>
                    <Form.Control 
                    onChange={e => props.class.setState({message: e.target.value})}
                    value={props.class.state.message}
                    as="textarea"
                    id="message"
                    placeholder="Write your message here..."
                    rows={5}
                    />
                  </Form.Group>
                </div>
              </Modal.Body>
              <Modal.Footer className="p-3 pt-0 border-0">
                <Button variant="outline-secondary" size="sm" className="m-0 me-2" onClick={props.handleClose}>
                  Cancel
                </Button>
                <Button variant="success" size="sm" className="m-0" disabled={props.class.state.submitting}  type="submit" onClick={handleSubmit}>
                  {props.class.state.submitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Accepting...</> : "Accept Bid"}
                </Button>
                {error && <div className="error text-danger small ms-3">{error}</div>}
              </Modal.Footer>
            </Form>
          </Modal>
        )
    }
  
    RejectModal(props){ 
      var error = null;
      const handleSubmit = async e => {
        e.preventDefault();
        props.class.setState({submitting: true})
  
        var response = await apiCall(`influencerCampaign/bid/reject?id=${props.class.state.campaign.id}`,{ "method" : "PUT", "data" : {"bid":bid.id,"message":props.class.state.reason} });
        if (response["success"]) {
          props.class.getBids()
          props.class.setState({alert: {"display":"toast","visible":true,"title":"Bids Rejected","content":"All bids were rejected successfully","className":"bg-success text-white"}})
          props.handleClose()
        }
        else {
          error = response["failure"]["error"]
          props.class.setState({alert: {"display":"toast","visible":true,"title":"Error Rejecting Bids","content":error,"className":"bg-danger text-white"}})
        };
        props.class.setState({submitting: false})
      }
      const bid = props.bidder;
        return (
          <Modal centered show onHide={props.handleClose}>
            <Modal.Header className="bg-danger text-white" closeButton>
              <Modal.Title className="h6">Are you sure you want to reject {bid.name}?</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body className="pt-0">
                <Form.Group className="form-group pt-3">
                  <Form.Label>Enter a message to send to {bid.name}:</Form.Label>
                  <Form.Control 
                    onChange={e => props.class.setState({reason: e.target.value})}
                    value={props.class.state.reason}
                    as="textarea"
                    id="message"
                    placeholder="Write your message here..."
                    rows={5}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="p-3 pt-0 border-0">
                <Button variant="outline-secondary" className="m-0 me-2" size="sm" onClick={props.handleClose}>
                  Cancel
                </Button>
                <Button variant="danger" size="sm" className="m-0" disabled={props.class.state.submitting}  type="submit" onClick={handleSubmit}>
                  {props.class.state.submitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Rejecting...</> : "Reject Bids"}
                </Button>
                {error && <div className="error text-danger small ms-3">{error}</div>}
              </Modal.Footer>
            </Form>
          </Modal>
        )
    }

    render(){
        const accepted = this.state.collaborators.filter(function(collaborator) { return collaborator.state == "Accepted" }).sort((a,b) => {return a.time - b.time});
        const completed = this.state.collaborators.filter(function(collaborator) { return collaborator.state == "Completed" }).sort((a,b) => {return a.completionTime - b.completionTime});

        if (this.state.loaders.campaign || !this.state.campaign.hasOwnProperty("name")) {
            return <FullPageSpinner />;
        }
        return (
            <Container className="fullscreenWithTopBar px-0 campaignPage" fluid>
            <AlertModal modal={this.state.alert} onClose={() => this.setState({alert: {"display":"toast","visible":false,"title":"","content":""}})} />
            {this.state.approval.visible && <this.ApprovalModal class={this} bidder={this.state.approval.bidder} handleClose={() => this.setState({approval: {visible: false, bidder: null}})} />}
            {this.state.reject.visible && <this.RejectModal class={this} bidder={this.state.reject.bidder} handleClose={() => this.setState({reject: {visible: false, bidder: null}})} />}
            <Tab.Container id="campaign-tab" activeKey={this.state.tabID} onSelect={(key) => this.setTabID(key)}>
              <Container className="pageTitleContainer px-0 shadow-sm pb-0" fluid>
                <Container className="border-bottom pb-0" fluid>
                  <Container className="px-0">
                    <Row className="px-3">
                      <Col xs={7}>
                        <div className="pt-3 mb-3">
                          <Link to="/MyInfluencerCampaigns" className="text-light"><svg width="15" height="13" className="align-middle me-1" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.157595 5.79154L3.5281 9.88415C3.80704 10.1963 4.25408 10.2261 4.54761 9.98575C4.84116 9.74544 4.88952 9.25978 4.64905 8.96624L2.249 6.05465L12.2777 6.05465C12.6766 6.05465 13 5.73122 13 5.33237C13 4.93353 12.6766 4.6101 12.2777 4.6101L2.249 4.6101L4.64905 1.69851C4.88935 1.40513 4.83528 0.92619 4.54762 0.678997C4.25088 0.424079 3.76842 0.487055 3.52811 0.780596L0.157595 4.87321C-0.0618908 5.1817 -0.0430826 5.4927 0.157595 5.79113L0.157595 5.79154Z" fill="var(--lightColor)"/></svg> My Campaigns</Link>
                        </div>
                        <Nav variant="tabs" className="nav nav-tabs border-0">
                          <Nav.Item>
                            <Nav.Link className="bg-transparent" eventKey="bids" onClick={()=>this.setTabID("bids")}>Bids {this.state.pendingBids.length > 0 && <Badge bg="primary">{this.state.pendingBids.length}</Badge>}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link className="bg-transparent" eventKey="collaborations" onClick={()=>this.setTabID("collaborations")}>Collaborations {this.state.collaborators.filter(function(collaborator) {return collaborator.state == "Marked as Complete"}).length > 0 && <Badge bg="danger">{this.state.collaborators.filter(function(collaborator) {return collaborator.state == "Marked as Complete"}).length}</Badge>}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link className="bg-transparent" eventKey="messages" onClick={()=>this.setTabID("messages")}>Messages {this.props.unreadMessages > 0 && <Badge bg="danger">{this.props.unreadMessages}</Badge>}</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col xs={5} className="d-flex align-items-center">
                        <img src={getImageUrl(this.props.providers,this.state.campaign.images[0],["w_100","c_scale"])} alt="campaignImage" height="40px" width="70px" className="rounded object-fit-cover me-3" />
                        <div className="text-truncate">
                          <h1 className="pageTitle tragedyRegularFont mb-0 text-truncate">{this.state.campaign.name}</h1>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </Container>
              <Container className="d-block d-md-none px-0 bg-white pb-0" fluid>
                <Container className="border-bottom pb-0" fluid>
                  <Container className="ps-3 pt-4 pe-0 shadow">
                    <Row className="mx-0">
                      <Col xs={12} className="pt-3 mb-3 px-0">
                        <Link to="/MyInfluencerCampaigns" className="text-muted"><svg width="15" height="13" className="align-middle me-1" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.157595 5.79154L3.5281 9.88415C3.80704 10.1963 4.25408 10.2261 4.54761 9.98575C4.84116 9.74544 4.88952 9.25978 4.64905 8.96624L2.249 6.05465L12.2777 6.05465C12.6766 6.05465 13 5.73122 13 5.33237C13 4.93353 12.6766 4.6101 12.2777 4.6101L2.249 4.6101L4.64905 1.69851C4.88935 1.40513 4.83528 0.92619 4.54762 0.678997C4.25088 0.424079 3.76842 0.487055 3.52811 0.780596L0.157595 4.87321C-0.0618908 5.1817 -0.0430826 5.4927 0.157595 5.79113L0.157595 5.79154Z" fill="#8D8D8D"/></svg> My Campaigns</Link>
                      </Col>
                      <Col xs={12} className="mb-3 px-0 d-flex align-items-center">
                        <img src={getImageUrl(this.props.providers,this.state.campaign.images[0],["w_80","h_80","c_scale"])} alt="campaignImage" height="40px" width="40px" className="rounded object-fit-cover me-2" />
                        <div className="w-100 text-truncate me-3">
                          <h1 className="pageTitle mb-0 fs-6 ps-0">{this.state.campaign.name}</h1>
                        </div>
                      </Col>
                      <Col xs={12} className="px-0">
                          <Nav variant="tabs" className="nav nav-tabs border-0 flex-nowrap overflow-auto">
                            <Nav.Item>
                              <Nav.Link className="text-nowrap" eventKey="bids" onClick={()=>this.setTabID("bids")}>Bids {this.state.pendingBids.length > 0 && <Badge bg="danger" className="align-middle">{this.state.pendingBids.length}</Badge>}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link className="text-nowrap" eventKey="collaborations" onClick={()=>this.setTabID("collaborations")}>Collaborations {this.state.collaborators.filter(function(collaborator) {return collaborator.state == "Marked as Complete"}).length && <Badge bg="danger" className="align-middle">{this.state.collaborators.filter(function(collaborator) {return collaborator.state == "Marked as Complete"}).length}</Badge>}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link className="text-nowrap" eventKey="messages" onClick={()=>this.setTabID("messages")}>Messages {this.state.campaign.unreadMessages > 0 && <Badge bg="danger" className="align-middle">{this.state.campaign.unreadMessages}</Badge>}</Nav.Link>
                            </Nav.Item>
                          </Nav>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </Container>
              <Tab.Content className="chatContainer">
                <Tab.Pane eventKey="bids" className={this.state.bidsTab !== "pending" ? "noButton" : ""}>
                  <Container className="px-0 pt-2">
                    <Row className="px-3">
                        <div className="col-12">
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade" id="all" role="tabpanel" aria-labelledby="all-tab">...</div>
                
                            <div className="tab-pane fade show active" id="collaborations" role="tabpanel" aria-labelledby="collaborations-tab">
                            <Row className="pt-2 position-relative">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                <Tabs defaultActiveKey="pending" id="uncontrolled-tab-example" onSelect={tab => this.setState({bidsTab: tab})}>
                                    <Tab eventKey="pending" title={`Pending (${this.state.pendingBids.length})`}>
                                    </Tab>
                                    <Tab eventKey="rejected" disabled={this.state.otherBids.filter(function(bid) {return bid.state == "Rejected"}).length == 0} title={`Rejected (${this.state.otherBids.filter(function(bid) {return bid.state == "Rejected"}).length})`}>
                                    </Tab>
                                    <Tab eventKey="cancelled" disabled={this.state.otherBids.filter(function(bid) {return bid.state == "Cancelled"}).length == 0} title={`Cancelled (${this.state.otherBids.filter(function(bid) {return bid.state == "Cancelled"}).length})`}>
                                    </Tab>
                                </Tabs>
                                </Col>
                            </Row>
                            </div>
                        </div>
                        </div>
                    </Row>
                    </Container>
                    {this.state.bidsTab === "pending" && 
                        <Container className="border-bottom d-none d-xl-block position-relative" fluid>
                            <Container className="px-0 text-nowrap">
                            <div className="p-3 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="me-4 small text-muted">
                                        {/* <input type="checkbox" className="form-check-input me-1" onChange={props.class.handleSelectAllBids} checked={props.class.props.selectAllBids} /> Select all */}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                <InputGroup size="sm" className="me-3">
                                    <Form.Control placeholder="Search" className="border-end-0" aria-label="Search" defaultValue={this.state.filters.search} onChange={e => this.handleFilterUpdate(e,"search")} />
                                    <InputGroup.Text className="bg-white border-end">
                                    <a><img src={searchBlue} /></a>
                                    </InputGroup.Text>
                                </InputGroup>
                                <div className="fw-bold text-nowrap">Total Bids: {this.state.pendingBids.length}</div>
                                </div>
                            </div>
                            </Container>
                        </Container>
                    }
                    <Container className="px-0 pt-3 pb-4 biddersList">
                    <Row className="px-3">
                        <div className="col-12">
                          {this.state.bidsTab === "pending" && 
                              <Masonry breakpointCols={this.breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                                {this.state.pendingBids.map(bidder => <BidderCard key={bidder.bidderID} bidder={bidder} class={this} />)}
                              </Masonry>
                          }
                          {this.state.bidsTab === "rejected" &&
                              <Masonry breakpointCols={this.breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                                {this.state.otherBids.filter(function(bid) {return bid.state == "Rejected"}).map(bidder => <BidderCard key={bidder.bidderID} bidder={bidder} class={this} />)}
                              </Masonry>
                          }
                          {this.state.bidsTab === "cancelled" &&
                              <Masonry breakpointCols={this.breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                                {this.state.otherBids.filter(function(bid) {return bid.state == "Cancelled"}).map(bidder => <BidderCard key={bidder.bidderID} bidder={bidder} class={this} />)}
                              </Masonry>
                          }
                        </div>
                    </Row>
                    </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="collaborations">
                  <Container className="px-0 pt-2">
                    <Row>
                      <div className="col-12">
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane fade" id="all" role="tabpanel" aria-labelledby="all-tab">...</div>
                          <div className="tab-pane fade show active" id="collaborations" role="tabpanel" aria-labelledby="collaborations-tab"> 
                            <Row className="pt-2 position-relative">
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Tabs defaultActiveKey="accepted" className="ps-3" id="uncontrolled-tab-example" >
                                  <Tab eventKey="accepted" disabled={accepted.length == 0} title={`Accepted (${accepted.length})`}>
                                    <div className="collaborationsList px-3 mt-3">
                                      <Masonry breakpointCols={this.breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                                        {accepted.map(collaborator => <CollaboratorCard key={collaborator.bidderID} collaborator={collaborator} class={this} campaign={this.state.campaign} />)}
                                      </Masonry>
                                    </div>
                                  </Tab>
                                  <Tab eventKey="completed" disabled={completed.length == 0} title={`Completed (${completed.length})`}>
                                    <div className="collaborationsList px-3 mt-3 completed">
                                      <Masonry breakpointCols={this.breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                                        {completed.map(collaborator => <CollaboratorCard key={collaborator.bidderID} collaborator={collaborator} class={this} campaign={this.state.campaign} />)}
                                      </Masonry>
                                    </div>
                                  </Tab>
                                </Tabs>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="messages" className="h-100">
                  <Container className="px-0 pt-md-4 h-100 pb-0" fluid>
                    <Container className="px-0 h-100">
                      <Conversation chatBox={this.props.chatBox} setChatBox={this.props.setChatBox} campaignID={this.props.campaignID} class={this} providers={this.props.providers} setTabID={this.setTabID.bind(this)} />
                    </Container>
                  </Container>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Container>
        )
    }

}


export default function ManageInfluencerCampaign() {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate()
    var campaignID = null;
    var tabID = "bids"
    const { data, currentProfile } = useAuthContext();
    const { providers } = useImageContext();
    if (searchParams.get('id')) {
        campaignID = searchParams.get('id');
    }
    if (searchParams.get('view')) {
        tabID = searchParams.get('view');
    }
    const conversation = searchParams.get('conversation_id');
    const [chatBox, setChatBox] = useState(false);
    const [conversations, setConversations] = useState({"conversations":[],"conversation":null,"messages":[]});
    const [unreadMessages,setUnreadMessages] = useState(0);
    const [showCarousel, setShowCarousel] = useState({"visible":false,"image":null});
    const [inboxFilters, setInboxFilters] = useState({"search":"","archived":false});
    const [conversationID, setConversationID] = useState(conversation);
    const [ autoScroll, setAutoScroll ] = useState(true);
  
    return (
      <div className="bg-page">
        <Topbar/>
        <div className="pageName">Manage Campaign</div>
            <ManageCampaignView navigate={navigate} data={data} currentProfile={currentProfile} campaignID={campaignID} providers={providers} tabID={tabID} chatBox={chatBox} setChatBox={setChatBox} conversations={conversations} setConversations={setConversations} unreadMessages={unreadMessages} setUnreadMessages={setUnreadMessages} showCarousel={showCarousel} setShowCarousel={setShowCarousel} inboxFilters={inboxFilters} setInboxFilters={setInboxFilters} conversationID={conversationID} setConversationID={setConversationID} autoScroll={autoScroll} setAutoScroll={setAutoScroll} />
        <Footer />
      </div>
    );
}
import React, { useState, useEffect, Component } from "react";
import { Container, Row, Dropdown, Badge, Form, InputGroup, Table, Modal, Button, Accordion, ToggleButton } from 'react-bootstrap';
import AdminNav from '../../components/adminnav/AdminNav';
import { Link } from "react-router-dom";
import { useAuthContext } from '../../hooks/useAuthContext';
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { apiCall, PageScroller } from "../../helpers/api";
import AlertModal from "../../components/alerts/alert";
import Topbar from "../../components/topbar/Topbar";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Dashboard.css';
import { getFilter, setFilter } from './../../helpers/filters'
import searchBlue from './../../images/search-blue.svg'
import { getSubscriptionBadge } from './../../helpers/status.js'
import { getDifference } from "../../helpers/dates.js";
import settings from './../../images/settings.svg';
import eye from './../../images/eye.svg';
import Favourited from './../../images/star-yellow.svg';
import Favourite from './../../images/star-grey.svg';

export default function Dashboard() {  
  const [isLoaded, setIsLoaded] = useState(true);
  const { data } = useAuthContext();
  const { providers } = useImageContext();
  const [brands, setBrands] = useState([]);
  const [brandFilters, setBrandFilters] = useState({"states":[],"subscriptions":[],"managers":[],"industries":[],"search":""});
  const [showAddCreditsModal,setAddCreditsModal] = useState({"visible":false,"brandID":null});
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});
  const [credits, setCredits] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initLoad, setInitLoad] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [ collaboratorView, setCollaboratorView ] = useState({"visible":false,"collaborators":[]})

  useEffect(() => {
    if (getFilter("brand")) {
      setBrandFilters(JSON.parse(getFilter("brand")))
    }
  }, []);

  const loginAsBrand = async (brandID,destination="/Campaigns") => {
    var response = await apiCall(`impersonate`,{ "method" : "POST", "data" : {"id":brandID,"type":"brands","returnPage":window.location.pathname+window.location.search} });
    if (response["success"]){
      window.location.href=destination;
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to login as brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  };

  const addCredits = async (brandID) => {
    setAddCreditsModal({"visible":true,"brandID":brandID})
  }

  const submitAddCredits = async e => {
    e.preventDefault();
    setIsSubmitting(true)
    var response = await apiCall(`admin/addCredits`,{ "method" : "POST", "data" : {"id":showAddCreditsModal.brandID,"credits":credits} });
    if (response["success"]) {
      setAlert({"display":"toast","visible":true,"title":"Credits Added","content":"Added credits to brand","className":"bg-success text-white"})
      for (var x in brands) {
        if (brands[x]["_id"] === showAddCreditsModal.brandID) {
          brands[x]["credits"] += Number(credits);
          setBrands([...brands])
          break
        }
      }
      setCredits(1);
      setAddCreditsModal({"visible":false,"brandID":""})
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to add credits","content":response["failure"]["error"],"className":"bg-danger text-white"})
    };
    setIsSubmitting(false)
  }

  const handleFilterUpdate = (value,type) => {
    var filters = brandFilters;
    if (type == "search") {
      filters.search = value.target.value.toLowerCase();
    }
    setBrandFilters(Object.assign({}, brandFilters, filters));
    setFilter(filters,"brand")
  }

  const toggleShowAll = (enabled) => {
    setShowAll(enabled)
    setBrands([])
    handleFilterUpdate({"target":{"value":""}},"search")
  }

  const setState = async (event,result) => { 
    result = await result;
    if (result["brands"].length == 0 && brands.length == 0 && !showAll && initLoad){
      setInitLoad(false)
      toggleShowAll(true)
    }
    else {
      if (event.pageData.page >= 1) {
        for (var x in result["brands"]) {
            brands.push(result["brands"][x])  
        }
        setBrands([...brands])
      } else {
        setBrands(result["brands"])
      }
    }
  }

  return (
    <div className="bg-page dashboard">
      <Topbar />
      <div className="pageName">Dashboard</div>
      <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
        <AlertModal modal={showAlert} onClose={hideAlert} />
        {showAddCreditsModal.visible == true &&
          <Modal show={showAddCreditsModal.visible} onHide={() => {setCredits(1);setAddCreditsModal({"visible":false,"brandID":""})}}>
          <Modal.Header closeButton>
            <Modal.Title className="h5">Add Campaign Credits to Brand</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body className="pb-0">
              <Form.Group>
                  <Form.Label className="mb-1">Number of Credits to Apply</Form.Label>
                  <Form.Control 
                    onChange={(e) => setCredits(e.target.value)}
                    type="number"
                    id="credits"
                    value={credits}
                  />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
              <Button variant="outline-secondary" onClick={() => {setCredits(1);setAddCreditsModal({"visible":false,"brandID":""})}}>Cancel</Button>
              <Button variant="primary" disabled={isSubmitting || credits < 1 || credits > 100 } type="submit" onClick={(e) => submitAddCredits(e)}>
                {isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Adding...</> : "Add Credits"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        }
        {collaboratorView.visible == true &&
          <Modal show centered onHide={() => setCollaboratorView({"visible":false,"collaborators":[]})}>
            <Modal.Header closeButton className="pe-4">
              <Modal.Title>
                Collaborators
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0 viewCollaborators">
              <div className="mb-3">
                {collaboratorView.collaborators.map(collaborator => (
                  <img key={collaborator.id} src={getImageUrl(providers,collaborator.profile,["w_80","h_80","c_scale"])} title={collaborator.name} height="60px" className="border rounded me-1"></img>
                ))}
              </div>
            </Modal.Body>
          </Modal>
        }
        <Row>
          <AdminNav />
          <div className="col-xl-12 col-xxl-10 px-0">
            <div className="px-4 py-3 py-xl-4 bg-white shadow-sm">
              <div className="d-flex align-items-center justify-content-between mx-xl-4">
                <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Hello {data.name}</h1>
                <div className="dashboardOptions">
                  <div id="showAllToggle"><Form.Check type="switch" checked={showAll} label="Show all brands" onChange={(e) => toggleShowAll(e.target.checked)} /></div>
                  <div className="form-group">
                    <InputGroup>
                      <Form.Control placeholder="Search" className="border-end-0" aria-label="Search" value={brandFilters.search} onChange={e => handleFilterUpdate(e,"search")} />
                      <InputGroup.Text id="basic-addon2" className="bg-white border-end">
                        <a><img src={searchBlue} /></a>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
            { !isLoaded ? <FullPageSpinner /> : (
            <>          
            <div className="p-4 pt-0 dashboardTable mt-2 mt-xl-4">
              <div className="mx-xl-4">
                <div className="tableHeaderFixedBelt"></div>
                <Table className="border-transparent">
                  <thead>
                    <tr>
                      <th>My Brands ({brands.length})</th>
                      <th className="text-center">Subscription Type</th>
                      <th className="text-center">Contract Ends</th>
                      <th className="text-center">Live Campaigns</th>
                      <th className="text-center">Campaigns</th>
                      <th className="text-center">Unread Messages</th>
                      <th className="text-center">Pending Bids</th>
                      <th className="text-center">Credits</th>
                      <th className="text-center">Country</th>
                      <th className="text-nowrap">Settings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brands.sort((a, b) => a.name > b.name ? 1 : -1).map(brand => (
                      <>
                      <tr className="small" key={brand._id}>
                        <td className="bg-white rounded-start fw-500 text-nowrap"><div className="d-flex align-items-center"><img src={getImageUrl(providers,brand.profileImage,["w_80","c_scale"])} height="36px" width="36px" className="me-2 object-fit-cover rounded border-gray p-1" /> <div className="text-truncate w-75">{brand.name}</div></div></td>
                        <td className="bg-white text-center">{brand.subscription.hasOwnProperty("plan") ? getSubscriptionBadge(brand.subscription.plan) : getSubscriptionBadge("N/A")}</td>
                        <td className="bg-white text-center"><span className="tags text-nowrap">N/A</span></td>
                        <td className="bg-white text-center"><span className="text-primary h3">{brand.stats.liveCampaigns}</span></td>
                        <td className="bg-white text-center"><span className="text-primary h3">{brand.stats.totalCampaigns}</span></td>
                        <td className="bg-white text-center"><span className="text-primary h3">{brand.stats.unreadMessages}</span></td>
                        <td className="bg-white text-center"><span className="text-primary h3">{brand.stats.pendingBids}</span></td>
                        <td className="bg-white text-center"><span className="text-primary h3">{typeof(brand.credits) == 'number' ? brand.credits : "?"}</span></td>
                        <td className="bg-white text-center"><img src={`https://hatscripts.github.io/circle-flags/flags/${brand.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>
                        <td className="bg-white rounded-end">
                          <div className="d-flex align-items-center justify-content-between">
                            <button type="button" onClick={e => loginAsBrand(brand._id)} className="btn btn-sm btn-outline-primary">Login</button>
                            <Dropdown className="brandAction">
                              <Dropdown.Toggle variant="default" className="border-0">
                                <img src={settings} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Link to="#" className="dropdown-item" onClick={e => addCredits(brand._id)}>Add Credits</Link>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                      {!showAll && 
                      <tr>
                        <td colSpan={10} className="px-0 d-none d-md-table-cell accordionTable">
                          <Accordion>
                            <Accordion.Item eventKey={brand._id} className="bg-transparent border-0">
                              <Accordion.Header>{brand.stats.campaignShortlist.length} Campaigns</Accordion.Header>
                              <Accordion.Body className="p-0">
                                <Table className="campaignTable allTab">
                                  <thead>
                                    <tr>
                                      <th className="ps-3">Campaigns ({brand.stats.campaignShortlist.length})</th>
                                      <th className="text-center text-nowrap">Unread <span className="d-none d-xl-inline-block">messages</span></th>
                                      <th className="text-center text-nowrap">Total <span className="d-none d-xl-inline-block">bids</span></th>
                                      <th className="text-center text-nowrap">Pending <span className="d-none d-xl-inline-block">bids</span></th>
                                      <th className="text-center text-nowrap">Accepted <span className="d-none d-xl-inline-block">bids</span></th>
                                      <th className="text-center text-nowrap">Content <span className="d-none d-xl-inline-block">posted</span></th>
                                      <th>Collaborators</th>
                                      <th>Status</th>
                                      <th className="pe-3"></th>
                                    </tr>
                                  </thead>
                                  <tbody className="w-100">
                                  {brand.stats.campaignShortlist.map(campaign => (
                                    <tr key={campaign._id}>
                                      <td className="bg-white rounded-start">
                                        <div className="d-flex align-items-center">
                                          <img src={campaign.favourite ? Favourited : Favourite} height="14px" width="20px" className="me-2 cursor-pointer" />
                                          <img height="40px" width="70px" src={getImageUrl(providers,campaign.image,["w_100","c_scale"])} alt={campaign.name} title={campaign.name} className="rounded object-fit-cover me-3 myCampaignsTableImage" /> 
                                          <Link title={campaign.name} className="text-black text-wrap fw-500" to={"#"} onClick={() => loginAsBrand(brand._id,`/Campaign/Manage?id=${campaign._id}`)}>{campaign.name}</Link>
                                        </div>
                                      </td>
                                      <td align="center" className="bg-white"><Link to={"#"} onClick={() => loginAsBrand(brand._id,`/Campaign/Manage?id=${campaign._id}&view=messages`)} className="text-primary h3 position-relative">{campaign.unreadMessages} {campaign.unreadMessages > 0 && (<span className="notify"></span>)}</Link></td>
                                      <td align="center" className="bg-white"><Link to={"#"} onClick={() => loginAsBrand(brand._id,`/Campaign/Manage?id=${campaign._id}&view=bids`)} className="text-primary h3 position-relative">{campaign.totalBids} </Link></td>
                                      <td align="center" className="bg-white"><Link to={"#"} onClick={() => loginAsBrand(brand._id,`/Campaign/Manage?id=${campaign._id}&view=bids`)} className="text-primary h3 position-relative">{campaign.pendingBids} </Link></td>
                                      <td align="center" className="bg-white"><Link to={"#"} onClick={() => loginAsBrand(brand._id,`/Campaign/Manage?id=${campaign._id}&view=collaborations`)} className="text-primary h3">{campaign.acceptedBids}</Link></td>
                                      {campaign.collaborators.length > 0 ? <td align="center" className="bg-white"><Link to={"#"} onClick={() => loginAsBrand(brand._id,`/Campaign/Manage?id=${campaign._id}&view=collaborations`)} className="text-primary h3">{campaign.contentPosted}/{campaign.acceptedBids}</Link></td> : <td align="center" className="bg-white text-muted">-</td>}
                                      <td className="bg-white text-nowrap">
                                        {campaign.acceptedBids == 0 ? (<span className="text-muted small">No content submitted yet</span>) : (
                                          <>
                                          {campaign.collaborators.slice(0,3).map(collaborator => <img key={collaborator.id} src={getImageUrl(providers,collaborator.profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded me-1"></img>)}
                                          {campaign.acceptedBids == 4 && (
                                            <img key={campaign.collaborators[3].id} src={getImageUrl(providers,campaign.collaborators[3].profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded" />
                                          )}
                                          {campaign.acceptedBids > 4 && (
                                            <span className="imageOverlay">
                                              <span className="textOverlay rounded" onClick={() => setCollaboratorView({"visible":true,"collaborators":campaign.collaborators})}>+{campaign.acceptedBids - 3}</span>
                                              <img key={campaign.collaborators[3].id} src={getImageUrl(providers,campaign.collaborators[3].profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded" />
                                            </span>
                                          )}
                                        </>              
                                        )}
                                      </td>
                                      <td className="bg-white text-nowrap">
                                          {getDifference(campaign.startDate).future && (
                                          <>
                                            <Badge pill bg="warning" text="dark">Scheduled</Badge>
                                            <div className="text-muted smaller">Starts in {getDifference(campaign.startDate).difference} {getDifference(campaign.startDate).unit}</div>
                                          </>
                                          )}
                                          {!getDifference(campaign.startDate).future && getDifference(campaign.endDate).future && (
                                          <>
                                            <Badge pill bg="success" text="dark">Live</Badge>
                                            <div className="text-muted smaller">Expires in {getDifference(campaign.endDate).difference} {getDifference(campaign.endDate).unit}</div>
                                          </>
                                          )}
                                          {!getDifference(campaign.endDate).future && (
                                          <>
                                            <Badge pill bg="danger" text="dark">Expired</Badge>
                                            <div className="text-muted smaller">Expired {getDifference(campaign.endDate).difference} {getDifference(campaign.endDate).unit} ago</div>
                                          </>
                                          )}
                                      </td>
                                      <td align="right" className="bg-white rounded-end text-nowrap">
                                        <Link className="me-2 d-inline-block" to={"/Campaign/Preview?id=" + campaign._id}><img src={eye} width="20px"  /></Link>
                                      </td>
                                    </tr>
                                  ))}
                                  </tbody>
                                </Table>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </td>
                      </tr>
                      }
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
              {showAll ? <PageScroller endpoint="brands" setState={setState} filter={brandFilters} pageSize={10} /> : <PageScroller endpoint="admin/myBrands" setState={setState} filter={brandFilters} pageSize={10} />}
            </div>
            </>
            ) }
          </div>
        </Row>
      </Container>
    </div>
  );
}
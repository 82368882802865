import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, Dropdown, DropdownButton, Modal, Form, Table, InputGroup, Badge, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import AdminNav from '../../components/adminnav/AdminNav';
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getAge, withinAgeRange } from "../../helpers/dates";
import { getSocialBadge, getMaxFollowers, getAverageEngagement } from "../../helpers/socials";
import { getSmallNumber } from "../../helpers/stats";
import { apiCall, PageScroller } from "../../helpers/api";
import { getFilter, setFilter } from './../../helpers/filters'
import { Link } from "react-router-dom"
import AlertModal from "../../components/alerts/alert";
import searchBlue from './../../images/search-blue.svg'
import { getRatingPill } from "../../helpers/status";
import { getIndustries, getCountries, getCounties, getStates } from "../../helpers/dropdowns";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import AsyncSelect, { useAsync } from 'react-select/async';

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Influencers.css';
import Topbar from "../../components/topbar/Topbar";
import filter from './../../images/filter.svg'
import instagram from "./../../images/instagram.svg";
import facebook from "./../../images/facebook.svg";
import twitter from "./../../images/twitter.svg";
import youtube from "./../../images/youtube.svg";
import tiktok from "./../../images/tiktok.svg";
import blueTick from "./../../images/blue-tick.svg";
import blueCopy from './../../images/blue-copy.svg';
import settings from './../../images/settings.svg';
import plus from './../signup/plus.svg';
import minus from './../signup/minus.svg';
import cancel from './../signup/cancel.svg';
import { propTypes } from "react-bootstrap/esm/Image";
const interests = getIndustries()

export default function Influencers() {  
  const [showNewInfluencerModal, setShowNewInfluencerModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [ activeButton, setActiveButton ] = useState(null);
  const { providers } = useImageContext();
  const { currentProfile } = useAuthContext();
  const [influencers, setInfluencers] = useState([]);
  const [availableQuestions, setAvailableQuestions] = useState({})
  const [newInfluencerData, setNewInfluencerData] = useState({
      "interests":"",
      "primaryInterest":"",
      "gender":"Female",
      "socials":[],
      "phoneNumber":"",
      "country":"",
      "email":"",
      "active":true,
      "sendPasswordReset":true,
      "createUser":false,
      "password":"",
      "passwordConfirm":"",
      "firstName":"",
      "lastName":""
  })
  const [influencerFilters, setInfluencerFilters] = useState({"states":[],"lastLogin":"","primaryInterests":[],"interests":[],"genders":[],"ages":[],"socials":[],"referred":[],"followers":0,"engagementRate":0,"search":"","countries":[],"counties":[],"statesUS":[],"cities":[],"ratings":[],"profileQuestion":"","profileAnswers":[]});
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const [pageStats, setPageStats] = useState({})
  const [specifyGender, setSpecifyGender] = useState(false);
  const [instaLink, setInstaLink] = useState(false);
  const [tiktokLink, settiktokLink] = useState(false);
  const [youtubeLink, setyoutubeLink] = useState(false);
  const [facebookLink, setfacebookLink] = useState(false);
  const [twitterLink, settwitterLink] = useState(false);
  const countries = getCountries()
  const counties = getCounties()
  const statesUS = getStates()
  const [updateCounter, setUpdateCounter] = useState(0)
  const animatedComponents = makeAnimated();
  const [ notificationModal, setNotificationModal ] = useState({"visible":false,"title":"","message":"","target":"All","customTargets":[]})

  useEffect(() => {
    if (getFilter("influencer")) {
      setInfluencerFilters(JSON.parse(getFilter("influencer")))
    }
  }, []);

  const loginAsInfluencer = async (influencerID) => {
    setActiveButton("impersonate")
    var response = await apiCall("impersonate",{ "method" : "POST", "data" : {"id":influencerID,"type":"influencers","returnPage":window.location.pathname} });
    if (response["success"]){
      sessionStorage.removeItem("browsewallState")
      window.location.href="/Home";
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to login as influencer","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setActiveButton(null)
  };

  const downloadInfluencers = async () => {
    setActiveButton("downloadInfluencers")
    var response = await apiCall(`admin/influencerListDownload`,{ "method" : "POST", "data" : {"filter": influencerFilters}, isFile:true });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Influencer List Downloaded","content":"Please check your downloads folder","className":"bg-success text-white"})
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to download influencer list","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setActiveButton(null)
  }

  const disableInfluencer = async (influencerID) => {
    var response = await apiCall(`admin/disableUser`,{ "method" : "POST", "data" : {"id":influencerID,"type":"influencer"} });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Influencer disabled","content":"Influencer successfully disabled","className":"bg-success text-white"})
      for (var x in influencers) {
        if (influencers[x]["_id"] === influencerID) {
          influencers[x]["active"] = false;
          setInfluencers([...influencers]);
          break
        }
      }
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to disable influencer","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const enableInfluencer = async (influencerID) => {
    var response = await apiCall(`admin/enableUser`,{ "method" : "POST", "data" : {"id":influencerID,"type":"influencer"} });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Influencer enabled","content":"Influencer successfully enabled","className":"bg-success text-white"})
      for (var x in influencers) {
        if (influencers[x]["_id"] === influencerID) {
          influencers[x]["active"] = true;
          setInfluencers([...influencers]);
          break
        }
      }
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to enable influencer","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  function updateNewInfluencerData(props){
    var tempInfluencerData = newInfluencerData;
    if (["active"].includes(props.target.id)){
        if (props.target.value == 1){
          tempInfluencerData[props.target.id] = true
        }
        else {
          tempInfluencerData[props.target.id] = false
        }
    }
    else if (props.target.id == "gender" || props.target.id == "specificGender" ){
      if (["Male","Female","Prefer not to say"].includes(props.target.value)){
        setSpecifyGender(false)
      }
      else {
        setSpecifyGender(true)
      }
      tempInfluencerData[props.target.id] = props.target.value
    }
    else if (props.target.id.includes("social_")){
      var newSocial = [];
        var found = false;
        tempInfluencerData["socials"].forEach(social => {
            if (social["name"] == props.target.id.split("social_")[1]){
                newSocial.push({"name":social["name"],"handle":props.target.value})
                found = true;
            }
            else {
                newSocial.push(social)
            }
        })
        if (!found){
            newSocial.push({"name":props.target.id.split("social_")[1],"handle":props.target.value})
        }
        tempInfluencerData["socials"] = newSocial;
    }
    else if (props.target.id == "delete_social"){
      tempInfluencerData["socials"] = tempInfluencerData["socials"].filter(function(social) {return social.name != props.target.value})
    }
    else { 
      tempInfluencerData[props.target.id] = props.target.value
    }
    setNewInfluencerData(tempInfluencerData)
    setUpdateCounter(updateCounter+1)
  }

  async function submitNewInfluencer(){
    var influencerData = newInfluencerData;
    if (specifyGender){
      influencerData["gender"] = influencerData.specificGender
    } 
    var response = await apiCall('influencer',{ "method" : "POST", "data" : newInfluencerData });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Influencer Created","content":`${newInfluencerData.firstName} ${newInfluencerData.lastName} Created Successfully`,"className":"bg-success text-white"})
        setShowNewInfluencerModal(false)
        setNewInfluencerData({
          "interests":"",
          "primaryInterest":"",
          "gender":"Female",
          "socials":[],
          "phoneNumber":"",
          "dob":"",
          "country":"",
          "email":"",
          "active":true,
          "sendPasswordReset":true,
          "createUser":false,
          "password":"",
          "passwordConfirm":"",
          "firstName":"",
          "lastName":""
        })
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not create influencer","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const handleFilterUpdate = (value,added,type,delay=0) => {
    var filters = influencerFilters;
    if (type == "reset"){
      filters = {"states":[],"lastLogin":"","primaryInterests":[],"interests":[],"genders":[],"ages":[],"socials":[],"referred":[],"followers":0,"engagementRate":0,"search":"","countries":[],"counties":[],"statesUS":[],"cities":[],"ratings":[],"profileQuestion":"","profileAnswers":[]}
    }
    else if (type == "states"){
      if (added) { filters.states.push(value) }
      else { filters.states = influencerFilters.states.filter(function(state) { return state != value })}
    }
    else if (type == "lastLogin"){
      if (added) { filters.lastLogin = value }
      else { filters.lastLogin = "" }
    }
    else if (type == "primaryInterests"){
      if (added) { filters.primaryInterests.push(value) }
      else { filters.primaryInterests = influencerFilters.primaryInterests.filter(function(interest) { return interest != value })}
    }
    else if (type == "interests"){
      if (added) { filters.interests.push(value) }
      else { filters.interests = influencerFilters.interests.filter(function(interest) { return interest != value })}
    }
    else if (type == "genders"){
      if (added) { filters.genders.push(value) }
      else { filters.genders = influencerFilters.genders.filter(function(gender) { return gender != value })}
    }
    else if (type == "ratings"){
      if (added) { filters.ratings.push(value) }
      else { filters.ratings = influencerFilters.ratings.filter(function(rating) { return rating != value })}
    }
    else if (type == "ages"){
      if (added) { filters.ages.push(value) }
      else { filters.ages = influencerFilters.ages.filter(function(age) { return age != value })}
    }
    else if (type == "socials"){
      if (added) { filters.socials.push(value) }
      else { filters.socials = influencerFilters.socials.filter(function(social) { return social != value })}
    }
    else if (type == "followers" || type == "engagementRate" ){
      filters[type] = value
    }
    else if (type == "referred" ){
      if (added) { filters.referred.push(value) }
      else { filters.referred = influencerFilters.referred.filter(function(referrerState) { return referrerState != value })}
    }
    else if (type == "search") {
      filters.search = value.target.value.toLowerCase();
    }
    else if (type == "countries"){
      if (added) { filters.countries.push(value) }
      else { filters.countries = influencerFilters.countries.filter(function(country) { return country != value })}
    }
    else if (type == "counties"){
      filters.counties = value.map(county => county.value)
    }
    else if (type == "statesUS"){
      filters.statesUS = value.map(stateUS => stateUS.value)
    }
    else if (type == "cities"){
      filters.cities = value.map(cities => cities.value)
    }
    else if (type == "profileQuestion"){
      filters.profileQuestion = value.value
      filters.profileAnswers = []
    }
    else if (type == "profileAnswers"){
      filters.profileAnswers = value.map(answers => answers.value)
    }
    filters = {"states":filters.states,"lastLogin":filters.lastLogin,"primaryInterests":filters.primaryInterests,"interests":filters.interests,"genders":filters.genders,"ages":filters.ages,"socials":filters.socials,"referred":filters.referred,"followers":filters.followers,"engagementRate":filters.engagementRate,"search":filters.search,"countries":filters.countries,"counties":filters.counties,"statesUS":filters.statesUS,"cities":filters.cities,"ratings":filters.ratings,"profileQuestion":filters.profileQuestion,"profileAnswers":filters.profileAnswers}
    setInfluencerFilters(Object.assign({},influencerFilters, filters));
    setFilter(filters,"influencer")
  }

  const updateNotificationData = (id,value) => {
    var tempNotificationModal = structuredClone(notificationModal)
    tempNotificationModal[id] = value
    setNotificationModal(tempNotificationModal)
  } 

  const sendNotification = async () => {
    var response = await apiCall(`notification/custom`,{ "method" : "POST", "data" : {"type":"influencer","target":notificationModal.target,"details":{"customTargets":notificationModal.customTargets.map(target => target.value),"message":{"title":notificationModal.title,"body":notificationModal.message},"filters":influencerFilters}} });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Notification Sent","content":"The notification was successfully sent to the targets specified","className":"bg-success text-white"})
      setNotificationModal({"visible":false,"title":"","message":"","target":"All","customTargets":[]})
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to send notification","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const getInfluencerCities = async (cityInput) => {
    var response = await apiCall(`influencers/cities?cityInput=${cityInput}`,{ "method" : "GET" });
    if (response["success"]){
      return response["success"]["cities"]
    }
  }

  const getProfileQuestions = async (profileInput) => {
    var response = await apiCall(`profile/questions?questionTitle=${profileInput}`,{ "method" : "GET" });
    var tempQuestions = structuredClone(availableQuestions)
    if (response["success"]){
      var questions = []
      response["success"]["questions"].forEach(question => {
        questions.push({"label":question.question,"value":question.questionID})
        tempQuestions[question.questionID] = question.question
      })
      setAvailableQuestions(tempQuestions)
      return questions
    }
  }

  const getProfileAnswers = async (answerInput) => {
    var response = await apiCall(`profile/questions/answers?questionID=${influencerFilters.profileQuestion}&answerInput=${answerInput}`,{ "method" : "GET" });
    if (response["success"]){
      var answers = []
      response["success"]["answers"].forEach(answer => {
        answers.push({"label":`${answer.name} (${answer.value})`,"value":answer.name})
      })
      return answers
    }
  }

  const setState = async (event,result) => { 
    result = await result;
    if (event.pageData.page >= 1) {
        for (var x in result["influencers"]) {
            influencers.push(result["influencers"][x])  
        }
        setInfluencers(influencers)
    } else {
      setInfluencers(result["influencers"])
    }
    setPageStats(result["_paging"])
  }

  return (
    <div className="bg-page influencers">
    <Topbar />
    <div className="pageName">Influencers</div>
    <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
      <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
      <Row>
        <AdminNav />
        <div className="col-xl-12 col-xxl-10 px-0">
          <div className="ps-4 py-3 py-xl-4 pe-0 bg-white shadow-sm">
            <div className="d-flex align-items-center justify-content-between mb-lg-4 ms-xl-4">
              <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Influencers</h1>
              <div className="form-group d-flex">
                {currentProfile.type == "admins" && <>
                  <Button variant="outline-primary" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => setNotificationModal({"visible":true,"title":"","message":"","target":"All","customTargets":[]})}>Notify <span className='d-none d-sm-inline'>Influencers</span></Button>
                  <Button variant="outline-primary" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => setShowNewInfluencerModal(true)}>Add <span className='d-none d-sm-inline'>Influencer</span></Button>
                  <Button variant="outline-primary" disabled={activeButton == "downloadInfluencers"} size="sm" className="desktopFlex text-nowrap me-2 me-md-3" onClick={() => downloadInfluencers()}>Download</Button>
                </>}
                <InputGroup size="sm" className="me-3 d-none d-md-flex">
                  <Form.Control placeholder="Search" className="border-end-0 form-control-sm" aria-label="Search" defaultValue={influencerFilters.search} onChange={e => handleFilterUpdate(e,true,"search",500)}/>
                  <InputGroup.Text className="bg-white border-end">
                    <a><img src={searchBlue} /></a>
                  </InputGroup.Text>
                </InputGroup>
                <div className="bg-gray pb-1 pt-1 ps-2 pe-3 rounded-start text-nowrap pageDataCount">
                  <span className="fw-500 me-1">Total: </span><span> {pageStats.total} Influencers</span>
                  </div>
                </div>
              </div>
              <div className="desktopFlex align-items-center mx-xl-4 filters">
                <div className="fw-500 me-2 smaller">Filter:</div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Account Status">
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.states.includes(true)} onChange={(e) => handleFilterUpdate(true,e.target.checked,"states")} />
                      <label title="" className="form-check-label">Active</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.states.includes(false)} onChange={(e) => handleFilterUpdate(false,e.target.checked,"states")} />
                      <label title="" className="form-check-label">Disabled</label>
                    </div>
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Last Login">
                    {[{label: "Last Hour", value: 3600}, {label: "Last Day", value: 86400}, {label: "Last Week", value: 604800}, {label: "Last Month", value: 2628000}, {label: "Last Year", value: 31536000}].map(timeDuration => (
                      <div key={timeDuration.value} className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="radio" className="form-check-input me-2 mb-2" checked={influencerFilters.lastLogin === timeDuration.value} onChange={(e) => handleFilterUpdate(timeDuration.value,e.target.checked,"lastLogin")} />
                        <label title="" className="form-check-label">{timeDuration.label}</label>
                      </div>
                    ))}
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Primary Interests">
                    {interests.map(interest => (
                      <div key={interest.value} className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.primaryInterests.includes(interest.value)} onChange={(e) => handleFilterUpdate(interest.value,e.target.checked,"primaryInterests")} />
                        <label title="" className="form-check-label">{interest.label}</label>
                      </div>
                    ))}
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Categories/Interests">
                    {interests.map(interest => (
                      <div key={interest.value} className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.interests.includes(interest.value)} onChange={(e) => handleFilterUpdate(interest.value,e.target.checked,"interests")} />
                        <label title="" className="form-check-label">{interest.label}</label>
                      </div>
                    ))}
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Age">
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ages.includes("18-24")} onChange={(e) => handleFilterUpdate("18-24",e.target.checked,"ages")} />
                      <label title="" className="form-check-label">18 - 24</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ages.includes("25-34")} onChange={(e) => handleFilterUpdate("25-34",e.target.checked,"ages")} />
                      <label title="" className="form-check-label">25 - 34</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ages.includes("35-44")} onChange={(e) => handleFilterUpdate("35-44",e.target.checked,"ages")} />
                      <label title="" className="form-check-label">35 - 44</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ages.includes("45-59")} onChange={(e) => handleFilterUpdate("45-59",e.target.checked,"ages")} />
                      <label title="" className="form-check-label">45 - 59</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ages.includes("60-125")} onChange={(e) => handleFilterUpdate("60-125",e.target.checked,"ages")} />
                      <label title="" className="form-check-label">60+</label>
                    </div>
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Gender">
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.genders.includes("Male")} onChange={(e) => handleFilterUpdate("Male",e.target.checked,"genders")} />
                      <label title="" className="form-check-label">Male</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.genders.includes("Female")} onChange={(e) => handleFilterUpdate("Female",e.target.checked,"genders")} />
                      <label title="" className="form-check-label">Female</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.genders.includes("Other")} onChange={(e) => handleFilterUpdate("Other",e.target.checked,"genders")} />
                      <label title="" className="form-check-label">Other</label>
                    </div>
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Social">
                    {["Facebook","Twitter","Instagram","TikTok","YouTube"].map(social => (
                      <div key={social} className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.socials.includes(social.toLowerCase())} onChange={(e) => handleFilterUpdate(social.toLowerCase(),e.target.checked,"socials")} />
                        <label title="" className="form-check-label">{social}</label>
                      </div>
                    ))}
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Followers">
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="range" id="followers" className="mx-2" min={0} max={1000000} step={10000} value={influencerFilters.followers} onChange={e => handleFilterUpdate(e.target.value,null,"followers",1000)} />  {getSmallNumber(parseInt(influencerFilters.followers),0)}
                    </div>
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Engagement Rate">
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="range" id="engagementRate" className="mx-2" min={0} max={20} step={0.1} value={influencerFilters.engagementRate} onChange={e => handleFilterUpdate(e.target.value,null,"engagementRate",1000)} />  {influencerFilters.engagementRate}%
                    </div>
                  </DropdownButton>
                </div>
                {currentProfile.type == "admins" && (
                  <div className="form-group me-2">
                    <DropdownButton size="sm" variant="light" title="Rating">
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ratings.includes(1)} onChange={(e) => handleFilterUpdate(1,e.target.checked,"ratings")} />
                          <label title="" className="form-check-label">1</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ratings.includes(2)} onChange={(e) => handleFilterUpdate(2,e.target.checked,"ratings")} />
                          <label title="" className="form-check-label">2</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ratings.includes(3)} onChange={(e) => handleFilterUpdate(3,e.target.checked,"ratings")} />
                          <label title="" className="form-check-label">3</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ratings.includes(4)} onChange={(e) => handleFilterUpdate(4,e.target.checked,"ratings")} />
                          <label title="" className="form-check-label">4</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ratings.includes(5)} onChange={(e) => handleFilterUpdate(5,e.target.checked,"ratings")} />
                          <label title="" className="form-check-label">5</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.ratings.includes(0)} onChange={(e) => handleFilterUpdate(0,e.target.checked,"ratings")} />
                          <label title="" className="form-check-label">Unrated</label>
                      </div>
                    </DropdownButton>
                  </div>
                )}
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Referred">
                  <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.referred.includes(true)} onChange={(e) => handleFilterUpdate(true,e.target.checked,"referred")} />
                      <label title="" className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.referred.includes(false)} onChange={(e) => handleFilterUpdate(false,e.target.checked,"referred")} />
                      <label title="" className="form-check-label">No</label>
                    </div>
                  </DropdownButton>
                </div>
                {currentProfile.type == "admins" && (
                <>
                  <div className="form-group me-2">
                    <DropdownButton size="sm" className="customHeaderMultiSelect" variant="light" title="Profile Questions">
                    <AsyncSelect 
                      cacheOptions
                      defaultOptions
                      placeholder="Profile Questions"
                      closeMenuOnSelect={true}
                      value={[{"value":influencerFilters.profileQuestion,"label":availableQuestions[influencerFilters.profileQuestion]}]}
                      loadOptions={getProfileQuestions}
                      onChange={(choice) => handleFilterUpdate(choice, null, "profileQuestion")}
                    />
                    </DropdownButton>
                  </div>
                  {influencerFilters.profileQuestion != "" && (
                    <div className="form-group me-2">
                      <DropdownButton size="sm" className="customHeaderMultiSelect" variant="light" title={influencerFilters.profileAnswers.length == 0 ? "All Profile Answers" : `${influencerFilters.profileAnswers.length} Answer${influencerFilters.profileAnswers.length == 1 ? "" : "s"}`}>
                      <AsyncSelect
                        key={influencerFilters.profileQuestion}
                        isMulti
                        cacheOptions
                        defaultOptions
                        placeholder="Profile Answers"
                        closeMenuOnSelect={false}
                        defaultValue={[]}
                        loadOptions={getProfileAnswers}
                        onChange={(choice) => handleFilterUpdate(choice, null, "profileAnswers")}
                      />
                      </DropdownButton>
                    </div>
                  )}
                </>
                )}
                <div className="form-group me-2">
                  <DropdownButton size="sm" className="customHeaderMultiSelect" variant="light" title={influencerFilters.cities.length == 0 ? "All Cities" : `${influencerFilters.cities.length} Cit${influencerFilters.cities.length == 1 ? "y" : "ies"}`}>
                  <AsyncSelect 
                    isMulti
                    cacheOptions
                    defaultOptions
                    placeholder="Cities"
                    controlShouldRenderValue={false}
                    closeMenuOnSelect={false}
                    loadOptions={getInfluencerCities}
                    onChange={(choice) => handleFilterUpdate(choice, null, "cities")}
                  />
                  </DropdownButton>
                </div>
                <div className="form-group me-2">
                  <DropdownButton size="sm" variant="light" title="Country">
                  <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.countries.includes("GB")} onChange={(e) => handleFilterUpdate("GB",e.target.checked,"countries")} />
                      <label title="" className="form-check-label">UK</label>
                    </div>
                    <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                      <input type="checkbox" className="form-check-input me-2 mb-2" checked={influencerFilters.countries.includes("US")} onChange={(e) => handleFilterUpdate("US",e.target.checked,"countries")} />
                      <label title="" className="form-check-label">US</label>
                    </div>
                  </DropdownButton>
                </div>
                {influencerFilters.countries.includes("GB") &&
                  <div className="form-group me-2">
                    <DropdownButton size="sm" className="customHeaderMultiSelect" variant="light" title={influencerFilters.counties.length == 0 ? "All Counties" : `${influencerFilters.counties.length} Count${influencerFilters.counties.length == 1 ? "y" : "ies"}`}>
                      <Select
                          placeholder="County"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          controlShouldRenderValue={false}
                          defaultValue={[]}
                          menuIsOpen
                          options={counties} 
                          id="county" 
                          isMulti
                          onChange={(choice) => handleFilterUpdate(choice, null, "counties")}
                      />
                    </DropdownButton>
                  </div>
                }
                {influencerFilters.countries.includes("US") &&
                  <div className="form-group me-2">
                    <DropdownButton size="sm" className="customHeaderMultiSelect" variant="light" title={influencerFilters.statesUS.length == 0 ? "All States" : `${influencerFilters.statesUS.length} State${influencerFilters.statesUS.length > 1 ? "s" : ""}`}>
                      <Select
                          placeholder="State"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          controlShouldRenderValue={false}
                          defaultValue={[]}
                          menuIsOpen
                          options={statesUS} 
                          id="stateUS"
                          isMulti
                          onChange={(choice) => handleFilterUpdate(choice, null, "statesUS")}
                      />
                    </DropdownButton>
                  </div>
                }
                <a className="text-danger small" onClick={(e) => handleFilterUpdate(null,null,"reset")}><u>Reset</u></a>
              </div>
            </div>

            {showNewInfluencerModal && (
              <Modal show backdrop="static" centered size="lg" >
              <Modal.Header className="pe-4">
                <Modal.Title className="h5">
                  Add New Influencer
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pb-0">
                <Row>
                  <Col xs={12} sm={12} md={6}>
                      <Form.Group className="mb-3">
                          <Form.Label className="mb-1">First Name <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="name" className="" id="firstName" value={newInfluencerData.firstName} onChange={(e) => updateNewInfluencerData(e)} />
                      </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                      <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Last Name <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="name" className="" id="lastName" value={newInfluencerData.lastName} onChange={(e) => updateNewInfluencerData(e)} />
                      </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                      <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Gender <span className="text-danger">*</span></Form.Label>
                          <Form.Select className="" id="gender" onChange={(e) => updateNewInfluencerData(e)} >
                            <option selected={newInfluencerData.gender == "Male"}>Male</option>
                            <option selected={newInfluencerData.gender == "Female"}>Female</option>
                            <option selected={newInfluencerData.gender == "Prefer not to say"}>Prefer not to say</option>
                            <option selected={newInfluencerData.gender == "Prefer to self-describe (please specify)"}>Prefer to self-describe (please specify)</option>
                          </Form.Select>
                      </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                      <Form.Group className="mb-3">
                          <Form.Label className="mb-1">	Date of Birth	<span className="text-danger">*</span></Form.Label>
                          <Form.Control type="date" className="" id="dob" onChange={(e) => updateNewInfluencerData(e)} />
                      </Form.Group>
                  </Col>
                  {specifyGender && (
                  <Col xs={12} sm={12} md={6}>
                      <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Specify Gender <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="gender" className="" id="specificGender" value={newInfluencerData.specificGender} onChange={(e) => updateNewInfluencerData(e)} />
                      </Form.Group>
                  </Col>
                  )}
                  <Col xs={12} sm={12} md={12}>
                      <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Email <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="email" className="" id="email" value={newInfluencerData.email} onChange={(e) => updateNewInfluencerData(e)} />
                      </Form.Group>
                  </Col>
        
                  <Col xs={12} sm={12} md={6} className="mb-3">
                    <label>Primary Interest</label>
                      <Select
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          defaultValue={[]}
                          options={interests} 
                          id="primaryInterest" 
                          onChange={ (choice) => updateNewInfluencerData({"target":{"value":choice.value,"id":"primaryInterest"}}) } 
                      />
                  </Col>
                  <Col xs={12} sm={12} md={6} className="mb-3">
                    <label>Interests</label>
                      <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[]}
                          options={interests}
                          isMulti
                          id="interests" 
                          onChange={ (choice) => updateNewInfluencerData({"target":{"value":choice.map(item => item.value),"id":"interests"}}) } 
                      />
                  </Col>
                  <Col xs={12} sm={12} md={6} className="mb-3">
                    <label>Country</label>
                      <Select
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          defaultValue={[]}
                          options={countries} 
                          id="country" 
                          onChange={ (choice) => updateNewInfluencerData({"target":{"value":choice.value,"id":"country"}}) } 
                      />
                  </Col>        
        
                  <Col xs={12} sm={12} md={12}>
                    <div className='form-group'>
        
                        <label>Social Media <span className="text-danger">*</span></label>
                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 border" id="instaLink" onClick={()=>setInstaLink((instaLink) => !instaLink)}><img src={instagram} width="24px" /> <img src={`${!instaLink ? plus: minus}`} width="20px" className="ms-1" /></button>
        
                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 border" id="tiktokLink" onClick={()=>settiktokLink((tiktokLink) => !tiktokLink)}><img src={tiktok} width="24px" /> <img src={`${!tiktokLink ? plus: minus}`} width="20px" className="ms-1" /></button>
        
                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 border" id="youtubeLink" onClick={()=>setyoutubeLink((youtubeLink) => !youtubeLink)}><img src={youtube} width="24px" /> <img src={`${!youtubeLink ? plus: minus}`} width="20px" className="ms-1" /></button>
        
                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 border" id="facebookLink" onClick={()=>setfacebookLink((facebookLink) => !facebookLink)}><img src={facebook} width="24px" /> <img src={`${!facebookLink ? plus: minus}`} width="20px" className="ms-1" /></button>
        
                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 border" id="twitterLink" onClick={()=>settwitterLink((twitterLink) => !twitterLink)}><img src={twitter} width="24px" /> <img src={`${!twitterLink ? plus: minus}`} width="20px" className="ms-1" /></button>
                        
                        <div className="row mt-3">
                            <div className={`col-sm-12 col-md-6 col-lg-6 mb-3 ${!instaLink ? "hide": ""}`}>
                                <div className="input-group">
                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={instagram} width="24px" /></span>
                                    <span className="input-group-text fw-bold py-0 ps-2 pe-1 bg-gray">@</span>
                                    <input type="text" className="form-control  border-start-0" id="social_instagram" placeholder="Instagram Handle" onChange={(e) => updateNewInfluencerData(e)}/>
                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="delete_instagram" onClick={()=>{setInstaLink(false); updateNewInfluencerData({"target":{"id":"delete_social","value":"instagram"}})}} ><img src={cancel} width="20px" /></button>
                                </div>
                            </div>
                            <div className={`col-sm-12 col-md-6 col-lg-6 mb-3 ${!tiktokLink ? "hide": ""}`}>
                                <div className="input-group">
                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={tiktok} width="24px" /></span>
                                    <span className="input-group-text fw-bold py-0 ps-2 pe-1 bg-gray">@</span>
                                    <input type="text" className="form-control  border-start-0" id="social_tiktok" placeholder="TikTok Handle" onChange={(e) => updateNewInfluencerData(e)}/>
                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="delete_tiktok" onClick={()=>{settiktokLink(false); updateNewInfluencerData({"target":{"id":"delete_social","value":"tiktok"}})}} ><img src={cancel} width="20px" /></button>
                                </div>
                            </div>
                            <div className={`col-sm-12 col-md-6 col-lg-6 mb-3 ${!youtubeLink ? "hide": ""}`}>
                                <div className="input-group">
                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={youtube} width="24px" /></span>
                                    <input type="text" className="form-control  border-start-0" id="social_youtube" placeholder="Youtube Channel ID" onChange={(e) => updateNewInfluencerData(e)}/>
                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="delete_youtube" onClick={()=>{setyoutubeLink(false); updateNewInfluencerData({"target":{"id":"delete_social","value":"youtube"}})}} ><img src={cancel} width="20px" /></button>
                                </div>
                            </div>
                            <div className={`col-sm-12 col-md-6 col-lg-6 mb-3 ${!facebookLink ? "hide": ""}`}>
                                <div className="input-group">
                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={facebook} width="24px" /></span>
                                    <input type="text" className="form-control  border-start-0" id="social_facebook" placeholder="Facebook Handle" onChange={(e) => updateNewInfluencerData(e)}/>
                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="delete_facebook" onClick={()=>{setfacebookLink(false); updateNewInfluencerData({"target":{"id":"delete_social","value":"facebook"}})}} ><img src={cancel} width="20px" /></button>
                                </div>
                            </div>
                            <div className={`col-sm-12 col-md-6 col-lg-6 mb-3 ${!twitterLink ? "hide": ""}`}>
                                <div className="input-group">
                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={twitter} width="24px" /></span>
                                    <span className="input-group-text fw-bold py-0 ps-2 pe-1 bg-gray">@</span>
                                    <input type="text" className="form-control  border-start-0" id="social_twitter" placeholder="Twitter Handle" onChange={(e) => updateNewInfluencerData(e)}/>
                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="delete_twitter" onClick={()=>{settwitterLink(false); updateNewInfluencerData({"target":{"id":"delete_social","value":"twitter"}})}} ><img src={cancel} width="20px" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                  </Col>

                  <Row>
                    <Col>
                      <div className='form-group mb-3'>
                        <label>
                          <input type="checkbox" className="form-check-input me-1" id="createUser" checked={newInfluencerData.createUser} onChange={(e) => updateNewInfluencerData({"target":{"value":e.target.checked,"id":e.target.id}})} /> Create User</label>
                      </div>
                      </Col>
                  </Row>

                  {newInfluencerData.createUser && (
                    <>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                  <input type="checkbox" className="form-check-input me-1" id="sendPasswordReset" checked={newInfluencerData.sendPasswordReset} onChange={(e) => updateNewInfluencerData({"target":{"value":e.target.checked,"id":e.target.id}})} /> Send Password Reset</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {!newInfluencerData.sendPasswordReset && (<>
                            <Col xs={12} sm={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="mb-1">New Password</Form.Label>
                                    <Form.Control type="password" id="password" value={newInfluencerData.password} onChange={(e) => updateNewInfluencerData(e)} className="" />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="mb-1">Confirm Password</Form.Label>
                                    <Form.Control type="password" id="passwordConfirm" value={newInfluencerData.passwordConfirm} onChange={(e) => updateNewInfluencerData(e)} className="" />
                                </Form.Group>
                            </Col>
                        </>)}
                    </Row>
                    </>
                  )}
        
                </Row>
              </Modal.Body>
              <Modal.Footer className="border-0 pt-0">
                <Button variant="outline-secondary" size="sm" onClick={()=>{setShowNewInfluencerModal(false);  setNewInfluencerData({"interests":"","primaryInterest":"","gender":"","socials":[],"phoneNumber":"","country":"","email":"","active":true,"sendPasswordReset":true,"createUser":false,"password":"","passwordConfirm":"","firstName":"","lastName":""})}}>Cancel</Button>
                <Button disabled={(newInfluencerData.password != newInfluencerData.passwordConfirm && !newInfluencerData.sendPasswordReset) || ["name","industry"].filter(function(field) {return newInfluencerData[field] == ""}).length > 0 || newInfluencerData.password == "" && !newInfluencerData.sendPasswordReset} onClick={() => submitNewInfluencer()} variant="success" size="sm">Submit</Button>
              </Modal.Footer>
            </Modal>
            )}

            {notificationModal.visible && (
              <Modal show backdrop="static" centered size="lg" >
              <Modal.Header className="pe-4">
                <Modal.Title className="h5">
                  Send Custom Notification
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pb-0">
                <p>Send a notification to selected influencers.</p>
                <div className="form-group mb-4 notificationTargets">              
                  <ToggleButtonGroup type="radio" name="toggle" value={notificationModal.target} onChange={e => updateNotificationData("target",e)} id="notificationTargetType">
                    <ToggleButton id="notificationTargetType-1" className="me-2 mb-2" value="All">All</ToggleButton>
                    <ToggleButton id="notificationTargetType-9" className="me-2 mb-2" value="Custom">Custom</ToggleButton>
                  </ToggleButtonGroup>
                  {notificationModal.target == "Custom" && 
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        value={notificationModal.customTargets}
                        options={influencers.map(influencer => ({"label":`${influencer.name} (${influencer.email})`,"value":influencer._id}))} 
                        id="customNotificationTargets" 
                        onChange={ (choice) => {updateNotificationData("customTargets",choice)} } 
                      />
                  }
                  <span className="small text-muted d-block mb-3">Select all influencers from or choose specific influencers</span>  
                </div>
                <div className="form-group my-4 position-relative">
                    <input type="text" maxLength="50" className="form-control pe-5" value={notificationModal.title} onChange={e => updateNotificationData("title",e.target.value)} id="title" placeholder="Write your message here"></input>
                </div>
                <div className="form-group my-4 position-relative">
                    <textarea type="text" rows="6" maxLength="500" className="form-control pe-5" value={notificationModal.body} onChange={e => updateNotificationData("message",e.target.value)} id="body" placeholder="Write a longer description for users to read once they've clicked on the notification"></textarea>
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 pt-0">
                <Button variant="outline-secondary" size="sm" onClick={() => setNotificationModal({"visible":false,"title":"","message":"","target":"All","customTargets":[]})}>Cancel</Button>
                <Button disabled={notificationModal.message.length == 0 || (notificationModal.target == "Custom" && notificationModal.customTargets.length == 0)} onClick={() => sendNotification()} variant="success" size="sm">Send</Button>
              </Modal.Footer>
            </Modal>
            )}

            { !isLoaded ? <FullPageSpinner /> : (     
              <div className="p-4 pt-0 influencersTable mt-2 mt-xl-4 pb-0">
                <div className="mx-xl-4">
                  <div className="tableHeaderFixedBelt"></div>
                  <Table className="border-transparent">
                    <thead>
                      <tr>
                        <th>Profile</th>
                        <th>Categories</th>
                        <th className="d-none">Referred by</th>
                        <th>Last Login</th>
                        <th className="text-center">Country</th>
                        <th>Log in as user</th>
                      </tr>
                    </thead>
                    <tbody className="small">
                      {influencers.map(influencer => (
                        <tr key={influencer._id}>
                          <td>
                            <div className="d-flex">
                              <img src={getImageUrl(providers,influencer.profileImage,["w_80","c_scale"])} height="44px" width="44px" className="object-fit-cover rounded me-2 border" />
                              <div>
                                <h6 className="mb-1">{influencer.name}</h6>
                                <div className="influencerInfo">
                                  {influencer.active ? <Badge pill bg="success" text="dark" className="me-2">Active</Badge> : <Badge pill bg="danger" text="dark" className="me-2">Disabled</Badge>}
                                  {currentProfile.type == "admins" && getRatingPill(influencer.rating) }
                                  <span className="small me-2">{getAge(influencer.dob)} years old</span>
                                  <span className="small">{influencer.gender}</span>
                                </div>
                              </div>
                            </div>
                            <div className="mt-3 d-flex align-items-start d-none d-sm-flex">
                              {influencer.socials.filter(function(social) {return social.name != "meta"}).map(social => (
                                <div className="me-3 d-flex align-items-start position-relative" key={social.name}>
                                  {getSocialBadge(social.name,null,"20px","colour",social.handle)}
                                  {(social.hasOwnProperty("data") && social.data.isVerified) && <img src={blueTick} className="blueTick" />}
                                  <div>
                                    <div className="text-muted smaller">{(social.hasOwnProperty("data") && social.data.followers > 0 && getSmallNumber(social.data.followers)) || ""}</div>      
                                    {social.hasOwnProperty("data") && social.data.hasOwnProperty("engagementRate") && social.data.engagementRate > 0 ? <Badge bg="info">{social.data.engagementRate.toFixed(2)}%</Badge>  : ""}    
                                  </div>
                                </div>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="mb-2">
                                <div className="mb-1 tagsTitle">Primary Category:</div>
                                <span className="tags text-nowrap">{influencer.interests[0]}</span>
                            </div>
                            <div>
                                <div className="mb-1 tagsTitle">Interests:</div>
                                {influencer.interests.length <= 3 ? 
                                (
                                  <>
                                  {influencer.interests.map(interest => (<span key={interest} className="tags me-1 text-nowrap">{interest}</span>))}
                                  </>
                                ) :
                                  <>
                                  {influencer.interests.slice(1,3).map(interest => (<span key={interest} className="tags me-1 text-nowrap">{interest}</span>))}
                                  <span className="tags fw-bold cursor-pointer">+{influencer.interests.length - 2}</span>
                                  </>
                                }
                            </div>
                          </td>
                          <td className="d-none"><span className="tags text-nowrap">{influencer.referrer ? influencer.referrer : "N/A"}</span></td>
                          <td><span className="text-nowrap">{influencer.lastLogin ? (new Date(influencer.lastLogin*1000)).toLocaleDateString('en-GB', { year:"numeric", month:"short", day:"numeric", hour:"2-digit", minute:"2-digit"}) : "Never"}</span></td>
                          <td align="center"><img src={`https://hatscripts.github.io/circle-flags/flags/${influencer.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>
                          <td>
                            <div className="d-flex align-items-center justify-content-between">
                              <button type="button" disabled={!influencer.active || activeButton == "impersonate"} onClick={e => loginAsInfluencer(influencer._id)} className="btn btn-sm btn-outline-primary">Login</button>
                              <Dropdown className="brandAction">
                                <Dropdown.Toggle variant="default" className="border-0">
                                  <img src={settings} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <Link to={`/Administration/Influencers/Settings?id=${influencer._id}`} className="dropdown-item">Edit</Link>
                                {currentProfile.type == "admins" && <>{ influencer.active ? <Link className="dropdown-item" onClick={e => disableInfluencer(influencer._id)}>Disable</Link> : <Link className="dropdown-item" onClick={e => enableInfluencer(influencer._id)}>Enable</Link> }</>}
                              </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <PageScroller endpoint="influencers" setState={setState} filter={influencerFilters} pageSize={100} />
              </div>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
}
import React, { Component, useEffect, useState, useRef } from "react";
import { Container, Col, Row, Tab, Nav, Button, Table, Dropdown, Badge, Form, Modal } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import InputGroup from 'react-bootstrap/InputGroup';
import { useAuthContext } from "../../hooks/useAuthContext";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import settings from './../../images/settings.svg';
import archive from './../../images/dropdown-archive.svg';
import bids from './../../images/dropdown-bids.svg';
import collaborations from './../../images/dropdown-collaborations.svg';
import edit from './../../images/dropdown-edit.svg';
import messages from './../../images/dropdown-messages.svg';
import eye from './../../images/eye.svg';
import Check from './../../images/check-circle.svg';
import Delete from './../../images/delete.svg';
import './InfluencerCampaigns.css'

import { apiCall, PageScroller } from './../../helpers/api'
import AlertModal from "../../components/alerts/alert";
import { Link } from "react-router-dom";
import { getDifference } from './../../helpers/dates'

class CampaignsView extends Component {
    constructor(props) {
      super(props);
      this.state = {
        campaigns: [],
        loaders: {campaigns: false, submitting: false},
        alert: {"display":"toast","visible":false,"title":"","content":""},
        modals: {"delete":false,"collaborators":false},
        selectedCampaign: null,
        collaborators: [],
        totalCampaigns: {"all":0,"live":0,"expired":0,"scheduled":0,"drafts":0},
        tabID: "all",
        campaignIDs: [],
      };
    }

    async stateHandler(event,result) { 
      result = await result;
      for (var x in result["campaigns"]) {
        if (!this.state.campaignIDs.includes(result["campaigns"][x]["_id"])) {
          this.state.campaignIDs.push(result["campaigns"][x]["_id"])
          this.state.campaigns.push(result["campaigns"][x])
        }
      }
      var tempTotals = structuredClone(this.state.totalCampaigns);
      tempTotals[this.state.tabID] = event.pageData.total
      this.setState({ totalCampaigns: tempTotals, influencer : result["influencer"], campaigns : this.state.campaigns, campaignIDs: this.state.campaignIDs }) 
    }

    updateNestedState(parent,child,value){
      var tempState = structuredClone(this.state); 
      tempState[parent][child] = value; 
      this.setState(tempState)
    }

    async markCompleted(campaignID){
      var response = await apiCall(`influencerCampaign/markComplete?id=${campaignID}`,{ "method" : "PUT" });
        if (response["success"]) {
          var tempCampaign = structuredClone(this.state.campaigns)
          for (var x in tempCampaign) {
              if ( tempCampaign[x]["_id"] === campaignID ) {
                tempCampaign[x]["completed"] = true
                break
              }
          }
          this.setState({ campaigns: tempCampaign })
          this.setState({alert: {"display":"toast","visible":true,"title":`Campaign Marked as Completed`,"content":`Campaign Successfully Marked as Completed`,"className":"bg-success text-white"}})
        }
        else {
          this.setState({alert: {"display":"toast","visible":true,"title":`Error Marking Campaign as Completed`,"content":response["failure"]["error"],"className":"bg-danger text-white"}})
        }
    }
   
    DeleteModal(props){
      var error = null;
      const handleSubmit = async e => {
        e.preventDefault();
        props.class.updateNestedState("loaders","submitting",true)
        var response = await apiCall(`influencerCampaign?id=${props.class.state.selectedCampaign._id}`,{ "method" : "DELETE" });
        if (response["success"]) {
          var tempCampaigns = structuredClone(props.class.state.campaigns)
          for (var x in tempCampaigns) {
            if ( tempCampaigns[x]["_id"] === props.class.state.selectedCampaign._id ) {
              tempCampaigns.splice(x,1)
              props.class.setState({ campaigns: tempCampaigns })
              break
            }
          }
          props.class.updateNestedState("modals","delete",false)
          props.class.setState({alert: {"display":"toast","visible":true,"title":"Campaign Deleted","content":"Campaign Successfully Deleted","className":"bg-success text-white"}})
        }
        else {
          error = response["failure"]["error"]
          props.class.setState({alert: {"display":"toast","visible":true,"title":"Error Deleting Campaign","content":error,"className":"bg-danger text-white"}})
        };
        props.class.updateNestedState("loaders","submitting",false)
      }
      if (props.class.state.modals.delete){
        return (
          <Modal show onHide={() => props.class.updateNestedState("modals","delete",false)}>
            <Modal.Header closeButton className="pe-4">
              <Modal.Title className='h5'>Delete Campaign?</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                Are you sure you want to delete <b>"{props.class.state.selectedCampaign.name}?"</b>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => props.class.updateNestedState("loaders","submitting",false)}>
                  No
                </Button>
                <Button variant="danger" disabled={props.class.state.loaders.delete} type="submit" onClick={handleSubmit}>
                  {props.class.state.loaders.delete ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Deleting...</> : "Yes"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )
      }
      else { return (<></>) }
    }
    
    CampaignsHeader(props){
      const changeTab = (tabName) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('view', tabName);
        let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
        window.history.replaceState({path:newUrl},'',newUrl);
      }
    
      return (
        <Container className="pageTitleContainer px-0 shadow-sm pb-0" fluid>
          <Container className="border-bottom pb-0" fluid>
            <Container className="px-0">
              <Row className="px-3">
                <Col xs={6} className="mb-4 pt-4">
                  <h1 className="pageTitle text-white mt-lg-2 tragedyRegularFont ps-md-0">My Campaigns</h1>
                </Col>
                <Col xs={6} className="mb-2 mb-lg-4 d-flex justify-content-end align-items-center">
                  <div className="mt-2 mt-lg-4 fw-normal text-primary bg-light py-2 px-3 rounded">
                    <span className="fw-500 fs-6">Campaigns left: </span>
                    <span className="fs-6">{props.influencer ? props.influencer.credits : "?"}</span>
                    <br /><span className="text-muted small">Resets each month</span>
                  </div>
                </Col>
                <Col xs={8}>
                  <Nav variant="tabs" className="nav nav-tabs border-0" id="nav-tabMobile">
                    <Nav.Item>
                      <Nav.Link className="bg-transparent" eventKey="all" onClick={()=>changeTab("all")}>All</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="bg-transparent" eventKey="live" onClick={()=>changeTab("live")}>Live</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="bg-transparent" eventKey="expired" onClick={()=>changeTab("expired")}>Expired</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="bg-transparent" eventKey="scheduled" onClick={()=>changeTab("scheduled")}>Scheduled</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="bg-transparent" eventKey="completed" onClick={()=>changeTab("completed")}>Completed</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="bg-transparent" eventKey="drafts" onClick={()=>changeTab("drafts")}>Drafts</Nav.Link>
                    </Nav.Item>
                  </Nav> 
                </Col>
                <Col xs={4} className="mb-lg-4 d-flex justify-content-end">
                  {props.influencer && props.influencer.credits > 0 ? <Link to="/InfluencerCampaign/Create"><Button variant="secondary" size="sm" className="rounded" >New Campaign</Button></Link> : <Button variant="secondary" size="sm" className="rounded" disabled >New Campaign</Button>}
                </Col>
              </Row>
            </Container>
          </Container>
        </Container>
      )
    }
  
    CampaignSummary(props){
      const campaignStart = getDifference(props.campaign.startDate)
      const campaignEnd = getDifference(props.campaign.endDate)
      return (
        <tr>
          <td className="bg-white rounded-start">
            <div className="d-flex align-items-center">
              <img height="40px" width="70px" src={getImageUrl(props.class.props.providers,props.campaign.images[0],["w_100","c_scale"])} alt={props.campaign.name} title={props.campaign.name} className="rounded object-fit-cover me-3 myCampaignsTableImage" /> 
              <Link title={props.campaign.name} className="text-black text-wrap fw-500" to={"/InfluencerCampaign/Manage?id=" + props.campaign._id}>{props.campaign.name}</Link>
            </div>
          </td>
          {["all","live","expired","archive"].includes(props.state) && (
            <>
            {props.campaign.active ? <td align="center" className="bg-white"><Link to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=messages"} className="text-primary h3 position-relative">{props.campaign.campaignStats.unreadMessages} {props.campaign.campaignStats.unreadMessages > 0 && (<span className="notify"></span>)}</Link></td> : <td align="center" className="bg-white text-muted">-</td>}
            {props.campaign.active ? <td align="center" className="bg-white"><Link to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=bids"} className="text-primary h3 position-relative">{props.campaign.bidStats.total} </Link></td> : <td align="center" className="bg-white text-muted">-</td>}
            {props.campaign.active ? <td align="center" className="bg-white"><Link to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=bids"} className="text-primary h3 position-relative">{props.campaign.bidStats.pending} </Link></td> : <td align="center" className="bg-white text-muted">-</td>}
            {props.campaign.active ? <td align="center" className="bg-white"><Link to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=collaborations"} className="text-primary h3">{props.campaign.bidStats.accepted}</Link></td> : <td align="center" className="bg-white text-muted">-</td>}
            <td className="bg-white text-nowrap">
              {props.campaign.bidStats.accepted == 0 ? (<span className="text-muted small">No collaborations accepted yet</span>) : (
                <>
                {props.campaign.bidStats.collaborators.slice(0,3).map(collaborator => <img key={collaborator.id} src={getImageUrl(props.class.props.providers,collaborator.profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded me-1"></img>)}
                {props.campaign.bidStats.accepted == 4 && (
                  <img key={props.campaign.bidStats.collaborators[3].id} src={getImageUrl(props.class.props.providers,props.campaign.bidStats.collaborators[3].profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded" />
                )}
                {props.campaign.bidStats.accepted > 4 && (
                  <span className="imageOverlay">
                    <span className="textOverlay rounded" onClick={() => props.class.props.setCollaboratorView({"visible":true,"collaborators":props.campaign.bidStats.collaborators})}>+{props.campaign.bidStats.accepted - 3}</span>
                    <img key={props.campaign.bidStats.collaborators[3].id} src={getImageUrl(props.class.props.providers,props.campaign.bidStats.collaborators[3].profile,["w_80","h_80","c_scale"])} height="40px" className="border rounded" />
                  </span>
                )}
              </>              
              )}
            </td>
            </>
          )}
          <td className="bg-white text-nowrap">
            {props.campaign.active ? ( 
            <>
              {campaignStart.future && (
              <>
                {props.state == "scheduled" ? null : <Badge pill bg="warning" text="dark">Scheduled</Badge>}
                <div className="text-muted smaller">Starts in {campaignStart.difference} {campaignStart.unit}</div>
              </>
              )}
              {!campaignStart.future && campaignEnd.future && (
              <>
                {props.state == "live" ? null : <Badge pill bg="success" text="dark">Live</Badge>}
                <div className="text-muted smaller">Expires in {campaignEnd.difference} {campaignEnd.unit}</div>
              </>
              )}
              {!campaignEnd.future && (
              <>
                {props.state == "expired" ? null : <Badge pill bg="danger" text="dark">Expired</Badge>}
                <div className="text-muted smaller">Expired {campaignEnd.difference} {campaignEnd.unit} ago</div>
              </>
              )}
            </>
             ): (
              <>
              {props.campaign.archived ? (
                <>
                {props.state == "archive" ? null : <Badge pill bg="danger" text="dark">Inactive</Badge>}
                <div className="text-muted smaller">Archived</div>
                </>
              ) : (
                <>
                {props.state == "drafts" ? null : <Badge pill bg="danger" text="dark">Inactive</Badge>}
                <div className="text-muted smaller">Draft</div>
                </>
              )}
              </>
            )}
          </td>
          <td align="right" className="bg-white rounded-end text-nowrap">
            {/* <Link className="me-2 d-inline-block" to={"/InfluencerCampaign/Preview?id=" + props.campaign._id}><img src={eye} width="20px"  /></Link> */}
            <Dropdown className="campaignAction d-inline-block">
              <Dropdown.Toggle variant="default" className="border-0">
                <img src={settings} />
              </Dropdown.Toggle>
  
              <Dropdown.Menu>
                {!props.campaign.active || props.class.props.session.sessionType == "impersonation" ? 
                (<>
                  <Link className="dropdown-item" to={"/InfluencerCampaign/Create?id=" + props.campaign._id}><img src={edit} height="14px" width="20px" className="me-2" /> Edit</Link>
                  <Dropdown.Divider /> 
                </>
                ) : null }
                <Link className="dropdown-item" to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=bids"}><img src={bids} height="16px" width="20px" className="me-2 mb-1" /> Bids</Link>
                <Link className="dropdown-item" to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=collaborations"}><img src={collaborations} height="14px" width="20px" className="me-2" /> Collaborations</Link>
                <Link className="dropdown-item" to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=messages"}><img src={messages} height="14px" width="20px" className="me-2" /> Messages</Link>
                {props.campaign.archived ? null : 
                (<>
                  <Dropdown.Divider /> 
                  {!props.campaign.completed && props.campaign.active && <Link className="dropdown-item" onClick={event => props.class.markCompleted(props.campaign._id)} ><img src={Check} height="14px" width="20px" className="me-2" /> Mark as Complete</Link>}
                  {!props.campaign.active && <Link className="dropdown-item" onClick={event => props.class.props.class.updateNestedState("modals","delete",true) ({"visible":true,"campaign":props.campaign})} ><img src={Delete} height="14px" width="20px" className="me-2" /> Delete</Link>}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      )
    }
  
    CampaignSummaryMobile(props){
      const campaignStart = getDifference(props.campaign.startDate)
      const campaignEnd = getDifference(props.campaign.endDate)
      return (
        <div className="bg-white">
          <img src={getImageUrl(props.class.props.providers,props.campaign.images[0],["w_500","c_scale"])} height="40vh" width="100%" className="mobileCampaignsList" />
          <div className="p-3">
            <Row>
              <Col xs={9}>
                <div className="fw-500">
                  <Link className="text-black" to={"/InfluencerCampaign/Manage?id=" + props.campaign._id}>{props.campaign.name}</Link>
                </div>
              </Col>
              <Col xs={3} className="text-end">
                {/* <Link className="me-3 d-inline-block" to={"/InfluencerCampaign/Preview?id=" + props.campaign._id}><img src={eye} width="20px"  /></Link> */}
                <Dropdown className="campaignAction d-inline-block">
                  <Dropdown.Toggle variant="default" className="border-0 px-0 pt-0 pb-1">
                    <img src={settings} />
                  </Dropdown.Toggle>
  
                  <Dropdown.Menu>
                  {!props.campaign.active || props.class.props.session.sessionType == "impersonation" ? 
                  (<>
                    <Link className="dropdown-item" to={"/InfluencerCampaign/Create?id=" + props.campaign._id}><img src={edit} height="14px" width="20px" className="me-2" /> Edit</Link>
                    <Dropdown.Divider /> 
                  </>
                  ) : null }
                    <Link className="dropdown-item" to={"/InfluencerCampaign/Manage?id=" + props.campaign._id}><img src={bids} height="16px" width="20px" className="me-2 mb-1" /> Bids</Link>
                    <Link className="dropdown-item" to={"/InfluencerCampaign/Manage?id=" + props.campaign._id}><img src={collaborations} height="14px" width="20px" className="me-2" /> Collaborations</Link>
                    <Link className="dropdown-item" to={"/InfluencerCampaign/Manage?id=" + props.campaign._id}><img src={messages} height="14px" width="20px" className="me-2" /> Messages</Link>
                    {props.campaign.archived ? null : 
                    (<>
                      <Dropdown.Divider />
                      {props.class.state.influencer && props.class.state.influencer.credits > 0 ? <Link className="dropdown-item" to={"/InfluencerCampaign/Create?cloneTargetID=" + props.campaign._id}><img src={edit} height="14px" width="20px" className="me-2" /> Clone</Link> : <Link className="dropdown-item" onClick={event => props.class.props.setAlert({"display":"toast","visible":true,"title":"Error Cloning Campaign","content":"You have no campaign entitlements available. Please purchase more or speak with Customer Support to create more campaigns.","className":"bg-danger text-white"})}><img src={edit} height="14px" width="20px" className="me-2" /> Clone</Link> }
                      <Link className="dropdown-item"><img src={archive} height="14px" width="20px" className="me-2" /> Archive</Link>
                      {!props.campaign.active && <Link className="dropdown-item"><img src={Delete} height="14px" width="20px" className="me-2" /> Delete</Link>}
                    </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs={12}>
                <div className="d-flex align-items-center justify-content-around text-wrap">
                  <div className="text-center my-3 mx-1">
                    {props.campaign.active == true ? <Link to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=messages"} className="text-primary h3 position-relative">{props.campaign.campaignStats.unreadMessages} {props.campaign.campaignStats.unreadMessages > 0 && (<span className="notify"></span>)}</Link> : <span className="text-muted">-</span>}
                    <div className="text-muted smaller">Unread messages</div>
                  </div>
                  <div className="text-center my-3 mx-1">
                    {props.campaign.active == true ? <Link to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=bids"} className="text-primary h3 position-relative">{props.campaign.bidStats.total}</Link> : <span className="bg-white text-muted h3">-</span>}
                    <div className="text-muted smaller">Total bids</div>
                  </div>
                  <div className="text-center my-3 mx-1">
                    {props.campaign.active == true ? <Link to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=bids"} className="text-primary h3 position-relative">{props.campaign.bidStats.pending}</Link> : <span className="bg-white text-muted h3">-</span>}
                    <div className="text-muted smaller">Pending bids</div>
                  </div>
                  <div className="text-center my-3 mx-1">
                    {props.campaign.active == true ? <Link to={"/InfluencerCampaign/Manage?id=" + props.campaign._id + "&view=collaborations"} className="text-primary h3">{props.campaign.bidStats.accepted}</Link> : <span className="bg-white text-muted h3">-</span>}
                    <div className="text-muted smaller">Accepted bids</div>
                  </div>
                </div>
              </Col>
              <Col xs={7} className="collaborators">
                {props.campaign.bidStats.accepted == 0 ? (<span className="text-muted small">No collaborations accepted yet</span>) : (
                  <>
                  {props.campaign.bidStats.collaborators.slice(0,3).map(collaborator => <img key={collaborator.id} src={getImageUrl(props.class.props.providers,collaborator.profile,["w_80","h_80","c_scale"])} height="32px" className="border rounded me-1"></img>)}
                  {props.campaign.bidStats.accepted == 4 && (
                    <img key={props.campaign.bidStats.collaborators[3].id} src={getImageUrl(props.class.props.providers,props.campaign.bidStats.collaborators[3].profile,["w_80","h_80","c_scale"])} height="32px" className="border rounded" />
                  )}
                  {props.campaign.bidStats.accepted > 4 && (
                    <span className="imageOverlay">
                      <span className="textOverlay rounded" onClick={() => props.class.props.setCollaboratorView({"visible":true,"collaborators":props.campaign.bidStats.collaborators})}>+{props.campaign.bidStats.accepted - 3}</span>
                      <img key={props.campaign.bidStats.collaborators[3].id} src={getImageUrl(props.class.props.providers,props.campaign.bidStats.collaborators[3].profile,["w_80","h_80","c_scale"])} height="32px" className="border rounded" />
                    </span>
                  )}
                  </>
                )}
                <div className="text-muted smaller mt-1">Collaborators</div>
              </Col>
              <Col xs={5} className="text-start">
              {props.campaign.active == true ? ( 
                <>
                  {campaignStart.future && (
                  <>
                    <Badge pill bg="warning" text="dark">Scheduled</Badge>
                    <div className="text-muted smaller">Starts in {campaignStart.difference} {campaignStart.unit}</div>
                  </>
                  )}
                  {!campaignStart.future && campaignEnd.future && (
                  <>
                    <Badge pill bg="success" text="dark">Live</Badge>
                    <div className="text-muted smaller">Expires in {campaignEnd.difference} {campaignEnd.unit}</div>
                  </>
                  )}
                  {!campaignEnd.future && (
                  <>
                    <Badge pill bg="danger" text="dark">Expired</Badge>
                    <div className="text-muted smaller">Expired {campaignEnd.difference} {campaignEnd.unit} ago</div>
                  </>
                  )}
                </>
                ): (
                  <>
                    <Badge pill bg="danger" text="dark">Inactive</Badge>
                    <div className="text-muted smaller">Draft</div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      )
    }
  
    CampaignsSummary(props){
      return (
        <Container className="campaignWallTabs influencerCampaignWallTabs" fluid>
          <AlertModal modal={props.class.state.alert} onClose={() => props.class.setState({alert: {"display":"toast","visible":false,"title":"","content":""}})} />
          <Container className="px-0 pt-md-2 h-100">
            <Row className="h-100">
              {props.class.state.modals.collaborators &&
                <Modal show centered onHide={() => props.class.updateNestedState("modals","collaborators",false)}>
                  <Modal.Header closeButton className="pe-4">
                    <Modal.Title>
                      Collaborators
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pb-0 viewCollaborators">
                    <div className="mb-3">
                      {props.class.state.collaborators.map(collaborator => (
                        <img key={collaborator.id} src={getImageUrl(props.class.props.providers,collaborator.profile,["w_80","h_80","c_scale"])} title={collaborator.name} height="60px" className="border rounded me-1"></img>
                      ))}
                    </div>
                  </Modal.Body>
                </Modal>
              }
              <div className="col-12 h-100">
                <Tab.Content className="h-100">
                  <Tab.Pane eventKey="all" className="h-100">
                    <Row className="mx-0 px-md-3">
                      {props.campaigns.length > 0 ? (
                      <>
                      <Col xs={12} className="px-0 d-none d-md-block">
                        <Table className="campaignTable allTab">
                          <thead>
                            <tr>
                              <th className="ps-3">Campaigns ({props.class.state.totalCampaigns.all})</th>
                              <th className="text-center text-nowrap">Unread <span className="d-none d-xl-inline-block">messages</span></th>
                              <th className="text-center text-nowrap">Total <span className="d-none d-xl-inline-block">bids</span></th>
                              <th className="text-center text-nowrap">Pending <span className="d-none d-xl-inline-block">bids</span></th>
                              <th className="text-center text-nowrap">Accepted <span className="d-none d-xl-inline-block">bids</span></th>
                              <th>Collaborators</th>
                              <th>Status</th>
                              <th className="pe-3"></th>
                            </tr>
                          </thead>
                          <tbody className="w-100">
                            {props.campaigns.filter(function(campaign) { return (!campaign.archived)}).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"all"} class={props.class} />)}
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={12} className="d-block d-md-none px-0">
                        {props.campaigns.filter(function(campaign) { return (!campaign.archived)}).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"all"} class={props.class} />)}
                      </Col>
                      </>
                      ) : (
                      <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>You haven't got any campaigns yet! Why not create one?</div>
                      )}
                    </Row>
                    <PageScroller endpoint="myInfluencerCampaigns?view=all" responseLists={["influencer","campaigns"]} setState={props.stateHandler} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="live" className="h-100">
                    <Row className="mx-0 px-md-3">
                      {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).length > 0 ? (
                      <>
                      <Col xs={12} className="px-0 d-none d-md-block">
                        <Table className="campaignTable liveTab">
                          <thead>
                            <tr>
                              <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).length})</th>
                              <th className="text-center text-nowrap">Unread <span className="d-none d-xl-inline-block">messages</span></th>
                              <th className="text-center text-nowrap">Total <span className="d-none d-xl-inline-block">bids</span></th>
                              <th className="text-center text-nowrap">Pending <span className="d-none d-xl-inline-block">bids</span></th>
                              <th className="text-center text-nowrap">Accepted <span className="d-none d-xl-inline-block">bids</span></th>
                              <th>Collaborators</th>
                              <th>Status</th>
                              <th className="pe-3"></th>
                            </tr>
                          </thead>
                          <tbody className="w-100">
                            {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"live"} class={props.class} />)}
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={12} className="d-block d-md-none px-0">
                        {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"live"} class={props.class} />)}
                      </Col>
                      </>
                      ) : (
                      <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No live campaigns to show</div>
                      )}
                    </Row>
                    <PageScroller endpoint="myInfluencerCampaigns?view=view" setState={props.stateHandler} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="expired" className="h-100">
                    <Row className="mx-0 px-md-3">
                      {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && !getDifference(campaign.endDate).future) }).length > 0 ? (
                      <>
                      <Col xs={12} className="px-0 d-none d-md-block">
                        <Table className="campaignTable expiredTab">
                        <thead>
                            <tr>
                              <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && !getDifference(campaign.endDate).future) }).length})</th>
                              <th className="text-center text-nowrap">Unread <span className="d-none d-xl-inline-block">messages</span></th>
                              <th className="text-center text-nowrap">Total <span className="d-none d-xl-inline-block">bids</span></th>
                              <th className="text-center text-nowrap">Pending <span className="d-none d-xl-inline-block">bids</span></th>
                              <th className="text-center text-nowrap">Accepted <span className="d-none d-xl-inline-block">bids</span></th>
                              <th>Collaborators</th>
                              <th>Status</th>
                              <th className="pe-3"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && !getDifference(campaign.endDate).future) }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"expired"} class={props.class} />)}
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={12} className="d-block d-md-none px-0">
                        {props.campaigns.filter(function(campaign) { return (campaign.active && !getDifference(campaign.startDate).future && !getDifference(campaign.endDate).future) }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"expired"} class={props.class} />)}
                      </Col>
                      </>
                      ) : (
                      <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No expired campaigns to show</div>
                      )}
                    </Row>
                    <PageScroller endpoint="myInfluencerCampaigns?view=expired" responseLists={["influencer","campaigns"]} setState={props.stateHandler} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="scheduled" className="h-100">
                    <Row className="mx-0 px-md-3">
                      {props.campaigns.filter(function(campaign) { return (campaign.active && getDifference(campaign.startDate).future && getDifference(campaign.endDate)) }).length > 0 ? (
                        <>
                      <Col xs={12} className="px-0 d-none d-md-block">
                        <Table className="campaignTable">
                        <thead>
                            <tr>
                              <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (campaign.active && getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).length})</th>
                              <th>Status</th>
                              <th className="pe-3"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.campaigns.filter(function(campaign) { return (campaign.active && getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"scheduled"} class={props.class} />)}
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={12} className="d-block d-md-none px-0">
                        {props.campaigns.filter(function(campaign) { return (campaign.active && getDifference(campaign.startDate).future && getDifference(campaign.endDate).future) }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} state={"scheduled"} class={props.class} />)}
                      </Col>
                      </>
                      ) : (
                      <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No scheduled campaigns to show</div>
                      )}
                    </Row>
                    <PageScroller endpoint="myInfluencerCampaigns?view=scheduled" responseLists={["influencer","campaigns"]} setState={props.stateHandler} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="completed" className="h-100">
                    <Row className="mx-0 px-md-3">
                      {props.campaigns.filter(function(campaign) { return (campaign.completed) }).length > 0 ? (
                      <>
                      <Col xs={12} className="px-0 d-none d-md-block">
                        <Table className="campaignTable">
                        <thead>
                            <tr>
                              <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (campaign.completed) }).length})</th>
                              <th>Status</th>
                              <th className="pe-3"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.campaigns.filter(function(campaign) { return (campaign.completed) }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"completed"} class={props.class} />)}
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={12} className="d-block d-md-none px-0">
                        {props.campaigns.filter(function(campaign) { return (campaign.completed) }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} class={props.class} />)}
                      </Col>
                      </>
                      ) : (
                      <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No completed campaigns to show</div>
                      )}
                    </Row>
                    <PageScroller endpoint="myInfluencerCampaigns?view=completed" responseLists={["influencer","campaigns"]} setState={props.stateHandler} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="drafts" className="h-100">
                    <Row className="mx-0 px-md-3">
                      {props.campaigns.filter(function(campaign) { return (!campaign.active && !campaign.archived) }).length > 0 ? (
                      <>
                      <Col xs={12} className="px-0 d-none d-md-block">
                        <Table className="campaignTable">
                        <thead>
                            <tr>
                              <th className="ps-3">Campaigns ({props.campaigns.filter(function(campaign) { return (!campaign.active && !campaign.archived) }).length})</th>
                              <th>Status</th>
                              <th className="pe-3"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.campaigns.filter(function(campaign) { return (!campaign.active && !campaign.archived) }).map(campaign => <props.class.CampaignSummary key={campaign._id} campaign={campaign} state={"drafts"} class={props.class} />)}
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={12} className="d-block d-md-none px-0">
                        {props.campaigns.filter(function(campaign) { return (!campaign.active && !campaign.archived) }).map(campaign => <props.class.CampaignSummaryMobile key={campaign._id} campaign={campaign} class={props.class} />)}
                      </Col>
                      </>
                      ) : (
                      <div className="me-4 pe-3 text-muted my-5" style={{textAlign: "center"}}>No draft campaigns to show</div>
                      )}
                    </Row>
                    <PageScroller endpoint="myInfluencerCampaigns?view=drafts" responseLists={["influencer","campaigns"]} setState={props.stateHandler} />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Row>
          </Container>
        </Container>
      )
    }
  
    render() { 
      const changeTab = (tabName) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('view', tabName);
        let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
        window.history.replaceState({path:newUrl},'',newUrl);
      }
  
      if (this.state.error) {
        return <div>Error: {this.state.error.message}</div>;
      } else if (this.state.loaders.campaigns) {
        return <FullPageSpinner />;
      } else {
        return (
          <Container className="fullscreenWithTopBar px-0 campaignWall" fluid>
          <this.DeleteModal class={this}/>
          <Tab.Container id="campaigns-tab" activeKey={this.state.tabID} onSelect={(key) => this.setState({"tabID":key})}>
            <Container className="pt-4 px-0 bg-white d-block d-md-none">
              <div className="mb-2 py-2 text-center">
                <div className="sectionTitle">My Campaigns</div>
              </div>
              <div className="mobileInfluencerCampaignsSummary">
                <div className="mt-2 mt-lg-4 fw-normal text-primary bg-light py-2 px-3 rounded">
                      <span className="fw-500 fs-6">Campaigns left: </span>
                      <span className="fs-6">{this.state.influencer ? this.state.influencer.credits : "?"}</span>
                      <br /><span className="text-muted small">Resets each month</span>
                </div>
                <div>
                  {this.state.influencer && this.state.influencer.credits > 0 ? <Link to="/InfluencerCampaign/Create"><Button variant="secondary" size="sm" className="rounded" >New Campaign</Button></Link> : <Button variant="secondary" size="sm" className="rounded" disabled >New Campaign</Button>}
                </div>
              </div>
              <Nav variant="tabs" className="flex-nowrap px-3" id="nav-tabMobile">
                <Nav.Item>
                  <Nav.Link eventKey="all" onClick={()=>changeTab("all")}>All</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="live" onClick={()=>changeTab("live")}>Live</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="expired" onClick={()=>changeTab("expired")}>Expired</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="scheduled" onClick={()=>changeTab("scheduled")}>Scheduled</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="completed" onClick={()=>changeTab("completed")}>Completed</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="drafts" onClick={()=>changeTab("drafts")}>Drafts</Nav.Link>
                </Nav.Item>
              </Nav>        
            </Container>
            <this.CampaignsHeader influencer={this.state.influencer}/>
  
            <this.CampaignsSummary campaigns={this.state.campaigns} class={this} stateHandler={this.stateHandler.bind(this)}/>
          </Tab.Container>
        </Container>
        );
      }
    }
  }

export default function MyInfluencerCampaigns() {
    let [searchParams] = useSearchParams();
    var view = searchParams.get('view');
    if (view == null){
      view = "all";
    }
    const { providers } = useImageContext();
    const { currentProfile, session } = useAuthContext();
   
    return (
      <div className="bg-page campaignsBrandFlow">
        <Topbar />
        <div className="pageName">My Campaigns</div>
        <CampaignsView view={view} providers={providers} currentProfile={currentProfile} session={session} />
        <Footer />
      </div>
    );
  }
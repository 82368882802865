import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, Card, Carousel, Badge, Tab, Tabs, ListGroup, Fade, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { apiCall } from './../../helpers/api'
import { getDifference, withinAgeRange } from './../../helpers/dates'
import { useSearchParams, Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import AlertModal from "../../components/alerts/alert";
import { getDetails, getProfilePicture, validateAddress, validatePhoneNumber } from '../../helpers/user';
import { getSocialBadge } from "../../helpers/socials";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import { formatText } from "../../helpers/format";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import Spinner from './../../images/tail-spin.svg';
import Clock from "./../../images/clock.svg";
import Avatar from "./../../images/avatar.png";
import NoAccess from "../../images/no-access.png";
import Instagram from "./../../images/instagram.svg";
import Facebook from "./../../images/facebook.svg";
import Info from "./../../images/info.svg";
import Plus from "./../../images/plus.svg";
import checkGreen from "./../../images/check-green.svg";
import checkGrey from "./../../images/check.svg";
import crossGrey from "./../../images/cross.svg";
import instagramOutline from "./../../images/instagram-gray.svg";
import facebookGrey from "./../../images/facebook-gray.svg";
import event from "./../../images/event.svg";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

class CampaignView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: null,
      campaign: null,
      campaignStart: {},
      campaignEnd: {},
      modals: {bid: false, cancel: false, carousel: false},
      alert: {"display":"toast","visible":false,"title":"","content":""},
      message: "",
      loaded: false,
      submitting: false,
      selectedImage: null,
      cancellationMessage: "",
    };
    this.getCampaign()
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  async getCampaign() {
    var response = await apiCall(`influencerCampaign?id=${this.props.id}`,{ "method" : "GET" });
    if (response["success"]) {
      this.setState({
        campaign : response["success"]["campaign"],
        campaignStart : getDifference(response["success"]["campaign"]["startDate"]),
        campaignEnd : getDifference(response["success"]["campaign"]["endDate"])
      });
    }
    else {
      this.setState({
        error: response["failure"]["error"]
      });
    }
    this.setState({loaded: true})
  }

  updateNestedState(parent,child,value){
    var tempState = structuredClone(this.state); 
    tempState[parent][child] = value; 
    this.setState(tempState)
  }

  CancelModal(props){
    var isSubmitting = false;
    var error = null;
    const handleSubmit = async e => {
      e.preventDefault();
      isSubmitting = true
      var response = await apiCall(`influencerCampaign/cancelBid?id=${props.id}`,{ "method" : "PUT", "data": {"reason": props.class.state.cancellationMessage } });
      if (response["success"]) {
        props.class.getCampaign(props.id)
        props.handleClose();
        props.class.setState({alert: {"display":"toast","visible":true,"title":"Bid Cancelled","content":"Bid Successfully Cancelled","className":"bg-success text-white"}})
      }
      else {
        error = response["failure"]["error"]
        props.class.setState({alert: {"display":"toast","visible":true,"title":"Error Cancelling Bid","content":error,"className":"bg-danger text-white"}})
      };
      isSubmitting = false
    }

    return (
      <Modal show={props.class.state.modals.cancel} centered onHide={props.handleClose}>
        <Modal.Header className="bg-danger text-white" closeButton>
          <Modal.Title className="h5">Cancellation Confirmation</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body className="pb-0">
            <p>Are you sure you want to cancel this bid? You won't be able to undo this action once you've clicked yes.</p>            
            {error && <div className="error text-danger small ms-2">{error}</div>}
            <Form.Label>Please confirm your reason for cancelling your bid.</Form.Label>
            <Form.Control 
              onChange={e => props.class.setState({cancellationMessage: e.target.value})}
              as="textarea"
              id="message"
              placeholder="Write your message here..."
              value={props.class.state.cancellationMessage}
              rows={5}
            />
          </Modal.Body>
          <Modal.Footer className="p-3 pt-0 border-0 d-flex align-items-center justify-content-between">
            <Button variant="success" className="m-0" onClick={props.handleClose}>No, don't cancel my bid</Button>
            <Button variant="danger" className="m-0" disabled={isSubmitting || props.class.state.cancellationMessage.length < 1} type="submit" onClick={handleSubmit}>
              {isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Cancelling...</> : "Yes, cancel my bid"}
            </Button>      
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }

  BidModal(props){
    var error = null;
    const handleSubmit = async e => {
      e.preventDefault();
      if (props.message.length < 50) {
        props.class.setState({alert: {"display":"toast","visible":true,"title":"Bid Message Too Short","content":"Please write at least 50 characters for your bid","className":"bg-danger text-white"}})
      }
      else {
        props.class.setState({submitting: true})
        var response = await apiCall(`influencerCampaign/bid?id=${props.id}`,{ "method" : "PUT", "data" : {"bidderID":props.currentProfile.profile.id,"message":props.class.state.message} });
        if (response["success"]) {
          props.class.getCampaign(props.id);
          props.handleClose();
          props.class.setState({alert: {"display":"toast","visible":true,"title":"Bid Submitted","content":"Bid Successfully Submitted","className":"bg-success text-white"}})
        }
        else {
          error = response["failure"]["error"]
          props.class.setState({alert: {"display":"toast","visible":true,"title":"Error Submitting Bid","content":error,"className":"bg-danger text-white"}})
        };
        props.class.setState({submitting: false})
      }
    }

    return (
      <Modal show={props.class.state.modals.bid} centered onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">Bid on This Campaign</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className="mb-3 position-relative" >
                <Form.Label>Bid Message (Min. 50 Characters)</Form.Label>
                <Form.Control 
                  onChange={e => props.class.setState({message: e.target.value})}
                  as="textarea"
                  id="message"
                  required
                  maxLength={500}
                  placeholder="Write bid message here..."
                  value={props.message}
                  rows={5}
                  className="pb-4"
                />
                <span className="position-absolute smaller end-0 bottom-0 text-muted me-2 mb-1">{props.message.length}/500</span> 
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex align-items-center justify-content-between m-0">
              {error && <div className="error text-danger small">{error}</div>}
              <div>
                <Button variant="outline-secondary" size="sm" className="me-2" onClick={props.handleClose}>Cancel</Button>
                <Button variant="primary" size="sm" disabled={props.message.length < 50} type="submit" onClick={handleSubmit}>
                  {props.class.state.submitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...</> : "Submit Bid"}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }

  HeaderCarousel(props){
    return (
      <Container className="position-relative headerCarousel pb-0 pb-md-3">      
        <div className="d-none d-md-block">
          <div className="mb-2 mt-3">
            <Link onClick={() => window.history.back()} className="text-primary small">&#60; Back</Link>
          </div>
          <h3 className="mb-4 text-white tragedyRegularFont">{props.title}</h3>
          <Row className="imagesBoxView">
            <div className="col-lg-6 col-md-8 col-sm-12 col-12 h-100">
            <img src={getImageUrl(props.class.props.providers,props.images[0],["w_500","c_scale"])} className="w-100 h-100 border rounded-xl shadow object-fit-cover cursor-pointer" onClick={() => {props.class.updateNestedState("modals","carousel",true); props.class.setState({"selectedImage": 0})}} aria-controls="imageViewer" aria-expanded={props.class.props.showCarousel} />
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col-12 h-100">
              <Row className="h-50">
                {props.images.slice(1).map((campaignImage,index) => (
                  <div key={campaignImage.id} className="col-6 h-100 col-padding-bottom">
                    <img src={getImageUrl(props.class.props.providers,campaignImage,["w_500","c_scale"])} className="w-100 h-100 border rounded object-fit-cover cursor-pointer" onClick={() => {props.class.updateNestedState("modals","carousel",true); props.class.setState({"selectedImage": index+1})}} aria-controls="imageViewer" aria-expanded={props.class.props.showCarousel} />
                  </div>
                ))}
              </Row>
            </div>
          </Row>
        </div>
        <Fade in={props.class.state.modals.carousel}>
          <div id="imageViewer">
            <div className="carouselControl">
              <button type="button" className="btn btn-sm btn-success">Download</button>
              <button type="button" onClick={() => props.class.updateNestedState("modals","carousel",false)} className="btn btn-sm btn-light closeImageViewer">Close</button>
            </div>
            <Carousel activeIndex={props.class.state.selectedImage} onSelect={(e) => {props.class.setState({"selectedImage": e})}} slide={false}>
              <Carousel.Item>
                <img id="0" className="d-block" src={getImageUrl(props.class.props.providers,props.images[0],["w_500","c_scale"])} alt="First slide" />
              </Carousel.Item>
              {props.images.slice(1).map((campaignImage,index) => (
                <Carousel.Item key={index}>
                  <img id={index+1} src={getImageUrl(props.class.props.providers,campaignImage,["w_500","c_scale"])} className="d-block" alt={campaignImage.id} />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Fade>
        <div className="d-block d-md-none">
          <div className="blackGradient"></div>      
          <div className="my-4 py-2 px-3 overlayBox">
            <Link className="backArrow me-4" onClick={() => window.history.back()}>
              <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#ffffff"/>
              </svg>
            </Link>
          </div>
          {props.class.state.campaignStart.future ? <Badge bg="light" text="dark" className="left"><img src={Clock} className="align-bottom" /> Starts in <strong>{props.class.state.campaignStart.difference} {props.class.state.campaignStart.unit}</strong></Badge> : <Badge bg="light" text="dark" className="left"><img src={Clock} className="align-bottom" /> Ends in <strong>{props.class.state.campaignEnd.difference} {props.class.state.campaignEnd.unit}</strong></Badge>}      
          <Carousel>
            {props.images.map((campaignImage,index) => (
              <Carousel.Item key={campaignImage.id}>
                <img
                  className="d-block w-100"
                  src={getImageUrl(props.class.props.providers,campaignImage,["w_500","c_scale"])} onError={(event) => event.target.src = Spinner}
                  alt="First slide"
                />
                <Badge bg="light" text="dark" className="right">{index+1}/{props.images.length}</Badge>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Container>
    )
  }

  render() {
    if (this.state.error) {
      return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {this.state.error}</div></div>;
    } else if (!this.state.campaign | !this.state.loaded) {
      return <FullPageSpinner />;
    } else {
      return (
        <Container className="p-0 pb-4 pb-md-0" fluid>
          <AlertModal modal={this.state.alert} onClose={() => this.setState({alert: {"display":"toast","visible":false,"title":"","content":""}})} />
          <Container className="p-0" fluid>
            <Container className="p-0" fluid>
                <Container className="px-0 mb-5 mb-md-4 mb-lg-0 pb-4 pb-md-2 pb-lg-0 tabViewChange ps-md-3" fluid>
                <this.HeaderCarousel class={this} images={this.state.campaign.images} title={this.state.campaign.name}/>
                <Container fluid className="bg-light rounded-top-xl">
                    <Container className="mt-md-4 pt-4">
                    <div className="logoBox mb-4">
                        <img src={getImageUrl(this.props.providers,this.state.campaign.influencerIcon)} width="46px" /> {this.state.campaign.influencerName}
                    </div>
                    <div className="mb-4">
                        <h5 className="d-block d-md-none">{this.state.campaign.name}</h5>
                        {this.state.campaign.categories.map(category => (
                          <Badge bg="event" className="me-2" key={category}>{category}</Badge>
                        ))}
                    </div>
                    </Container>
                    <Container>
                    <Row>
                        <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12 mb-3 mb-md-0">
                        <Tabs defaultActiveKey="offering" id="uncontrolled-tab-example" fill >

                            <Tab eventKey="offering" title="Offering">
                            <div className="p-3 px-md-0">
                                <h6>What we're offering</h6>
                                <p className="small text-muted">{formatText(this.state.campaign.offering)}</p>
                            </div>
                            </Tab>

                            <Tab eventKey="looking" title="Looking for">
                            <div className="p-3 px-md-0">

                                <h6>What we're looking for</h6>
                                <p className="small text-muted pb-3 mb-3">{formatText(this.state.campaign.lookingFor)}</p>
                                
                            </div>
                            </Tab>
                        </Tabs>
                        </div>
                        <div className="col-xl-3 col-lg-4 mb-md-4">
                          <div className="card border-0 bg-secondary d-none d-md-block">
                              <div className="py-3 d-flex align-items-center card-header">
                                  {!this.state.campaignEnd.future ? 
                                  <Badge bg="warning" text="dark" className="reportBadge">
                                      <img src={Clock} className="align-bottom" /> Finished <strong>{this.state.campaignEnd.difference} {this.state.campaignEnd.unit}</strong> ago
                                  </Badge> 
                                  : (this.state.campaignStart.future ? 
                                  <Badge bg="warning" text="dark" className="reportBadge">
                                      <img src={Clock} className="align-bottom" /> Starts in <strong>{this.state.campaignStart.difference} {this.state.campaignStart.unit}</strong>
                                  </Badge> 
                                  : 
                                  <Badge bg="warning" text="dark" className="reportBadge">
                                      <img src={Clock} className="align-bottom" /> Ends in <strong>{this.state.campaignEnd.difference} {this.state.campaignEnd.unit}</strong>
                                  </Badge>
                                  )} 
                              </div>
                              <div className="card-body">
                              {this.state.campaign.hasOwnProperty("bidState") && (
                                  <>
                                  {this.state.campaign.bidState == "Cancelled" || this.state.campaign.bidState == "Rejected" ? <button type="button" onClick={() => this.updateNestedState("modals","bid",true)} className="w-100 mt-3 btn btn-primary btn-sm">Bid</button> : null}
                                  {["Pending","Accepted","In Progress"].includes(this.state.campaign.bidState) ? <button type="button" onClick={() => this.updateNestedState("modals","cancel",true)} className="w-100 mt-3 btn btn-danger btn-sm">Cancel Bid</button> : null}
                                  </>
                              ) || this.state.campaignEnd.future && <button type="button" onClick={() => this.updateNestedState("modals","bid",true)} className="w-100 mt-3 btn btn-primary btn-sm">Bid</button>
                              }
                              </div>
                          </div>
                        </div>
                    </Row>
                    </Container>
                </Container>
                </Container>
            </Container>
          </Container>
          <this.BidModal handleClose={() => this.updateNestedState("modals","bid",false)} show={this.state.modals.bid} message={this.state.message} id={this.state.campaign._id} class={this} userDetails={this.state.userDetails} user={this.props.user} campaign={this.state.campaign} currentProfile={this.props.currentProfile} />
          <this.CancelModal handleClose={() => this.updateNestedState("modals","cancel",false)} show={this.state.modals.delete} message={this.state.message} id={this.state.campaign._id} class={this} user={this.props.user} currentProfile={this.props.currentProfile}/>
          {this.state.campaign.hasOwnProperty("bidState") && (
            <>
              {this.state.campaign.bidState == "Cancelled" || this.state.campaign.bidState == "Rejected" ? <button type="button" onClick={() => this.updateNestedState("modals","bids",true)} className="btn btn-primary btn-lg d-md-none mobileBidBtn">Bid</button> : null}
              {["Pending","Accepted","In Progress"].includes(this.state.campaign.bidState) ? <button type="button" onClick={() => this.updateNestedState("modals","cancel",true)} className="btn btn-danger btn-lg d-lg-none mobileBidBtn">Cancel Bid</button> : null}
            </>
          ) || this.state.campaignEnd.future && <button type="button" onClick={() => this.updateNestedState("modals","bids",true)} className="btn btn-primary btn-lg d-md-none mobileBidBtn">Bid</button>
          }
        </Container>
      );
    }
  }
}

export default function InfluencerCampaignDetail() {
  const { data, currentProfile } = useAuthContext();
  let [searchParams] = useSearchParams();
  const { providers } = useImageContext();
  const campaignID = searchParams.get('id');

  return (
    <div className="bg-page">
      <Topbar />
      <div className="pageName">Campaign Overview</div>
      <Container className="fullscreenWithTopBar campaignDetail bg-primary px-0" fluid="fluid">
          <CampaignView id={campaignID} currentProfile={currentProfile} memberships={data.memberships} providers={providers} />
      </Container>
      <Footer />
    </div>
  );
}